<template>
    <div ref="tourHolder">
        <Loading :loading="loading" solid/>

        <WelcomeScreen v-if="shouldShowWelcomeScreen" @onStart="startTourManually" :theme="tour.audio_screen_theme || 'light'"/>

        <AudioPlayBack ref="audioPlayback" v-show="shouldShowAudioPlayer"/>
    </div>
  </template>
  
  <script>
      import HTMLParser from "@/utils/parser/html/dom";
      import Loading from "@/components/Loading";
      import ResponsiveViewer from "../components/responsive-viewer";
      import { addStyles, injectCSS, convertToHttpUrl, addScriptTagStringInHtml, onIframeReady, debounce } from "@/utils";
      import { domGetElement } from "@/utils/dom-element-path";
      import { renderVariables, shouldRenderVariables } from "@/utils/variables";
      
      import GuideDrawer from "../../../guideDrawer";
  
      import AudioPlayBack from '../../components/audio-playback';
      import WelcomeScreen from '../../components/welcome-screen';

      const debounceFunction = debounce((callback) => callback(), 500);

      function createResponsiveViewerIframe(){
          const iframe = document.createElement("iframe");
  
          iframe.setAttribute("class", "story-guide-viewer-iframe")
  
          return iframe;
      }
  
      function createResponsiveIframe(url){
          const iframe = document.createElement("iframe");
  
          iframe.style.visibility = "hidden";

          if(url) iframe.src = url;
  
          return iframe;
      }
  
      function constructStyleGuide(type, tourStyleGuide, guideStyleGuide, override = false) {
          let styleGuide = {};
  
          switch (type) {
              case "guide": {
                  styleGuide = {
                      guide: {
                          ...(tourStyleGuide?.guide || {}),
                          ...(override ? guideStyleGuide?.guide || {} : {}),
                      },
                      button: {
                          ...(tourStyleGuide?.button || {}),
                          ...(override ? guideStyleGuide?.button || {} : {})
                      },
                      glow: {
                          ...(tourStyleGuide?.glow || {}),
                      },
                      beacon: {
                          ...(tourStyleGuide?.beacon || {}),
                      }
                  };
  
                  break;
              }
              case "lightbox": {
                  styleGuide = {
                      lightbox: {
                          ...(tourStyleGuide?.lightbox || {}),
                          ...(override ? guideStyleGuide?.lightbox || {} : {}),
                      },
                      button: {
                          ...(tourStyleGuide?.button || {}),
                          ...(override ? guideStyleGuide?.button || {} : {})
                      }
                  };
  
                  break;
              }
              case "link": {
                  styleGuide = {
                      guide: {
                          ...(tourStyleGuide?.link || {}),
                          ...(override ? guideStyleGuide?.link || {} : {}),
                      },
                      glow: {
                          ...(tourStyleGuide?.glow || {}),
                      },
                      beacon: {
                          ...(tourStyleGuide?.beacon || {}),
                      }
                  };
  
                  break;
              }
          }
  
          return styleGuide;
      }
  
      function setupGuides(iframe, guides, options){
          const { screenType = "html", getTour, getTourOperator, getResponsiveViewer } = options;
  
          const responsiveViewer = getResponsiveViewer();
          const mainDocument = iframe.contentDocument;
          const mainWindow = iframe.contentWindow;
  
          const storyGuide = new mainWindow.StoryGuideResponsive({ iframe: responsiveViewer.iframe });
  
          const guidesCount = guides.length;
  
          const tour = getTour();
  
          guides.forEach((guide, index) => {
              const isFirstGuideIndex = index == 0;
              const isLastGuideIndex = guidesCount - 1 == index;
              let autoAdvanceTimer = null;

              if(screenType != "html"){
                  try {
                      const guideDrawerInstance = new GuideDrawer({
                           doc: mainDocument,
                      });
      
                      const guideElement = guideDrawerInstance.createGuideElement({
                          elementId: guide.meta.guide_element_id,
                          getPositionProps: () => {
                              return {
                                  elementX: guide.meta.guide_element_x,
                                  elementY: guide.meta.guide_element_y,
                                  elementWidth: guide.meta.guide_element_width,
                                  elementHeight: guide.meta.guide_element_height,
                                  containerX: guide.meta.guide_element_container_x,
                                  containerY: guide.meta.guide_element_container_y,
                                  containerWidth: guide.meta.guide_element_container_width,
                                  containerHeight: guide.meta.guide_element_container_height,
                              }
                          },
                          getContainer: () => {
                              return mainDocument.body.querySelector("#storyscale-screen-element");
                          },
                          className: "",
                      });
      
                      mainDocument.body.appendChild(guideElement);
                  } catch (error) {
                      console.log(error);
                  }
              }
              
              const styleGuide = constructStyleGuide(
                  guide.type,
                  tour.style_guide, 
                  guide.style_guide, 
                  guide.meta.override_styles == "1"
              );
  
              const guideButtonTemplate = guide.type !== "link" 
              ? guide.meta.mobile_template || guide.meta.template || "single_button"
              : guide.meta.mobile_template || guide.meta.template || "prompt";
  
              const attachToElement = domGetElement(guide.meta.elementPath, mainDocument)
  
              // Setup tour audio
              if (guide.meta.audio_enabled == "1") {
                  getTourOperator().audioPlayback()?.add(guide.id, guide.meta.audio_url);
              }
  
              // Welcome Screen
              if (!getTourOperator().self.welcomeSceenWasActive && getTourOperator().isTourAudioSettingsEnabled && isFirstGuideIndex && (guide.meta.audio_enabled == "1")) {
                  getTourOperator().self.shouldShowWelcomeScreen = true;
                  getTourOperator().self.shouldStartTourManually = true;
                  getTourOperator().self.welcomeSceenWasActive = true;
              }

              let stepOptions = {
                  id: guide.id,
                  type: guide.type,
                  options: {
                      container: responsiveViewer.guideContainer,
  
                      title: guide.meta.mobile_title || guide.meta.title,
                      description: renderVariables({
                          content: guide.meta.mobile_description || guide.meta.description,
                          variables: getTourOperator().variableMap
                      }),
                      overlay: guide.meta.overlay == "1" ? true : false,
                      overlayType: guide.meta.overlay_type,
                      overlayBlurIntensity: guide.meta.overlay_blur_intensity,
                      
                      // Glow    
                      glowEnabled: guide.meta.glow_enabled == '1' ? true : false,
                      glowType: guide.meta.glow_type,
                      glowColor: guide.meta.glow_color,
  
                      showConfetti: guide.meta.showConfetti == "1" ? true : false,
                      template: guideButtonTemplate,
                  
                      attachTo: {
                          element: attachToElement,
                          on: guide.meta.position || 'top'
                      },
                      getTotalNumberOfSteps(){
                          return getTourOperator().getTotalNumberOfSteps();
                      },
                      getCurrentStepNumber(){
                          return getTourOperator().getCurrentStepNumber();
                      },
                      getResponsiveViewer(){
                          return getResponsiveViewer();
                      }
                  },
                  styles: styleGuide,
                  events: {
                      onBeforeShow(){
                          return new Promise(resolve => {
                              const resolveEvent = () => {
                                // Tour audio
                                if (guide.meta.audio_enabled == "1" && getTourOperator().isTourAudioSettingsEnabled) {
                                    getTourOperator().audioPlayback()?.pause();

                                    debounceFunction(() => {
                                        getTourOperator().audioPlayback()?.play(guide.id);
                                    });
                                }

                                resolve();
                              }

                              setTimeout(async () => {
                                  if(!attachToElement) return resolveEvent();
  
                                  await responsiveViewer.centerGracefully();
  
                                  const animationDuration = Number(guide.meta.responsive_transition_duration || 1.5) * 1000;
  
                                  await getTourOperator().currentScreen.tourGuide.currentStep.invoke("scrollToView");
                                  
                                  setTimeout(() => {
                                      try {
                                          const hasPosition = guide.meta.responsive_position_x && guide.meta.responsive_position_y && guide.meta.responsive_position_scale &&  guide.meta.responsive_position_screen_width && guide.meta.responsive_position_screen_height;
                                      
                                          if(hasPosition){    
                                              responsiveViewer.lookAt(
                                                  guide.meta.responsive_position_x,
                                                  guide.meta.responsive_position_y,
                                                  guide.meta.responsive_position_scale,
                                                  guide.meta.responsive_position_screen_width,
                                                  guide.meta.responsive_position_screen_height,
                                                  animationDuration,
                                                  guide.meta.responsive_transition_easing,
                                              );
                                          } else {
                                              responsiveViewer.autoLookAt(
                                                  attachToElement, 
                                                  animationDuration,
                                                  guide.meta.responsive_transition_easing,
                                              );
                                          }
                                      } catch (error) {
                                          console.log(error);
                                      } finally {
                                          setTimeout(resolveEvent, animationDuration + 1500);
                                      }
                                  }, 100);
                              }, 10);  
                          });
                      },
                      onShow(instance){
                          startAutoAdvance(instance);

                          // Tour audio
                          getTourOperator().self.shouldShowAudioPlayer = guide.meta.audio_enabled == "1" && getTourOperator().isTourAudioSettingsEnabled == true;
                      },
                      onHide(){
                          cleanupAutoAdvance();
                          getTourOperator().audioPlayback()?.pause();
                      }
                  }
              };

              function startAutoAdvance(instance){
                if(guide.meta.auto_advance_enabled == "1" && guide.meta.auto_advance_duration){
                    const durationInMiliseconds = Number(guide.meta.auto_advance_duration) * 1000;

                    autoAdvanceTimer = setTimeout(() => {
                        if(guide.type == 'link'){
                            onLinkGuideElementClick();
                        } else {
                            gotToNextStep(instance);
                        }
                    }, durationInMiliseconds);
                }
              }

              function cleanupAutoAdvance(){
                clearTimeout(autoAdvanceTimer);
              }
  
              function gotToNextStep(instance){
                  if(isLastGuideIndex){
                      const operator = getTourOperator();
  
                      if(operator.isLastScreen()){
                          getTourOperator()?.tourGuideInstance()?.next();    
                      } else {
                          operator.next();
                          operator.startTour();
                      }
                  } else {
                      getTourOperator()?.tourGuideInstance()?.next();
                  }
              }
  
              function gotToPreviousStep(instance){
                  if(isFirstGuideIndex){
                      const operator = getTourOperator();
  
                      if(!operator.isFirstScreen()){
                          operator.back();
                          operator.startTourFromLast();
                      }
                  } else {
                      getTourOperator()?.tourGuideInstance()?.back();
                  }
              }
  
              function restartTour(){
                  const operator = getTourOperator();
                  operator.start();
              }
  
              const buttons = {
                  button1: {
                      text: guide.meta.button1_text,
                      onClick(instance){
                          if(guideButtonTemplate === "prompt") return;
  
                          const guideAction = guide.meta.button1_action || "next";
  
                          if(guideAction === "next"){
                              gotToNextStep(instance)
                          } else if(guideAction === "back"){
                              gotToPreviousStep(instance);
                          } else if(guideAction === "restart"){
                              restartTour();
                          }
                      }
                  },
                  button2: {
                      text: guide.meta.button2_text,
                      onClick(instance){
                          if(["prompt", "single_button"].includes(guideButtonTemplate)) return;
  
                          const guideAction = guideButtonTemplate === "single_button_with_link" ? "link" : guide.meta.button2_action || "back";
  
                          if(guideAction === "link"){
                              guide.meta.button2_external_url && window.open(convertToHttpUrl(guide.meta.button2_external_url), "_blank");
                          } else if(guideAction === "next"){
                              gotToNextStep(instance)
                          } else if(guideAction === "back"){
                              gotToPreviousStep(instance);
                          } else if(guideAction === "restart"){
                              restartTour();
                          }
                      }
                  },
              };
  
              const linkDetails = {
                  type: guide.meta.link_type,
                  external_url: guide.meta.link_external_url || "",
                  external_url_target: guide.meta.link_external_url_target || "_self",
                  screen_id: Number(guide.meta.link_screen_id) || 0,
                  step_id: Number(guide.meta.link_step_id) || 0,
              };

              function onLinkGuideElementClick(){
                 switch(linkDetails.type){
                     case "external": {
                         if(linkDetails.external_url) window.open(convertToHttpUrl(linkDetails.external_url), linkDetails.external_url_target);
                         break;
                     }
                     case "screen": {
                        if(!linkDetails.screen_id) {
                            gotToNextStep();
                        } else {
                            const operator = getTourOperator();
                            operator.show(linkDetails.screen_id);
                            operator.startTour();
                        }
                         break;
                     }
                     case "step": {
                         const operator = getTourOperator();
                     
                         if(operator.currentScreen.id === linkDetails.screen_id){
                             operator.showGuide(linkDetails.step_id);
                         } else {
                             operator.show(linkDetails.screen_id);
                             setTimeout(() => {
                                 operator.showGuide(linkDetails.step_id);
                             }, 500);
                         }
                     }
                 }
              }

              // Guide & lightbox options
              if(["guide", "lightbox"].includes(guide.type)){
                  stepOptions = {
                      ...stepOptions,
                      options: {
                          ...stepOptions.options,
                          buttons,
                      }
                  }
              } else if(guide.type === "link"){
                  stepOptions = {
                      ...stepOptions,
                      options: {
                          ...stepOptions.options,
                          link: linkDetails,
                          onElementClick: onLinkGuideElementClick,
                      }
                  }
              } else if(guide.type === "input"){
                  stepOptions = {
                      ...stepOptions,
                      options: {
                          ...stepOptions.options,
                          // Validation
                          validation: guide.meta.validation,
                          characterLength: guide.meta.character_length,
                          predefineValue: guide.meta.pre_define_value,
                          // Input field
                          placeholder: guide.meta.placeholder,
                          defaultValue: guide.meta.default_value,
                          // Selectbox
                          selectedValueOption: guide.meta.selected_value_option,
                          selectboxOptions: guide.meta.selectbox_options,
                          // Events
                          onSuccess(instance){
                            return new Promise((resolve) => {
                                 gotToNextStep(instance);
  
                                 resolve();
                            });
                          }
                      }
                  }
              } else if(guide.type === "group"){
                  // Please refactor
                  const stepCollection = guide.guides.map((guideObject) => {
                      let options = {};
  
                      if(screenType != "html"){
                            try {
                                const guideDrawerInstance = new GuideDrawer({
                                    doc: mainDocument,
                                });
                
                                const guideElement = guideDrawerInstance.createGuideElement({
                                    elementId: guideObject.meta.guide_element_id,
                                    getPositionProps: () => {
                                        return {
                                            elementX: guideObject.meta.guide_element_x,
                                            elementY: guideObject.meta.guide_element_y,
                                            elementWidth: guideObject.meta.guide_element_width,
                                            elementHeight: guideObject.meta.guide_element_height,
                                            containerX: guideObject.meta.guide_element_container_x,
                                            containerY: guideObject.meta.guide_element_container_y,
                                            containerWidth: guideObject.meta.guide_element_container_width,
                                            containerHeight: guideObject.meta.guide_element_container_height,
                                        }
                                    },
                                    getContainer: () => {
                                        return mainDocument.body.querySelector("#storyscale-screen-element");
                                    },
                                    className: "",
                                });
                
                                mainDocument.body.appendChild(guideElement);
                            } catch (error) {
                                console.log(error);
                            }
                      }
              
                      const guideButtonTemplate = guideObject.type !== "link" 
                      ? guideObject.meta.template || "single_button"
                      : guideObject.meta.template || "prompt";
  
                      const buttons = {
                          button1: {
                              text: guideObject.meta.button1_text,
                              onClick(){
                                  let instance = storyGuide;
  
                                  try{
                                      instance.currentStep.invoke("clearTimeout")
                                  }catch(error){
                                      console.log(error)
                                  }
  
                                  if(guideButtonTemplate === "prompt") return;
  
                                  const guideAction = guideObject.meta.button1_action || "next";
  
                                  if(guideAction === "next"){
                                      gotToNextStep(instance)
                                  } else if(guideAction === "back"){
                                      gotToPreviousStep(instance);
                                  } else if(guideAction === "restart"){
                                      restartTour();
                                  }
                              }
                          },
                          button2: {
                              text: guideObject.meta.button2_text,
                              onClick(){
                                  let instance = storyGuide;
  
                                  try{
                                      instance.currentStep.invoke("clearTimeout")
                                  }catch(error){
                                      console.log(error)
                                  }
  
                                  if(["prompt", "single_button"].includes(guideButtonTemplate)) return;
  
                                  const guideAction = guideButtonTemplate === "single_button_with_link" ? "link" : guideObject.meta.button2_action || "back";
  
                                  if(guideAction === "link"){
                                      guide.meta.button2_external_url && window.open(convertToHttpUrl(guideObject.meta.button2_external_url), "_blank");
                                  } else if(guideAction === "next"){
                                      gotToNextStep(instance)
                                  } else if(guideAction === "back"){
                                      gotToPreviousStep(instance);
                                  } else if(guideAction === "restart"){
                                      restartTour();
                                  }
                              }
                          },
                      };
  
                      switch(guideObject.type){
                          case "guide": {                
                              options = {
                                  type: "guide",
                                  template: guideButtonTemplate,
                                  buttons,
                              };
  
                              break;
                          }
                          case "link": {
                              const linkDetails = {
                                  type: guideObject.meta.link_type,
                                  external_url: guideObject.meta.link_external_url || "",
                                  external_url_target: guideObject.meta.link_external_url_target || "_self",
                                  screen_id: Number(guideObject.meta.link_screen_id) || 0,
                                  step_id: Number(guideObject.meta.link_step_id) || 0,
                                  link_event: guideObject.meta.link_event || "click",
                                  link_event_hover_delay: Number(guideObject.meta.link_event_hover_delay || 0) * 1000,
                              };
  
                              options = {
                                  type: "link",
                                  template: guideButtonTemplate,
                                  link: linkDetails,
                                  onElementClick(instance){
                                      switch(this.link.type){
                                          case "external": {
                                              if(this.link.external_url) window.open(convertToHttpUrl(this.link.external_url), this.link.external_url_target);
                                              break;
                                          }
                                          case "screen": {
                                              if(!this.link.screen_id) {
                                                  gotToNextStep(instance);
                                              } else {
                                                  const operator = getTourOperator();
                                                  operator.show(this.link.screen_id);
                                                  operator.startTour();
                                              }
                                              
                                              break;
                                          }
                                          case "step": {
                                              const operator = getTourOperator();
                                              
                                              if(operator.currentScreen.id === this.link.screen_id){
                                                  operator.showGuide(this.link.step_id);
                                              } else {
                                                  operator.show(this.link.screen_id);
                                                  setTimeout(() => {
                                                      operator.showGuide(this.link.step_id);
                                                  }, 500);
                                              }
                                          }
                                      }
                                  }
                              };
  
                              break;
                          }
                      }
  
                      const attachToElement = domGetElement(guideObject.meta.elementPath, mainDocument);
  
                      const styleGuide = constructStyleGuide(
                          guideObject.type,
                          tour.style_guide, 
                          guideObject.style_guide, 
                          guideObject.meta.override_styles == "1"
                      );
  
                      return {
                          type: "guide",
  
                          container: responsiveViewer.guideContainer,
                          
                          trigger_hover: guideObject.meta.trigger_hover == '1' ? true : false,
  
                          preview: true,
                          template: guideObject.mobile_template || guideObject.meta.template,
                          title: guideObject.meta.mobile_title || guideObject.meta.title,
                          description: guideObject.meta.mobile_description || guideObject.meta.description,
                          overlay: guideObject.meta.overlay == '1' ? true : false,
                          overlayType: guideObject.meta.overlay_type,
                          overlayBlurIntensity: guideObject.meta.overlay_blur_intensity,
                      
                          // Glow    
                          glowEnabled: guideObject.meta.glow_enabled == '1',
                          glowType: guideObject.meta.glow_type,
                          glowColor: guideObject.meta.glow_color,
  
                          // Beacon    
                          beaconEnabled: guideObject.meta.beacon_enabled == '1',
                          beaconPositionX: guideObject.meta.beacon_position_x,
                          beaconPositionY: guideObject.meta.beacon_position_y,
                          beaconType: guideObject.meta.beacon_type,
                          beaconColor: guideObject.meta.beacon_color,
  
                          attachTo: {
                              element: attachToElement,
                              on: guideObject.meta.position || 'top'
                          },
  
                          buttons: {
                              button1: {
                                  text: guideObject.meta.button1_text,
                                  action: guideObject.meta.button1_action
                              },
                              button2: {
                                  text: guideObject.meta.button2_text,
                                  external_url: guideObject.meta.button2_external_url,
                                  action: guideObject.meta.button2_action
                              }
                          },
                          getTotalNumberOfSteps(){
                              return getTourOperator().getTotalNumberOfSteps();
                          },
                          getCurrentStepNumber(){
                              return getTourOperator().getCurrentStepNumber();
                          },
                          getResponsiveViewer(){
                              return getResponsiveViewer();
                          },
                          styles: styleGuide,
                          events: {
                              onBeforeShow(){
                                  return new Promise(resolve => {
                                      setTimeout(async () => {
                                          if(!attachToElement) return resolve();
  
                                          await responsiveViewer.centerGracefully();
  
                                          const animationDuration = Number(guideObject.meta.responsive_transition_duration || 1.5) * 1000;
  
                                          await getTourOperator().currentScreen.tourGuide.currentStep.invoke("scrollToView");
  
                                          setTimeout(() => {
                                              try {
                                                  const hasPosition = guideObject.meta.responsive_position_x && guideObject.meta.responsive_position_y && guideObject.meta.responsive_position_scale &&  guideObject.meta.responsive_position_screen_width && guideObject.meta.responsive_position_screen_height;
                                              
                                                  if(hasPosition){    
                                                      responsiveViewer.lookAt(
                                                          guideObject.meta.responsive_position_x,
                                                          guideObject.meta.responsive_position_y,
                                                          guideObject.meta.responsive_position_scale,
                                                          guideObject.meta.responsive_position_screen_width,
                                                          guideObject.meta.responsive_position_screen_height,
                                                          animationDuration,
                                                          guideObject.meta.responsive_transition_easing,
                                                      );
                                                  } else {
                                                      responsiveViewer.autoLookAt(
                                                          attachToElement, 
                                                          animationDuration,
                                                          guideObject.meta.responsive_transition_easing,
                                                      );
                                                  }
                                              } catch (error) {
                                                  console.log(error);
                                              } finally {
                                                  setTimeout(resolve, animationDuration + 1500);
                                              }
                                          }, 100);
                                      }, 10);  
                                  });
                              }
                          },
  
                          ...options,
                      }
                  }).filter(Boolean);
  
                  stepOptions = {
                      ...stepOptions,
                      options: {
                          steps: stepCollection,
                          getTotalNumberOfSteps(){
                              return getTourOperator().getTotalNumberOfSteps();
                          },
                          getCurrentStepNumber(){
                              return getTourOperator().getCurrentStepNumber();
                          },
                          getTourOperator: () => getTourOperator(),
                      }
                  }
              }
  
              storyGuide.addStep({
                  ...stepOptions,
              });
          });
  
          return storyGuide;
      }
  
      function showIframe(iframe){
          iframe.style.removeProperty("display");
      }
  
      function hideIframe(iframe){
          iframe.style.display = "none";
      }
  
      export default {
          props: ["responsiveMode"],
          components: {
              Loading,
              AudioPlayBack,
              WelcomeScreen
          },
          data(){
              return {
                  loading: true,
                  tour: {},
                  operator: {},
                  responsiveViewer: null,

                  // Screen loader
                  screenLoadMap: new Map(),

                  shouldShowAudioPlayer: false,

                  welcomeSceenWasActive: false,
                  shouldShowWelcomeScreen: false,
                  shouldStartTourManually: false,
              }
          },
          created(){
              injectCSS(`
                  .story-guide-iframe{
                      width: 100%;
                      height: 100%;
                      border: none;
                      position: absolute;
                      left: -100%;
                      visibility: hidden;
                  }
                  .story-guide-iframe.active{
                      visibility: visible;
                      left: 0px;
                  }
  
                  .story-guide-viewer-iframe{
                      width: 100%;
                      height: 100%;
                      border: none;
                      position: absolute;
                      z-index: 2147482637;
                  }
              `);
          },
          mounted(){},
          destroyed(){
              this.removePreviews();
          },
          methods: {
              init({ tour, variableMap }){
                  this.removePreviews();
  
                  const self = this;
  
                  return this.createViewer().then(() => {
                      this.tour = tour;
  
                      this.operator = {
                          screens: [],
                          variableMap,
                          currentScreen: null,
                          currentIndex: null,
                          audioPlayback: () => self.$refs.audioPlayback,
                          self,
                          isTourAudioSettingsEnabled: this.tour?.is_audio_enabled,
                          tourGuideInstance(){
                            return this.currentScreen.tourGuide;
                          },
                          totalScreens(){
                              return this.screens.length;
                          },
                          isFirstScreen(){
                              return this.currentIndex == 0;
                          },
                          isLastScreen(){
                              return this.currentIndex == (this.totalScreens() - 1);
                          },
                          getScreen(screenId){
                              let screenIndex = null;
  
                              const screen = this.screens.find((item, index) => {
                                  if(screenId == item.id){
                                      screenIndex = index;  
  
                                      return true;
                                  }
  
                                  return false;
                              });
  
                              return {
                                  index: screenIndex,
                                  screen
                              }
                          },
                          show(screenId){
                              const { screen, index } = this.getScreen(screenId);
  
                              if(screen){
                                  const prevScreen = this.currentScreen;

                                  if(this.currentScreen){
                                      // Hide current step
                                      if(this.currentScreen.tourGuide.currentStep){
                                          this.currentScreen.tourGuide.currentStep.hide()
                                      }
                                  }
  
                                  this.currentIndex = index;
                                  this.currentScreen = screen;
                                  
                                  this.currentScreen.show({
                                    beforeShow(){
                                        prevScreen && prevScreen.hide();
                                    }
                                  }).then(() => this.startTour()).catch(console.log);
                              }
                          },
                          showGuide(stepId){
                              this.currentScreen.tourGuide.showStep(stepId);
                          },
                          startTour(){
                              // Welcome Screen
                              if(this.currentScreen && !self.shouldStartTourManually){
                                  try {
                                      setTimeout(() => {
                                          self.responsiveViewer.center();
                                      }, 10);
  
                                      this.currentScreen.iframe.contentWindow.scrollTo(0, 0);
                                  } catch (error) {
                                      console.log(error);
                                  } 
  
                                  setTimeout(() => this.currentScreen.tourGuide?.start(), 1000);
                              }
                          },
                          startTourFromLast(){
                              if(this.currentScreen){
                                  setTimeout(() => this.currentScreen.tourGuide?.startFromLast(), 500);
                              }
                          },
                          next(){
                              if (this.currentScreen && !this.isLastScreen()) {
                                  const nextIndex = this.currentIndex + 1;
                                  const screen = this.screens[nextIndex];
  
                                  if (screen) {
                                      const prevScreen = this.currentScreen;
                                      
                                      this.currentScreen.tourGuide.complete();
                                      
                                      this.currentIndex = nextIndex;
                                      this.currentScreen = screen;
                                      
                                      this.currentScreen.show({
                                        beforeShow(){
                                            prevScreen && prevScreen.hide();
                                        }
                                      }).then(() => this.startTour()).catch(console.log);
                                  }
                              }
                          },
                          back(){
                              if (this.currentScreen) {
                                  const backIndex = this.currentIndex - 1;
                                  const screen = this.screens[backIndex]
  
                                  if (screen) {
                                      const prevScreen = this.currentScreen;

                                      this.currentScreen.tourGuide.complete();
  
                                      this.currentIndex = backIndex;
                                      this.currentScreen = screen;
                                      
                                      this.currentScreen.show({
                                        beforeShow(){
                                            prevScreen && prevScreen.hide();
                                        }
                                      }).then(() => this.startTour()).catch(console.log);
                                  }
                              }
                          },
                          start(){
                              const screen = this.screens[0];
                              
                              if(screen){
                                  const prevScreen = this.currentScreen;

                                  if(this.currentScreen){
                                      this.currentScreen.tourGuide.complete();
                                  }
                                      
                                  this.currentIndex = 0;
                                  this.currentScreen = screen;
                                  
                                  this.currentScreen.show({
                                    beforeShow(){
                                        prevScreen && prevScreen.hide();
                                    }
                                  }).then(() => this.startTour()).catch(console.log)
                              }
                          },
                          getTotalNumberOfSteps(){
                              return this.screens.reduce((steps, screen) => steps + screen.guides.length, 0)
                          },
                          getCurrentStepNumber(){
                              let step = 0;
                            
                              for(const screen of this.screens){
                                  if(this.currentScreen?.id == screen.id){
                                      step += screen.tourGuide?.currentIndex != null ? screen.tourGuide?.currentIndex + 1 : 0;
                                      
                                      break;
                                  } else {
                                      step += screen.guides.length;
                                  }
                              }

                              return step;
                          },
                      };
  
                      return this.operator;
                  });
              },
              loadScreen(screen){
                const self = this;

                const screenIndex = self.operator.screens.findIndex(screenItem => screenItem.id == screen.id);

                if(screenIndex == -1) return Promise.reject();

                // Check if screen is already loading, return screen loader promise instance
                if(screen.loading) return Promise.resolve(self.screenLoadMap.get(screen.id));

                if(screen.isReady && screen.iframe) return Promise.resolve();

                // Set screen loading true
                self.operator.screens[screenIndex].loading = true;

                return new Promise(async (resolve, reject) => {
                      try {
                          let html = "";

                          try {
                              html = await self.fetchScreenContent(screen.html_file_url, screen.type || "html");
                          } catch (error) {
                              console.error(error);
                              
                              html = "";
                          }

                          const content = addScriptTagStringInHtml(process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js", html);
                            
                          const htmlBlob = new Blob([content], {type: "text/html"});

                          const htmlURL = URL.createObjectURL(htmlBlob);

                          const iframe = createResponsiveIframe(htmlURL);
  
                          setTimeout(() => {
                              hideIframe(iframe);
                          }, 100)
  
                          self.responsiveViewer.previewHolder.appendChild(iframe);
  
                          await HTMLParser.renderIframe({
                              iframe,
                          },(element) => {
                              if(element.tagName == "TEXT"){
                                if(shouldRenderVariables(element.innerText)){
                                    element.innerText = renderVariables({
                                        content: element.innerText,
                                        variables: self.operator.variableMap,
                                    });
                                }
                              }
                          });
  
                          const mainDocument = iframe.contentDocument;
  
                          const checkStoryGuideLoaded = () => {
                              const mainWindow = iframe.contentWindow;
  
                              if(mainWindow.StoryGuideResponsive){
                                  const tourGuide = setupGuides(iframe, screen.guides, {
                                      screenType: screen.type || "html",
                                      getTour(){
                                          return self.tour;
                                      },
                                      getTourOperator(){
                                          return self.operator;
                                      },
                                      getResponsiveViewer(){
                                          return self.responsiveViewer;
                                      }
                                  });
  
                                  // Delete screen loader promise instance
                                  self.screenLoadMap.delete(screen.id);

                                  self.operator.screens[screenIndex].loading = false;
                                  self.operator.screens[screenIndex].isReady = true;
                                  self.operator.screens[screenIndex].iframe = iframe;
                                  self.operator.screens[screenIndex].tourGuide = tourGuide;

                                  resolve();
                               } else {
                                  window.requestAnimationFrame(checkStoryGuideLoaded);
                              }
                          }
  
                          await onIframeReady(iframe);

                          addStyles(mainDocument, `html, body { margin: 0; padding: 0;} body {background: #fff}`);
  
                          window.requestAnimationFrame(checkStoryGuideLoaded);
                      } catch (err) {
                          reject(err);
                      }
                  })     
              },
              setScreens(screens){
                    if(!this.operator) return;

                    const self = this;

                    this.operator.screens = screens.map((screen, index) => {
                        return {
                            id: screen.id,
                            original_id: screen.original_tour_screen_id,
                            html_file_url: screen.html_file_url,
                            guides: screen.guides,
                            name: screen.name,
                            type: screen.type,
                            order: screen.order,
                            isReady: false,
                            iframe: null,
                            tourGuide: null,
                            loading: false,
                            async show({ beforeShow = null} = {}){
                                return self.loadScreen(this).then(() => {
                                    if(!this.iframe) return;

                                    (beforeShow && typeof beforeShow == 'function') && beforeShow();

                                    showIframe(this.iframe);
  
                                    this.iframe.style.visibility = "hidden";

                                    this.iframe.classList.add("active");

                                    self.responsiveViewer.center();
                                    self.responsiveViewer.center();

                                    setTimeout(() => {
                                        this.iframe.style.visibility = "visible";

                                        showIframe(this.iframe);
                                    }, 100);

                                    // Load next screen
                                    setTimeout(() => {
                                        const nextIndex = Math.min(index + 1, self.operator.screens.length - 1);

                                        const nextScreen = self.operator.screens[nextIndex];

                                        self.screenLoadMap.set(nextScreen.id, self.loadScreen(nextScreen));
                                    }, 10);

                                    self.loading = false;
                                }).catch(error => console.log(error));
                            },
                            hide(){
                                if(!this.iframe) return;

                                hideIframe(this.iframe);
  
                                this.iframe.style.visibility = "hidden";

                                this.iframe.classList.remove("active");

                                self.responsiveViewer.center();
                                self.responsiveViewer.center();
                            },
                        }
                    }).sort((screenOne , screenTwo) => screenOne.order - screenTwo.order);
              },
              startTour(showLoading = true){
                  this.loading = showLoading;
  
                  this.operator?.start();
              },
              // Welcome Screen
              startTourManually() {
                  this.shouldStartTourManually = false;
                  this.shouldShowWelcomeScreen = false;
                  this.startTour(false);
              },
              removePreviews(){
                  this.operator?.screens?.forEach((screen) => screen.iframe.remove())
              },
              async fetchScreenContent(fileUrl, screenType = "html"){
                   if(screenType == "html"){
                       return fetch(fileUrl).then(res => res.text())
                   }

                   return HTMLParser.toString(HTMLParser.generateAST({
                       type: screenType,
                       content_url: fileUrl,
                   }));
              },
              createViewer(){
                  return new Promise((resolve) => {
                      const viewerIframe = createResponsiveViewerIframe();
  
                      this.$refs.tourHolder.appendChild(viewerIframe);
  
                      this.responsiveViewer = new ResponsiveViewer({
                          iframe: viewerIframe,
                          onReady: () => {
                              resolve();
                          }
                      });
                  })
              }
          },
          watch: {
              responsiveMode(isOpen){
                  if(isOpen) {
                      setTimeout(() => {
                          this.operator && this.operator.startTour && this.operator.startTour();
                      }, 0);
                  }
              }
          }
      }
  </script>