<template>
    <div class="widget_panel">
        <lightViewer 
        v-if="experienceType === 'viewer' && dataObj && show" 
        :obj="dataObj" 
        @onClose="closeModal"/>

        <spotlightLight 
        v-else-if="experienceType === 'spotlight' && dataObj && show" 
        :obj="dataObj" 
        @onClose="closeModal"/>
    </div>
</template>
<script>
  import lightViewer from "../components/viewer/light";
  import spotlightLight from "../components/spotlight/spotlightLight";

  export default {
    props: ['token'],
    components: {
        lightViewer,
        spotlightLight,
    },
    data () {
      return {
        dataObj: {},
        loading: false,
        tokenData: this.token,
        experienceType: null,
        show: false,
      };
    },
    created () {
        if(this.token) this.getExperienceDetails(this.token)
    },
    methods: {
      getExperienceDetails(token) {
        this.loading = true;
        this.axios.get( `frontend/view-experience?encoded_input=${token}` )
        .then( response => {
            const { data } = response.data;
        
            const exp = data.experience;

            this.experienceType = exp.experience_type;

            this.dataObj = {
                ...exp,
                display_pages: [],
                paywall_cta_id: data.paywall_cta,
                primary_cta_id: data.primary_cta,
                nudge_cta_id: data.nudge_details,
                style_guide_id: data.style_guide,
                primary_sequence: null,
                sequences: [],
                keen_data: data.user.keen_data,
            };

            this.dataObj.delivery_type = "launcher";

            data.sequences.map( ( sq ) => {
                if ( sq.pivot.is_primary === 1 ) {
                    this.dataObj.primary_sequence = { ...sq };
                } else {
                    this.dataObj.sequences.push( { ...sq } );
                }
                return sq;
            });

            switch(this.experienceType){
                case 'viewer':
                    break;
                case 'spotlight':
                    if ( data.experience.launch_with_splash ) {
                        this.dataObj.splash_asset_id = data.splash_image;
                    } else {
                        this.dataObj.splash_asset_id = null;
                    }
                    break;
            }

            this.loading = false;
        })
        .catch( ( error ) => {
            console.log(error)
            const { message } = error.response.data;
            console.log(message);
        })
      },
      showModal(){
          this.show = true;
      },
      closeModal(){
          setTimeout(() => {
              this.show = false;
          },100)
      },
    },
  }
</script>