import Vue from 'vue';

export default ( message, type ) => {
    switch ( type ) {
        case "s":
            Vue.$toast.success( `<b>${ message }</b>`, {
                position: 'top',
            } );
            break;
        case "e":
            Vue.$toast.error( `<b>${ message }</b>`, {
                position: 'top',
            } );
            break;
        case "i":
            Vue.$toast.info( `<b>${ message }</b>`, {
                position: 'top',
            } );
            break;
        default:
            Vue.$toast.error( `<b>Invalid Toast Type</b>`, {
                position: 'top',
            } );
    }
};
