import { getGUID, generateGUID } from "@/utils/guid";

export default class {
    constructor(options) {
        this.options = options;
        this.isDev = options.dev ? true : false;
        this.logging = options.logging ? true : false;
        this.platform_customer_id = options.platform_customer_id;
        this.timers = {};
        this.timeSpentMap = {};
        this.pageClickHandlers = new Map();
        this.totalClicks = 0;
        this.demoCenterCompleted = false;
        this.sessionId = generateGUID();

        this.resourceStateMap = {};

        this.subscriptions = {};

        this.resetGuidesViewed();
        this.setTotalGuides(0);
        this.setupOnTabVisibilityChange();
        this.setupPageClickHandler();
    }

    onEvent(eventName, callback) {
        if (!this.subscriptions[eventName]) this.subscriptions[eventName] = [];

        this.subscriptions[eventName].push(callback);
    }

    isTabVisible() {
        return document.visibilityState === 'visible';
    }

    onClose(analyticsData) {
        if (this.demoCenterCompleted) return;

        const options = {
            id: `demo_center_${analyticsData.demo_center.id}`,
        };

        this.demoCenterCompleted = true;

        this.addTotalTimeEvent(analyticsData, options);

        this.addCompletionEvent(analyticsData, options);

        this.addResourceStateEvent(analyticsData);
        
        this.resetPageClickHandlers();
    }

    setupOnTabVisibilityChange() {
        document.addEventListener("visibilitychange", () => {
            // Timer setup
            Object.keys(this.timers).forEach((timerId) => {
                if (timerId) {
                    if (this.isTabVisible()) {
                        this.startTimer(timerId);
                    } else {
                        const endTime = new Date();
                        const startTime = this.getStartTime(timerId)
                        const timeDiff = (endTime - startTime) / 1000;
                        const seconds = Math.round(timeDiff);

                        this.timeSpentMap[timerId] = this.getTimeSpent(timerId) + seconds;
                        this.resetTimer(timerId);
                    }
                }
            });
        });
    }

    setupPageClickHandler(doc = document) {
        const handler = () => this.totalClicks++;

        if (!this.pageClickHandlers.has(doc)) this.pageClickHandlers.set(doc, handler);

        doc.addEventListener('click', handler);
    }

    resetPageClickHandlers() {
        this.pageClickHandlers.forEach((handler, doc) => {
            doc.removeEventListener('click', handler)
        });

        this.pageClickHandlers = new Map();
        this.totalClicks = 0;
    }

    startTimer(id) {
        if (this.isTabVisible()) {
            this.timers[id] = new Date();
        }
    }

    resetTimer(id) {
        this.timers[id] = null;
    }

    getStartTime(id) {
        return this.timers[id] || new Date();
    }

    getTimeSpent(id) {
        return (this.timeSpentMap[id] || 0)
    }

    getEndTimeSeconds(id) {
        const endTime = new Date();
        const startTime = this.getStartTime(id)

        const timeDiff = (endTime - startTime) / 1000;

        const seconds = Math.round(timeDiff);

        this.resetTimer();

        const finalSeconds = this.getTimeSpent(id) + seconds;

        return finalSeconds;
    }

    setTotalGuides(count) {
        this.totalGuides = count;
    }

    addToGuidesViewed(guideId) {
        this.guidesViewed.push(guideId);
    }

    resetGuidesViewed() {
        this.guidesViewed = [];
    }

    getGuideViews() {
        const totalGuidesViewed = [...(new Set(this.guidesViewed))].length;

        const totalGuides = this.totalGuides;

        return {
            guide_viewed: totalGuidesViewed,
            total_guides: totalGuides,
        }
    }

    addTotalTimeEvent(analyticsData, options = {}) {
        const event_type = [options.type, "total_time"].filter(Boolean).join("_");

        const seconds = parseInt(this.getEndTimeSeconds(options?.id));

        if (seconds > 0) {
            this.trackEvent(analyticsData, {
                event_type,
                time_spent: seconds,
                ...(options.entries || {}),
            }, {
                keepalive: true,
            });
        }
    }

    addCompletionEvent(analyticsData, options = {}) {
        const { guide_viewed, total_guides } = this.getGuideViews();

        const seconds = parseInt(this.getEndTimeSeconds(options?.id));
        const totalClicks = this.totalClicks;

        this.trackEvent(analyticsData, {
            event_type: "completion",
            guide_viewed,
            total_guides,
            time_spent: seconds,
            total_clicks: totalClicks,
            url: window.location.href,
        }, {
            keepalive: true,
        });
    }

    addVistorEvent(analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "visitor",
            url: window.location.href,
        });
    }

    addScreenViewEvent(analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "screen_view",
        });
    }

    addGuideViewEvent(analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "guide_view",
        });
    }

    addResourceViewEvent(entry, analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "resource_view",
            ...entry
        });
    }

    addConversionEvent(analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "conversion",
        });
    }

    addChatEntry(entry, analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "chat",
            ...entry,
        });
    }

    addJourneyEntry(entry, analyticsData) {
        this.trackEvent(analyticsData, {
            event_type: "journey",
            ...entry,
        });
    }

    getApiEndpoint() {
        return process.env.VUE_APP_API_URL + "analytics/demo-center/events/create";
    }

    getChatStoreApiEndpoint() {
        return process.env.VUE_APP_API_URL + "analytics/demo-center/chat/create";
    }

    updateResourceStateMap(id, callback) {
        this.resourceStateMap[id] = callback(this.resourceStateMap[id] || {});

        if(this.isDev){
            console.log(this.resourceStateMap);
        }
    }

    addResourceStateEvent(analyticsData) {
        Object.values(this.resourceStateMap).forEach((state) => {
            this.trackEvent(analyticsData, {
                ...state
            });
        });
    }

    /**
     * Create demo center analytics event into DB
     * @param {Object} payload 
     * @param {number} payload.platform_customer_id
     * @param {string} payload.event_type
     * @param {object} payload.demo_center
     * @param {number} [payload.time_spent]
     * @param {number} [payload.page]
     * @param {object} [payload.device]
     * @param {object} [payload.geo]
     * @param {object} [payload.extra]
     * @param {object} [payload.created_at]
     */
    createEvent(payload, options = {}) {
        const apiEndpoint = this.getApiEndpoint();

        try {
            this.subscriptions[payload.event_type] && this.subscriptions[payload.event_type].forEach(callback => callback(payload));
        } catch (error) {
            console.log(error);
        }

        return new Promise((resolve, reject) => {
            this.logging && console.log(`Analytics Event: [${payload.event_type}]`, payload);

            if (!this.isDev) {
                fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                    ...options,
                }).then(res => res.json()).then(data => {
                    resolve(data)
                }).catch((err) => reject(err));

            } else {
                resolve();
            }
        });
    }

    constructEventPayload(analyticsData, optional = {}) {
        return {
            guid: getGUID(),
            session_id: this.sessionId,
            platform_customer_id: this.platform_customer_id,
            event_type: "",
            delivery_type: analyticsData?.delivery_type || "share",
            demo_center_id: analyticsData?.demo_center?.id,
            demo_center: analyticsData?.demo_center,
            geo: {
                continent: analyticsData?.customer?.geo?.continent || "",
                country: analyticsData?.customer?.geo?.country || "",
                country_code: analyticsData?.customer?.geo?.countryCode || "",
                city: analyticsData?.customer?.geo?.city || "",
                state: analyticsData?.customer?.geo?.state || "",
                state_code: analyticsData?.customer?.geo?.stateCode || "",
                postal: analyticsData?.customer?.geo?.postal || "",
                latitude: analyticsData?.customer?.geo?.lat || "",
                longitude: analyticsData?.customer?.geo?.lng || "",
                time_zone: analyticsData?.customer?.geo?.timeZone || "",
            },
            company: {
                id: analyticsData?.customer?.company?.id || "",
                name: analyticsData?.customer?.company?.name || "",
                location: analyticsData?.customer?.company?.location || "",
                revenue: analyticsData?.customer?.company?.metrics?.annualRevenue || "",
                employees: analyticsData?.customer?.company?.metrics?.employees || "",
                industry: analyticsData?.customer?.company?.category?.industry || "",
                sub_industry: analyticsData?.customer?.company?.category?.subIndustry || "",
                industry_group: analyticsData?.customer?.company?.category?.industryGroup || "",
                logo: analyticsData?.customer?.company?.logo || "",
                country: analyticsData?.customer?.company?.geo?.country || "",
            },
            user: {
                name: analyticsData?.user.name || "",
                email: analyticsData?.user.email || ''
            },
            device: {
                width: screen.width,
                height: screen.height,
            },
            extra: {
                customer: analyticsData?.customer
            },
            created_at: (new Date()).toISOString(),
            ...optional
        }
    }

    trackEvent(analyticsData, optional = {}, { keepalive = false } = {}) {
        const eventData = this.constructEventPayload(analyticsData, optional);

        this.createEvent(eventData, { keepalive }).catch(err => console.error(err));
    }

    createEntry(payload, options = {}) {
        const eventData = { ...payload, ...this.constructEventPayload(payload, {}) };

        const apiEndpoint = options.apiEndpoint;

        return new Promise((resolve, reject) => {
            if (!this.isDev) {
                fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(eventData),
                    ...options,
                }).then(res => res.json()).then(data => {
                    resolve(data)
                }).catch((err) => reject(err));

            } else {
                resolve();
            }
        });
    }
}