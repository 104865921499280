import {
    generateAttributeString,
} from '../utils';

const ELEMENTS_TO_INJECT_TEXT = ["title", "style", "tspan", "text"];
const ELEMENTS_TO_IGNORE_CHILDREN = ["textarea"];

function getElementContent(elementName, attribute) {
    switch (elementName) {
        case 'textarea': return attribute.value
        default: return ''
    }
}

function toString(rootElement, callback = () => { }) {
    function stringify(buff, doc, parent = {}) {
        let { extraAttrs = {}, config = {} } = callback(doc) ?? {};

        if (!Boolean(config.handleDisabledElement) && doc?.props?.disabled) {
            return buff;
        }

        extraAttrs = {
            ...extraAttrs,
            ...(doc?.props?.disabled ? { "editor-disabled": "" } : {}),
            tabindex: "-1",
        };

        switch (doc.type) {
            case 'text': {
                let content = "";

                if (doc.content) {
                    const attrs = { ...doc.attrs, ...extraAttrs };

                    const attributeString = generateAttributeString(attrs) ?? ''

                    const shouldInjectText = ELEMENTS_TO_INJECT_TEXT.includes(parent?.name);

                    if (!shouldInjectText) {
                        content = `<text ${attributeString}>${doc.content}</text>`
                    } else {
                        content = doc.content;
                    }
                }

                return buff + content;
            }
            case 'tag': {
                if (doc.name == "iframe") {
                    extraAttrs = { ...extraAttrs, src: "" };
                }

                const attrs = { ...doc.attrs, ...extraAttrs };

                const attributeString = generateAttributeString(attrs) ?? '';

                buff += `<${doc.name}${attributeString}${(doc.voidElement ? '/>' : `>${getElementContent(doc.name, attrs)}`)}`;

                if (doc.voidElement) return buff;

                let childrenString = "";

                if (!ELEMENTS_TO_IGNORE_CHILDREN.includes(doc.name) && doc.children) {
                    childrenString = doc.children?.reduce((acc, item) => {
                        return stringify(acc, item, {
                            name: doc.name,
                            type: doc.type,
                            attrs: doc.attrs,
                        })
                    }, '');
                }

                return buff + childrenString + '</' + doc.name + '>'
            }
        }
    }

    return "<!DOCTYPE html>" + stringify('', rootElement)
}

export default toString;