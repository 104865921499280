import { htmlToElement, generateStyleString } from "../..";
import { castStyleValue, convertLoomUrlToEmbedUrl, convertYoutubeUrlToEmbedUrl, increaseStylePriorityInHtml, isLoomUrl, isYoutubeUrl } from "../utils";
import { DEFAULT_IMAGE_URL, DEFAULT_VIDEO_POSTER } from "./constants";

export function text(options = {}) {
    const { props, styles } = options;

    const generatedStyles = generateStyleString({ ...styles, position: "absolute" });

    const html = `<h3 style="${generatedStyles}" class="builder-text" visual-builder-element>${props.content}</h3>`;

    const element = htmlToElement(html)

    function update(options) {
        const { props, styles } = options;

        element.innerText = props.content;

        Object.entries(styles).forEach(([key, value]) => {
            value = castStyleValue(key, value);

            element.style.setProperty(key, value);
        });

        // Add attributes
    }

    function cleanup() { }

    return {
        element,
        update,
        cleanup,
    }
}

export function rich_text(options = {}) {
    const { props, styles } = options;

    const generatedStyles = generateStyleString({ ...styles, position: "absolute" });

    const html = `<div style="${generatedStyles}" class="builder-rich-text" visual-builder-element>${props.content}</div>`;

    const element = htmlToElement(html)

    function update(options) {
        const { props, styles } = options;

        element.innerHTML = increaseStylePriorityInHtml(props.content);

        Object.entries(styles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
    }

    function cleanup() { }

    return {
        element,
        update,
        cleanup,
    }
}

export function button(options = {}) {
    const html = `<button class="builder-button" visual-builder-element></button>`;

    const element = htmlToElement(html)

    function update(options) {
        const { props, styles } = options;

        element.innerText = props.content;

        const finalStyles = {
            ...styles,

            // Default styles
            position: "absolute",
            cursor: "pointer",
        };

        Object.entries(finalStyles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
        element.className = [
            "builder-button",
            props.button_type ? props.button_type : 'primary',
            props.is_link_type ? 'link' : '',
        ].join(" ");
    }

    function events(options = {}) {
        const { props, context } = options;

        if (!context.preview) {
            // Refactor
            element.addEventListener("click", () => {
                context.onButtonClick && context.onButtonClick(props);
            });
        }
    }

    function cleanup() { }

    // Initialize
    update(options);
    events(options);

    return {
        element,
        update,
        cleanup,
    }
}

export function step_counter(options = {}) {
    const { props, styles, context } = options;

    function getLabel() {
        const total = context.getTotalNumberOfSteps();
        const current = context.getCurrentStepNumber();

        const label = `STEP - ${current} OF ${total}`;

        return label;
    }

    const generatedStyles = generateStyleString({ ...styles, position: "absolute", display: "initial !important" });

    const html = `<h6 style="${generatedStyles}" class="top_heading" visual-builder-element>${getLabel()}</h6>`;

    const element = htmlToElement(html)

    function update(options) {
        const { props, styles } = options;

        element.innerText = getLabel();

        Object.entries(styles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
    }

    function cleanup() { }

    return {
        element,
        update,
        cleanup,
    }
}

export function box(options = {}) {
    const html = `<div visual-builder-element></div>`;

    const element = htmlToElement(html)

    function update(options) {
        const { styles } = options;

        const finalStyles = {
            // Default
            "border-width": "0px",

            // Override
            ...styles,

            // Persisted
            position: "absolute",
            "border-style": "solid",
        };

        Object.entries(finalStyles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
    }

    function cleanup() { }

    update(options);

    return {
        element,
        update,
        cleanup,
    }
}

export function circle(options = {}) {
    const html = `<div visual-builder-element></div>`;

    const element = htmlToElement(html)

    function update(options) {
        const { styles } = options;

        const finalStyles = {
            // Default
            "border-width": "0px",

            // Override
            ...styles,

            // Persisted
            position: "absolute",
            "border-radius": "50%",
            "border-style": "solid",
        };

        Object.entries(finalStyles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
    }

    function cleanup() { }

    update(options);

    return {
        element,
        update,
        cleanup,
    }
}

export function image(options = {}) {
    const html = `<img alt="storyscale-image-element" visual-builder-element/>`;

    const element = htmlToElement(html)

    function update(options) {
        const { props, styles } = options;

        const finalStyles = {
            // Default
            "border-width": "0px",

            // Override
            ...styles,

            // Persisted
            position: "absolute",
            "border-style": "solid",
        };

        Object.entries(finalStyles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        // Add attributes
        element.setAttribute("src", props.src || DEFAULT_IMAGE_URL);
    }

    update(options);

    function cleanup() { }

    return {
        element,
        update,
        cleanup,
    }
}

export function video(options = {}) {
    const html = `<div visual-builder-element>
        <video alt="storyscale-video-element" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" autoplay></video>
        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0"></iframe>
    </div>`;

    const element = htmlToElement(html)

    const state = {
        src: null,
        external_src: null,
        is_external_url: null,
    };

    function update(options) {
        const { props, styles } = options;

        const isSrcChanged = state.src != props.src;
        const isExternalSrcChanged = state.external_src != props.external_src;
        const isExternalUrl = Boolean(props.is_external_url);
        const isExternalUrlBooleanChanged = state.is_external_url != props.is_external_url;
        const videoTag = element.querySelector("video");
        const iframeTag = element.querySelector("iframe");

        const finalStyles = {
            ...styles,
            // Default styles
            position: "absolute",
        };

        state.src = props.src;
        state.external_src = props.external_src;
        state.is_external_url = props.is_external_url;

        Object.entries(finalStyles).forEach(([key, value]) => {
            element.style.setProperty(key, value)
        });

        videoTag.style.borderRadius = finalStyles["border-radius"] || null;
        iframeTag.style.borderRadius = finalStyles["border-radius"] || null;

        if (isExternalUrl) {
            videoTag.style.display = "none";
            iframeTag.style.display = "block";

            if(isExternalSrcChanged || isExternalUrlBooleanChanged){
                let finalUrl = props.external_src;
    
                try {
                    if (isYoutubeUrl(finalUrl)) {
                        finalUrl = convertYoutubeUrlToEmbedUrl(finalUrl);
                    } else if (isLoomUrl(finalUrl)) {
                        finalUrl = convertLoomUrlToEmbedUrl(finalUrl);
                    }
                } catch (error) { console.log(error); }
    
                if (finalUrl) {
                    iframeTag.removeAttribute("srcdoc")

                    iframeTag.src = finalUrl;
                } else {
                    if(props.external_src){
                        iframeTag.removeAttribute("srcdoc");
                        
                        iframeTag.src = props.external_src;
                    } else {
                        iframeTag.srcdoc = `<video poster="${DEFAULT_VIDEO_POSTER}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></video>`
                    }
                }
            }
        } else {
            videoTag.style.display = "block";
            iframeTag.style.display = "none";
            
            if(isSrcChanged || isExternalUrlBooleanChanged){
                // Add attributes
                videoTag.setAttribute("src", props.src);
    
                if (!props.src) {
                    videoTag.setAttribute("poster", DEFAULT_VIDEO_POSTER);
                    videoTag.removeAttribute("controls");
                } else {
                    videoTag.removeAttribute("poster");
                    videoTag.setAttribute("controls", "true");
                }
            }
        }
    }

    function onSelect(options){
        const { context } = options;

        if(context.preview){
            const videoTag = element.querySelector("video");
            const iframeTag = element.querySelector("iframe");

            setTimeout(() => {
                videoTag.style.removeProperty("pointer-events");
                iframeTag.style.removeProperty("pointer-events");
            }, 100);
        }
    }

    function onDeSelect(options){
        const { context } = options;

        if(context.preview){
            const videoTag = element.querySelector("video");
            const iframeTag = element.querySelector("iframe");

            videoTag.style.pointerEvents = "none";
            iframeTag.style.pointerEvents = "none";
        }
    }

    function cleanup() { }

    update(options);

    onDeSelect(options);

    return {
        element,
        update,
        cleanup,
        onSelect,
        onDeSelect
    }
}