export function generateAST(screen, ast = {}) {
    switch (screen.type || "html") {
        case "html": return ast;
        case "image": {
            return {
                name: "img",
                type: "tag",
                voidElement: true,
                attrs: {
                    id: "storyscale-screen-element",
                    style: "width: 100%; pointer-events: none; position: absolute; top: 0; left: 0;",
                    src: screen.content_url,
                },
            }
        }
    }
}