<template>
    <Transition>
        <div
            style="background: #ffffffd1;
            width: 100%; height: 100vh;
            overflow: visible;
            position: fixed;
            top: 0; left: 0;
            z-index: 2147483647;"
            :style="{ width: `${ 100 }%`, height: `${ height }px`, background: solid ? `#ffffff` : '#ffffffd1'}"
            v-if="!isFixed && loading"
        >
            <div style="display: flex; align-items: center; justify-content: center;" :style="{ width: `${ 100 }%`, height: `${ height }px` }">
                <img
                    src="https://asset.storyscale.com/Ripple-1.7s-200px.gif"
                    style="width: 80px;"
                    alt="Loading..."
                    class="loading-key"
                />
            </div>
        </div>
        <div 
        class="h-100 w-100" 
        style="display: flex;justify-content: center;align-items: center;" 
        v-else-if="isFixed && loading"
        >
        <img
            src="https://asset.storyscale.com/Ripple-1.7s-200px.gif"
            style="width: 80px;"
            alt="Loading..."
            class="loading-key"
        />
        </div>
    </Transition>
</template>
<script>
    export default {
        props: {
            loading: { type: Boolean },
            isFixed: { type: Boolean },
            solid: { type: Boolean,  default: false },
            full: { type: Boolean,  default: false }
        },
        created(){},
        data () {
            return {
                width: window.innerWidth,
                height: window.innerHeight,
            };
        },
    }
</script>
<style scoped>
    .v-enter-active,.v-leave-active {
        transition: opacity 0.6s ease;
    }
    .v-enter-from,.v-leave-to {
        opacity: 0;
    }
</style>
