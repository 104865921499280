function generateSelector(context) {
    let index, pathSelector, localName;

    if (context == "null") throw "not an dom reference";
    // call getIndex function
    index = getIndex(context);

    while (context.tagName && context.tagName !== "BODY") {
        // selector path
        pathSelector = context.localName + (pathSelector ? ">" + pathSelector : "");
        context = context.parentNode;
    }
    // selector path for nth of type
    pathSelector = pathSelector + `:nth-of-type(${index})`;
    return pathSelector;
}

// get index for nth of type element
function getIndex(node) {
    let i = 1;
    let tagName = node.tagName;

    while (node.previousSibling) {
        node = node.previousSibling;
        if (
            node.nodeType === 1 &&
            tagName.toLowerCase() == node.tagName.toLowerCase()
        ) {
            i++;
        }
    }
    return i;
}

function getDomPath(el) {
    if (!el) {
        return;
    }
    let stack = [];
    let isShadow = false;
    while (el.parentNode != null) {

        let sibCount = 0;
        let sibIndex = 0;
        // get sibling indexes
        for (let i = 0; i < el.parentNode.childNodes.length; i++) {
            let sib = el.parentNode.childNodes[i];
            if (sib.nodeName == el.nodeName) {
                if (sib === el) {
                    sibIndex = sibCount;
                }
                sibCount++;
            }
        }
        let nodeName = el.nodeName.toLowerCase();
        if (isShadow) {
            nodeName += "::shadow";
            isShadow = false;
        }
        if (sibCount > 1) {
            stack.unshift(nodeName + ':nth-of-type(' + (sibIndex + 1) + ')');
        } else {
            stack.unshift(nodeName);
        }
        el = el.parentNode;
        if (el.nodeType === 11) { // for shadow dom, we
            isShadow = true;
            el = el.host;
        }
    }
    stack.splice(0, 1); // removes the html element
    return stack.join(' > ');
}

export function domIframeElementPath(element, options) {
    const { shouldEnd } = options;

    const pathList = [];

    function constructPath(el) {
        pathList.push(getDomPath(el));

        const iframe = el.ownerDocument?.defaultView?.frameElement;

        if (!shouldEnd(iframe)) {
            constructPath(iframe);
        }
    }

    constructPath(element);

    return pathList.reverse().join(" -> ");
}

export function domGetElement(path = "", doc = document) {
    if(!path) return null;
    
    const pathList = path.split(" -> ");

    let resultElement = null;

    pathList.forEach((path) => {
        // IFRAME ISSUE - Inner iframe elements
        if(!doc) return;

        const element = doc.querySelector(path);

        if(element && element.tagName == "IFRAME") doc = element.contentDocument;

        resultElement = element;
    });

    return resultElement;
}

export default getDomPath;