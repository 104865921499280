import postscribe from 'postscribe';
import { adjustIframeHeight, hide, isFunction, onIframeReady, show } from '../../../utils';

export default class ConversionWidget {
    constructor(props) {
        const {
            root,
            doc = document,
            onSubmit = () => { },
            onClose = () => { },
            closeButton = true,
        } = props

        this.root = root;
        this.doc = doc;
        this.onSubmit = isFunction(onSubmit) ? onSubmit : null;
        this.onClose = isFunction(onClose) ? onClose : null;
        this.closeButtonEnabled = closeButton;

        this.setupRootElement();
    }

    setupRootElement() {
        if (typeof this.root === 'string') {
            this.root = this.doc.querySelector(this.root);
        }

        if (!this.root) throw new Error("Root element is invalid");
    }

    createContainer() {
        this.container = this.doc.createElement("div");

        this.container.style.cssText = `
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60%;
            max-width: 600px;
            overflow: auto;
            z-index: 2147483647 !important;
            background: #fff;
            padding: 8px;
            border-radius: 8px;
        `;

        // Close button
        if(this.closeButtonEnabled){
            this.closeButton = document.createElement("a");
            this.closeButton.href = "javascript:void(0)";
            this.closeButton.innerHTML = "&times;";
            this.closeButton.title = "Close";
            this.closeButton.style.cssText = `
                float: right;
                text-decoration: none;
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
                background: #fff;
                color: #7f7f7f;
                width: 25px;
                height: 25px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            `;
            if (this.onClose) this.closeButton.addEventListener("click", this.onClose);
            this.container.appendChild(this.closeButton);
        }

        // Script iframe
        this.containerIframe = this.doc.createElement("iframe");
        this.containerIframe.style.cssText = `
            border: none;
            width: 100%;
            height: 100%;
        `;
        this.containerIframe.srcdoc = "<!DOCTYPE html><html><head></head><body><body><html>";
        this.container.appendChild(this.containerIframe);

        this.hideContainer();
    }

    removeContainer() {
        if (this.container) {
            this.container.remove();
            this.container = null;
            this.hideOverlay();
        }
    }

    appendContainer() {
        this.root.appendChild(this.container);
    }

    showContainer() {
        show(this.container)
        this.showOverlay();
    }

    hideContainer() {
        hide(this.container);
    }

    showLoadingScreen() {
        this.loadingScreen = document.createElement("div");

        this.loadingScreen.style.cssText = `
            width: 100%; 
            height: 100%;
            display: flex; 
            align-items: center; 
            justify-content: center;
        `;

        this.loadingScreenImage = document.createElement("img");

        this.loadingScreenImage.src = "https://asset.storyscale.com/Ripple-1.7s-200px.gif";

        this.loadingScreenImage.style.cssText = `width: 80px;`;

        this.loadingScreen.appendChild(this.loadingScreenImage);

        this.container.appendChild(this.loadingScreen);
    }

    removeLoadingScreen() {
        this.loadingScreen.remove();
    }

    showOverlay() {
        this.overlay = document.createElement("div");

        this.overlay.style.cssText = `
           position: fixed;
           top: 0px;
           left: 0px;
           background: #efefefb0;
           z-index: 2147483646 !important;
           width: 100%;
           height: 100%;
        `;

        this.root.appendChild(this.overlay);
    }

    hideOverlay() {
        this.overlay.remove();
        this.overlay = null;
    }

    setupOnSubmitHandler() {
        setTimeout(() => {
            onIframeReady(this.containerIframe).then(() => {
                const contentDocument = this.containerIframe.contentDocument;

                const form = contentDocument.querySelector("form");

                if (form && this.onSubmit) return form.addEventListener("submit", this.onSubmit);

                const iframe = contentDocument.querySelector("iframe");

                onIframeReady(iframe).then(() => {
                    const embededForm = iframe.contentDocument.querySelector("form");

                    if (embededForm && this.onSubmit) embededForm.addEventListener("submit", this.onSubmit);
                });
            });
        }, 100);
    }

    show(ctaCode) {
        this.removeContainer();

        this.createContainer();

        this.appendContainer();

        this.showLoadingScreen();

        hide(this.containerIframe);

        return new Promise((resolve, reject) => {
            try {
                onIframeReady(this.containerIframe).then(() => {
                    postscribe(this.containerIframe.contentDocument.body, ctaCode, {
                        done: () => {
                            this.showContainer();

                            setTimeout(() => {
                                show(this.containerIframe);

                                this.setupOnSubmitHandler();

                                this.removeLoadingScreen();

                                resolve();
                            }, 1000);

                            // TODO: Refactor this!!
                            function autoResizeContainerIframe() {
                                try {
                                    if (this.containerIframe?.parentElement) {
                                        adjustIframeHeight(this.containerIframe)

                                        window.requestAnimationFrame(autoResizeContainerIframe);
                                    }
                                } catch (error) { 
                                    console.log(error);
                                }
                            }

                            autoResizeContainerIframe();
                        }
                    });
                });
            } catch (err) {
                reject(err);
            }
        })
    }

    hide() {
        this.removeContainer();
    }
}
