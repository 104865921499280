<template>
    <div style="position: absolute; top: 0; left: 0;display: flex; width: 100%; height: 100%; overflow: hidden; font-family: Inter, sans-serif;">
        <div class="w-100 h-100">
            <canvas ref="animationCanvas"></canvas>
        </div>

        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -55%);">
            <h1 style="color: #fbf9fc; font-size: 8rem; font-weight: 700; line-height: 1.1;">404</h1>
            <p style="color: #fbf9fc; font-size: 1.25rem; line-height: 1.5;">
                Sorry, this StoryScale Tour is disabled by its owner.
                    <br>
                Please check back soon. 
            </p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "NotFound",
        data() {
            return {
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight,
            }
        },
        mounted() {
            this.startAnimation();
        },
        methods: {
            startAnimation() {
                let canvas = this.$refs.animationCanvas;
                let ctx = canvas.getContext('2d');
            
                canvas.width = this.screenWidth;
                canvas.height = this.screenHeight;

         
                let letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ';
                letters = letters.split('');
                
                let fontSize = 10;
                let columns = canvas.width / fontSize;
                

                let drops = [];

                for (let i = 0; i < columns; i++) {
                    drops[i] = 1;
                }

                function draw() {
                    ctx.fillStyle = 'rgba(0, 0, 0, .1)';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    for (let i = 0; i < drops.length; i++) {
                        let text = letters[Math.floor(Math.random() * letters.length)];
                        ctx.fillStyle = '#4B60EB';
                        
                        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

                        drops[i]++;

                        if (drops[i] * fontSize > canvas.height && Math.random() > .95) {
                            drops[i] = 0;
                        }
                    }
                }

                setInterval(draw, 30);
            }
        }
    }
</script>
<style scoped>
</style>