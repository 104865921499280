import $cookie from 'vue-cookies';
import axios from 'axios';

export default class Analytics {
    constructor(options) {
        this.options = options;
        this.isDev = options.dev ? true : false;
        this.assetChangeTimeoutHandler = null;
        this.assetChangeTimeout = 5000;
        this.assetBinge = 0;
        this.assetBingeNumber = 2;
        this.platform_customer_id = options.platform_customer_id;

        if (!options.manageTimer) {
            this.startTimer();
        }

        this.isConversionCreated = false;
    }

    initialEvents(analyticsData) {
        if (localStorage.getItem("sc_exp_time")) {
            const seconds = localStorage.getItem("sc_exp_time");
            const id = localStorage.getItem("sc_exp_id");

            if (analyticsData.experience.id == id) {
                this.addTotalTimeEvent(analyticsData, seconds, id);
                localStorage.removeItem("sc_exp_time");
                localStorage.removeItem("sc_exp_id");
            }
        }

        this.active = true;
    }

    onClose(analyticsData) {
        if (!this.active) return;

        const seconds = this.getEndTimeSeconds();

        if (seconds) {
            localStorage.setItem("sc_exp_time", seconds);
            localStorage.setItem("sc_exp_id", analyticsData.experience.id);
        }
    }

    startTimer() {
        this.timerStart = new Date();
    }

    resetTimer() {
        this.timerStart = null;
    }

    getEndTimeSeconds() {
        const endTime = new Date();

        if (this.timerStart === null) this.timerStart = endTime;

        const timeDiff = (endTime - this.timerStart) / 1000;
        const seconds = Math.round(timeDiff);
        this.resetTimer()
        return seconds;
    }

    addTotalTimeEvent(analyticsData, seconds = null, id = null) {
        seconds = seconds || this.getEndTimeSeconds();

        if (parseInt(seconds) > 0) {

            const structure = { ...analyticsData };

            if (id) structure.experience.id = parseInt(id);

            this.trackAnalyticsEvent(analyticsData, {
                event_type: "total_time",
                time_spent: parseInt(seconds),
            });

            this.active = false;
        }
    }

    addVistorEvent(analyticsData) {
        const deliveryFilter = this.options.throttleVisitor ?? ["web-widget", "launcher"].includes(analyticsData.experience.delivery_type);

        // Throttle the visitor count
        const experienceVisitedCookie = `sc_exp_visited_${analyticsData.experience.id}`;
        const expire = "30m";

        if (deliveryFilter) {
            if ($cookie.get(experienceVisitedCookie)) return;
        }

        this.trackAnalyticsEvent(analyticsData, {
            event_type: "visitor",
        });

        if (deliveryFilter) {
            $cookie.set(experienceVisitedCookie, "true", expire);
        }
    }

    addAssetViewEvent(analyticsData) {
        const self = this;

        if (this.assetChangeTimeoutHandler) clearTimeout(this.assetChangeTimeoutHandler)

        this.assetChangeTimeoutHandler = setTimeout(() => {

            self.trackAnalyticsEvent(analyticsData, {
                event_type: "asset_view",
            });

            // Analytics - Binge Session
            if (self.assetBinge < self.assetBingeNumber) self.assetBinge += 1;

            if (self.assetBinge === self.assetBingeNumber) {
                self.assetBinge += 1;
                self.addBingeEvent(analyticsData);
            }

        }, this.assetChangeTimeout);
    }

    addBingeEvent(analyticsData) {
        this.trackAnalyticsEvent(analyticsData, {
            event_type: "binge_session",
        });
    }

    cancelAssetViewEvent() {
        clearTimeout(this.assetChangeTimeoutHandler)
    }

    addConversionEvent(analyticsData) {
        if (this.isConversionCreated) return;

        this.isConversionCreated = true;

        this.trackAnalyticsEvent(analyticsData, {
            event_type: "conversion",
        });
    }

    /**
     * Create analytics event into DB
     * @param {Object} payload 
     * @param {number} payload.platform_customer_id
     * @param {string} payload.event_type
     * @param {object} payload.experience
     * @param {object} payload.sequence
     * @param {object} payload.asset
     * @param {number} [payload.time_spent]
     * @param {number} [payload.page]
     * @param {object} [payload.device]
     * @param {object} [payload.geo]
     * @param {object} [payload.extra]
     * @param {object} [payload.created_at]
     */
    createAnalyticsEvent(payload) {
        const apiUrl = process.env.VUE_APP_API_URL + "analytics/events/create";

        return new Promise((resolve, reject) => {
            if (!this.isDev) {
                axios.post(apiUrl, payload).then((data) => {
                    resolve(data)
                }).catch((err) => reject(err));
            } else {
                console.log(`Analytics Event: [${payload.event_type}]`, payload)
                resolve();
            }
        })
    }

    trackAnalyticsEvent(analyticsData, optional = {}) {
        this.createAnalyticsEvent({
            platform_customer_id: this.platform_customer_id,
            event_type: "",
            delivery_type: analyticsData?.experience?.delivery_type,
            experience_id: analyticsData?.experience?.id,
            experience: analyticsData?.experience,
            asset_id: analyticsData?.asset?.id,
            asset: analyticsData?.asset,
            sequence_id: analyticsData?.sequence?.id,
            sequence: analyticsData?.sequence,
            page: window.location.href,
            geo: {
                continent: analyticsData?.customer?.geo?.continent || "",
                country: analyticsData?.customer?.geo?.country || "",
                country_code: analyticsData?.customer?.geo?.countryCode || "",
                city: analyticsData?.customer?.geo?.city || "",
                state: analyticsData?.customer?.geo?.state || "",
                state_code: analyticsData?.customer?.geo?.stateCode || "",
                postal: analyticsData?.customer?.geo?.postal || "",
                latitude: analyticsData?.customer?.geo?.lat || "",
                longitude: analyticsData?.customer?.geo?.lng || "",
                time_zone: analyticsData?.customer?.geo?.timeZone || "",
            },
            device: {
                width: screen.width,
                height: screen.height,
            },
            extra: {
                customer: analyticsData?.customer
            },
            created_at: (new Date()).toISOString(),
            ...optional
        }).catch(err => console.error(err));
    }
}
