<template>
  <div class="video-player">
    <div class="initial-wrapper" v-if="!isStarted" @click="play">
      <div class="initial-play">
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#ffffff" d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 0 0 0-1.69L9.54 5.98A.998.998 0 0 0 8 6.82"/></svg>
      </div>
    </div>

    <video ref="videoPlayer" @click="togglePlay" @timeupdate="timeUpdated" @loadeddata="initializeVideo" @ended="handleEnd" :poster="poster">
      <source :src="src" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <template v-if="isStarted">
      <div class="controls">
        <button @click="togglePlay" class="play-pause">
          <svg v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 0 0 0-1.69L9.54 5.98A.998.998 0 0 0 8 6.82"/></svg>
          
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M8 19c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2m6-12v10c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2"/></svg>
        </button>
  
        <div class="d-flex align-items-center" style="width: 70%">
          <range-slider class="slider px-0" style="width: 100%;" min="0" max="100" :value="progress" @input="(value) => seek(value)"/> 
        </div>
        
        <div class="time text-center" style="flex: 1;">{{ currentTime }} / {{ duration }}</div>
  
        <button @click="toggleMute">
          <svg v-if="!isMuted" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M3 10v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71V6.41c0-.89-1.08-1.34-1.71-.71L7 9H4c-.55 0-1 .45-1 1m13.5 2A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02M14 4.45v.2c0 .38.25.71.6.85C17.18 6.53 19 9.06 19 12s-1.82 5.47-4.4 6.5c-.36.14-.6.47-.6.85v.2c0 .63.63 1.07 1.21.85C18.6 19.11 21 15.84 21 12s-2.4-7.11-5.79-8.4c-.58-.23-1.21.22-1.21.85"/></svg>

          <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M3.63 3.63a.996.996 0 0 0 0 1.41L7.29 8.7L7 9H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71v-4.17l4.18 4.18c-.49.37-1.02.68-1.6.91c-.36.15-.58.53-.58.92c0 .72.73 1.18 1.39.91c.8-.33 1.55-.77 2.22-1.31l1.34 1.34a.996.996 0 1 0 1.41-1.41L5.05 3.63c-.39-.39-1.02-.39-1.42 0M19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87c0-3.83-2.4-7.11-5.78-8.4c-.59-.23-1.22.23-1.22.86v.19c0 .38.25.71.61.85C17.18 6.54 19 9.06 19 12m-8.71-6.29l-.17.17L12 7.76V6.41c0-.89-1.08-1.33-1.71-.7M16.5 12A4.5 4.5 0 0 0 14 7.97v1.79l2.48 2.48c.01-.08.02-.16.02-.24"/></svg>
        </button>

        <button @click="toggleFullScreen">
          <svg v-if="!isFullScreen" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M6 14c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1H7v-2c0-.55-.45-1-1-1m0-4c.55 0 1-.45 1-1V7h2c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1m11 7h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1zM14 6c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V6c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1"/></svg>

          <svg v-else xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M6 16h2v2c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1m2-8H6c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1s-1 .45-1 1zm7 11c.55 0 1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-3c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1m1-11V6c0-.55-.45-1-1-1s-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1z"/></svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import RangeSlider from 'vue-range-slider';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    onProgress: {
      type: Function,
    }
  },
  components: {
    RangeSlider,
  },
  data() {
    return {
      isStarted: false,
      isPlaying: false,
      isMuted: false,
      isFullScreen: false,
      progress: 0,
      currentTime: "0:00",
      duration: "0:00",
    };
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.fullscreenChanged);
  },
  destroyed() {
    document.removeEventListener('fullscreenchange', this.fullscreenChanged);
  },
  methods: {
    play(){
        this.$refs.videoPlayer.play();

        this.isPlaying = true;

        if(!this.isStarted) this.isStarted = true;
    },
    pause(){
        this.$refs.videoPlayer.pause();

        this.isPlaying = false;
    },
    togglePlay() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },
    timeUpdated() {
      this.updateProgress();
    },
    updateProgress() {
      const video = this.$refs.videoPlayer;

      if(video){
        this.progress = (video.currentTime / video.duration) * 100;
        this.currentTime = this.formatTime(video.currentTime);
      }
    },
    initializeVideo() {
      this.duration = this.formatTime(this.$refs.videoPlayer.duration);

      if(this.autoplay){
        this.play();
      }
    },
    seek(value) {
      const video = this.$refs.videoPlayer;
      const seekPosition = (Number(value) / 100) * video.duration;

      this.updateProgress();

      if (video.fastSeek) {
        video.fastSeek(seekPosition);
      } else {
        video.currentTime = seekPosition;
      }
    },
    handleEnd() {
      this.pause();
      this.progress = 100;
      this.currentTime = this.duration;
    },
    toggleMute() {
      this.$refs.videoPlayer.muted = !this.$refs.videoPlayer.muted;

      this.isMuted = this.$refs.videoPlayer.muted;
    },
    formatTime(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.$el.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable fullscreen: ${err.message}`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    fullscreenChanged() {
      this.isFullscreen = !!document.fullscreenElement;
    },
  },
  watch: {
    progress() {
      this.$emit("onProgress", {
        progress: parseInt(this.progress),
      })
    }
  }
};
</script>

<style>
.video-player {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.video-player .initial-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0f0f0f8a;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.video-player .initial-wrapper .initial-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  width: 8rem;
  height: 5rem;
  background-color: #47556999;
  border: 2px solid #ffffffaf;

  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;
}
.video-player .initial-wrapper .initial-play:hover {
  border-color: #fff;
}
.video-player .initial-wrapper .initial-play svg{
  transition: .2s;
}
.video-player .initial-wrapper .initial-play:hover svg {
  transform: scale(1.1) translateZ(0);
}

.video-player video {
  width: 100%;
  height: 100%;
  display: block;
}

.video-player .controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  padding: 10px;
  transition: opacity 0.3s;
  transition-delay: 200ms;
  gap: 10px;
  opacity: 0;
}

.video-player:hover .controls {
  opacity: 1;
}

.video-player button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.video-player .range-slider {
  cursor: pointer;
}

.video-player .range-slider-knob {
    display: none !important;
}

.video-player .range-slider-fill {
  transition: width 0.1s;
  background-color: #4B60EB !important;
}

.video-player .time {
  color: #fff;
  font-size: 14px;
}
</style>
