const systemVariables = [
  /**
   * Basic system variables
   */
  {
    is_system_variable: true,
    type: 'text',
    name: 'first_name',
    label: 'First Name',
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'text',
    name: 'last_name',
    label: 'Last Name',
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'text',
    name: 'email',
    label: 'email',
    default_value: ''
  },
  /**
   * Date Time system variables
   */
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_day',
    label: 'Now Day',
    rule: () => { return new Date().toLocaleString('default', { day: 'numeric' }) },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_month',
    label: 'Now Month',
    rule: () => { return new Date().toLocaleString('default', { month: 'long' }) },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_time_of_day',
    label: 'Now Time Of Day',
    rule: () => { 
      const currentTime = new Date().getHours()
      let message = ""

      if (currentTime < 12) {
        message = "morning"
      } else if (currentTime < 18) {
        message = "afternoon"
      } else {
        message = "evening"
      }

      return message
    },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_weekday',
    label: 'Now Weekday',
    rule: () => { return new Date().toLocaleString('default', { weekday: 'long' }) },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_year',
    label: 'Now Year',
    rule: () => { return new Date().toLocaleString('default', { year: 'numeric' }) },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'now_clock',
    label: 'Now Clock',
    rule: () => { return new Date().toLocaleString('default', { hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase() },
    default_value: ''
  },
  {
    is_system_variable: true,
    type: 'datetime',
    name: 'tomorrow',
    label: 'Tomorrow',
    rule: () => { 
      const tomorrow = new Date()
      tomorrow.setDate(new Date().getDate() + 1)
      
      return tomorrow.toLocaleString('default', { weekday: 'long' })
    },
    default_value: ''
  }
];

export default systemVariables;