import { filterObject, getCookies, queryParams } from "../../utils";
import { generateStyleString } from "../../utils/parser/html/dom/utils";
import base64 from "base-64";

function getIframeStyles(styles, defaultStyles = false){
    if(defaultStyles) styles = {};

    const { width, height, rounded = false, drop_shadow = false } = styles;

    //  "min-height": "700px" TODO
    const heightStyle = height ? {
        height,
    } : {
        height: "100%",
    };

    const borderRadius = rounded ? {
        "border-radius": "15px"
    } : {
        border: 0
    };

    const shadow = drop_shadow ? {
        "box-shadow": "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px"
    } : {};

    let iframeStyles = {
        width: width || "100%",
        ...heightStyle,
        ...borderRadius,
        ...shadow
    };

    return generateStyleString(iframeStyles);
}

function createIframe({ src = "", styles = {}, defaultStyles = false}) {
    const iframe = document.createElement("iframe");

    iframe.setAttribute("class", "storyscale-tour");
    iframe.setAttribute("src", src);

    iframe.style.cssText = getIframeStyles(styles, defaultStyles) 

    return iframe;
}

function getTourShareUrl(token, query = {}, baseUrl = process.env.VUE_APP_SHARE_URL) {
    const params = new URLSearchParams({
        ...query,
        clfn: "tour",
        rptn: token,
    });

    const queryString = params.toString();

    return `${baseUrl}?${queryString}`;
}

function getTour(tourPublicId) {
    return window.axios.get(`/frontend/tour/view/${tourPublicId}`, {
        params: {
            with_style_guide: true,
            without_screens: true,
            with_embed_settings: true,
        }
    }).then((res) => {
        const { data } = res.data;

        return data.tour
    }).catch((err) => {
        console.error(err);
    });
}

function createWidget({ token, element, defaultStyles = false, launcher = false, delivery, props = {} }) {
    if(token.length > 45){
        token = JSON.parse(window.atob(token))?.id || ""
    }

    return getTour(token).then(tour => {
        const pageQueryParams = queryParams();

        let cookies = null;

        try {
            const cookieObject = filterObject(getCookies(), (value) => Boolean(value));

            cookies = base64.encode(JSON.stringify(cookieObject));
        } catch (error) {
            console.log(error)
        }

        if(cookies) pageQueryParams.__stappck__;

        const src = getTourShareUrl(token, pageQueryParams);

        let embedStyles = tour.embed_settings ? tour.embed_settings : {};

        embedStyles = {
            ...embedStyles,
            ...(props ? props : {})
        }

        const iframe = createIframe({ src, styles: embedStyles, defaultStyles });

        if (element) {
            element.replaceWith(iframe);
        }

        if(launcher){
            return {
                styles: embedStyles,
                iframe,
            }
        }
        
        return iframe;
    });
}

export default {
    createWidget,
    getTourShareUrl
}