<template>
  <section class="mt-2 widget_panel" title="" v-if="token && !loading">

    <lightViewer
    v-if="experienceType === 'viewer' && dataObj && show"
    :obj="dataObj"
    :showAssetId="currentAssetId"
    :showSequenceId="currentSequenceId"
    @onClose="closeModal"/>

    <spotlightLight
    v-else-if="experienceType === 'spotlight' && dataObj && show"
    :obj="dataObj"
    :showAssetId="currentAssetId"
    :showSequenceId="currentSequenceId"
    @onClose="closeModal"/>

      <!-- DESIGN FROM HERE -->
        <div class="storyscale_featured_holder" style="padding: 10px 0; align-items: flex-start;">
            <div class="storyscale_featured_first_holder">
                <h1 class="main-heading" style="font-weight: 600; font-size: 40px; margin-bottom: 30px; color: #1a245f!important;" :style="{ fontFamily: dataObj.style_guide_id.font }">{{webWidget.title}}</h1>
                <p style="font-size: 18px; font-weight: 400; margin-bottom: 0;" :style="{ fontFamily: dataObj.style_guide_id.font }">{{webWidget.description}}</p>
            </div>
            <div class="storyscale_featured_second_holder">
                <label for="channel-input" style="font-size: 16px; margin-bottom: 7px;" :style="{ fontFamily: dataObj.style_guide_id.font }">Choose a channel</label>
                <select style="min-width: 300px; min-height: 52px; font-size: 16px; color: #1a245f; border: 2px solid #e4e6f0;" class="form-control" id="channel-input" v-model="currentSequenceId" :style="{ fontFamily: dataObj.style_guide_id.font }">
                    <option :value="sequence.id" v-for="sequence in sequences" :key="sequence.id">
                        {{sequence.sequence_name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="storyscale_featured_assets" style="padding: 2% 0;">
            <div ref="storyscale_webwidget_container" class="scoll-pane">
                <ul class="photos">
                    <li v-for="asset in sequenceAssets" :key="asset.id" style="cursor: pointer;">
                        <div @click.prevent="showAsset(asset.id)">
                            <img style="width: 100%; height: 100%; object-fit: fill;" :src="asset.cover_image_url" :alt="asset.asset_friendly_name" alt="">

                            <!-- <ul class="list-unstyled">
                                <li class="nav-item">
                                    <a href="#" class="nav-link show-link" style="display: flex; padding: 0 !important;">
                                        <div class="storyscale_icon-w-content">
                                            <img src="https://asset.storyscale.com/Video.svg" alt="" v-if="asset.asset_type_id === 6 || asset.asset_type_id === 7">
                                            <img src="https://asset.storyscale.com/Website.svg" alt="" v-if="asset.asset_type_id === 2 || asset.asset_type_id === 3 || asset.asset_type_id === 4 || asset.asset_type_id === 5">
                                            <img src="https://asset.storyscale.com/PDF1.svg" alt="" v-if="asset.asset_type_id === 1">
                                        </div>
                                        <span class="assets_name" :style="{ fontFamily: dataObj.style_guide_id.font }">
                                            {{asset.asset_friendly_name}}
                                        </span>
                                    </a>
                                </li>
                            </ul> -->
                        </div>
                    </li>
                </ul>
            </div>
            <button class="storyscale_prev-btn" style="outline: none;" @click="prev" type="button" >
                <span>&#60;</span>
            </button>
            <button class="storyscale_next-btn" style="outline: none;" @click="next" type="button">
                <span>&#62;</span>
            </button>
        </div>
  </section>
<!--    :disabled="scrollLeft == 0"-->
</template>
<script>
  import lightViewer from "../components/viewer/light";
  import spotlightLight from "../components/spotlight/spotlightLight";
  import WebFont from "webfontloader";

  export default {
    props: ["title", "description", 'type', 'token'],
    components: {
        lightViewer,
        spotlightLight
    },
    data () {
      return {
        dataObj: {},
        loading: false,
        loaded: false,
        tokenData: this.token,
        sequences: [],
        currentAssetId: 0,
        currentSequenceId: 0,
        currentSequence: {},
        experience: {},
        experienceType: null,
        show: false,
        webWidget: {},
        scrollLeft: 0
      };
    },
    created () {
        if(this.token) this.getExperienceDetails(this.token)

    },
    methods: {
      getExperienceDetails(token) {
        this.loading = true;
        this.axios.get( `frontend/view-experience?encoded_input=${token}` )
        .then( response => {
            const { data } = response.data;

            const exp = data.experience;

            this.experience = exp;
            this.experienceType = exp.experience_type;
            this.webWidget = exp.web_widget ?? {},
            this.dataObj = {
                ...exp,
                display_pages: [],
                paywall_cta_id: data.paywall_cta,
                primary_cta_id: data.primary_cta,
                nudge_cta_id: data.nudge_details,
                style_guide_id: data.style_guide,
                primary_sequence: null,
                sequences: [],
                keen_data: data.user.keen_data,
            };

            this.dataObj.delivery_type = "web-widget";

            data.sequences.map( ( sq ) => {
                if ( sq.pivot.is_primary === 1 ) {
                    this.dataObj.primary_sequence = { ...sq };
                } else {
                    this.dataObj.sequences.push( { ...sq } );
                }
                return sq;
            });

            switch(this.experienceType){
                case 'viewer':
                    break;
                case 'spotlight':
                    if ( data.experience.launch_with_splash ) {
                        this.dataObj.splash_asset_id = data.splash_image;
                    } else {
                        this.dataObj.splash_asset_id = null;
                    }
                    break;
            }

            this.sequences = [this.dataObj.primary_sequence, ...this.dataObj.sequences];

            this.currentSequence = this.sequences[0];

            this.currentSequenceId = this.currentSequence.id;

            WebFont.load({
                google: {
                    families: [ this.dataObj.style_guide_id.font ]
                }
            });

            this.loaded = true;

            this.loading = false;
        })
        .catch( ( error ) => {
            console.log(error)
            const { message } = error.response.data;
            console.log(message);
        })
      },
      showAsset(assetId){
          this.setAsset(assetId);
          this.show = true;
      },
      setAsset(assetId){
         this.currentAssetId = assetId;
      },
      closeModal(){
          setTimeout(() => {
              this.show = false;
          },100)
      },
      next () {
        this.sideScroll(this.$refs.storyscale_webwidget_container,'right',15,200,10);
      },
      prev () {
        this.sideScroll(this.$refs.storyscale_webwidget_container,'left',15,200,10);
      },
      sideScroll (element,direction,speed,distance,step){
        let scrollAmount = 0;
        const self = this;
            let slideTimer = setInterval(function(){
                if(direction == 'left'){
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if(scrollAmount >= distance){
                    window.clearInterval(slideTimer);
                }
                self.scrollLeft = element.scrollLeft
            }, speed);
        }
    //   getLink(asset){
    //       const host = process.env.VUE_APP_EXPERIENCE_URL
    //       const experienceType = this.experience.experience_type;
    //       const token = this.token;
    //       const sequenceName = this.slugify(this.currentSequence.sequence_name)
    //       const assetName = this.slugify(asset.asset_friendly_name)

    //       let link = `${host}/${experienceType}/${token}/${sequenceName}/${assetName}`;

    //       return link;
    //   }
    },
    computed: {
        sequenceAssets(){
            const sequence = this.currentSequenceId
            ? this.sequences.find(({id}) => this.currentSequenceId == id)
            : {};

            this.currentSequence =  sequence;

            return sequence.assets ? sequence.assets : {};
        }
    }
  }
</script>

<style>
    /* @import "../assets/css/style.css";
    @import "../assets/css/style_viewer.css";
    @import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"; */
    /* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"; */

    .storyscale_asset-holder{
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
    }
    .storyscale_card a{
        text-decoration: none;
        color: inherit;
    }
    .storyscale_card-text{
         overflow-x: hidden;
    }
    .storyscale_featured_first_holder {
        width: 60%;
    }
    .storyscale_featured_second_holder {
        width: 40%;
    }

    @media (max-width: 767px) {
        .storyscale_featured_first_holder,
        .storyscale_featured_second_holder {
            width: 100%;
        }
        .storyscale_featured_first_holder h1 {
            font-size: 24px !important;
            text-align: center;
            margin-bottom: 10px !important;
        }
        .storyscale_featured_first_holder p {
            font-size: 14px !important;
            text-align: center;
        }
    }
</style>
