<template>
    <div class="container">
        <div class="row">

            <section
                class="animated faster"
                :class="[ $props.depTyp === 'embed' ? animationClass : `${ animationClass } storyscale_ss_modal_section storyscale_ss_modal_active` ]"
                style="overflow: hidden; align-items: center; justify-content: center; display: flex; width: 100%;"
                v-if="showModal"
                :style="[
                    max ? { width: `${ contentWidth }px`, height: `${ contentHeight }px`, maxWidth: `${ contentWidth-15 }px`, minHeight: `${ contentHeight }px` } : {}
                ]"
            >
                <div
                    class="storyscale_ss_modal_dialog"
                    style="margin: 0 !important; left: 0; width: 100%;"
                    :style="[
                        max ? { width: `${ contentWidth-15 }px`, height: `${ contentHeight }px`, maxWidth: `${ contentWidth-15 }px`, minHeight: `${ contentHeight }px` } : { width: `100%` },
                        $props.depTyp === 'embed' ? { maxWidth: `100%`,  } : {},
                    ]"
                >
                    <div
                        class="storyscale_ss_modal_content" :class="{overlay: bgoverlay}"
                        :style="[
                            dataObj.style_guide_id ? { background: dataObj.style_guide_id.background_color,  } : {},
                            max ? { width: `${ contentWidth }px`, height: `${ contentHeight }px`, maxWidth: `${ contentWidth }px`, minHeight: `${ contentHeight }px` } : {},
                        ]"
                    >
                        <div class="storyscale_ss_modal_close" @click.prevent="closeModal" v-if="!max && $props.depTyp !== 'embed'">&times;</div>
                        <div
                            class="storyscale_left-side-holder" :class="{show: showLeftPanel}"
                            :style="[ max ? { width: `408px !important`, height: `${ contentHeight - 35 }px`,  } : { width: `308px !important` } ]"
                        >
                            <button class="storyscale_hamburg-btn" @click="menuAction">
<!--                                <i class="fas fa-angle-right" />-->
                                <img src="https://asset.storyscale.com/arrow-right.svg" style="width: 18px;" />
                            </button>
                            <div class="storyscale_o-logo">
                                <img
                                    :src="dataObj.style_guide_id.logo"
                                    style="width: 140px;"
                                    :alt="dataObj.style_guide_id.logo_alternative_text ? dataObj.style_guide_id.logo_alternative_text : 'Logo'"
                                >
                            </div>
                            <a
                                class="storyscale_unique-btn"
                                target="_blank"
                                :href="dataObj.primary_cta_id.cta_links_to"
                                v-if="dataObj.primary_cta_id"
                                :style="[
                                    dataObj.style_guide_id ?
                                    {
                                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                        color: dataObj.style_guide_id.secondary_font_color,
                                        background: dataObj.style_guide_id.primary_accent_color,
                                    } : {}
                                ]"
                            >
                                {{ dataObj.primary_cta_id.text }}
                            </a>
                            <div class="storyscale_now-holder tab-feature" v-if="dataObj.is_custom_message">
                                <p
                                    v-html="dataObj.custom_message"
                                    style="font-size: 21px;
                                    margin-bottom: 4%; color: #000; font-weight: 600;"
                                    :style="[
                                        dataObj.style_guide_id ?
                                        {
                                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                        } : {}
                                    ]"
                                />
                            </div>
                            <div class="storyscale_now-holder tab-feature">
                                <h3 class="storyscale_label-heading" :style="[
                                    dataObj.style_guide_id ?
                                    {
                                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                    } : {}
                                ]">Now Viewing</h3>

                                <div style="display: flex;" >
                                    <div style="width: auto !important;" class="storyscale_icon-w-content" v-if="dataObj.show_icon">
                                        <img src="https://asset.storyscale.com/Video.svg" alt="" v-if="nowViewing.asset_type_id === 6 || nowViewing.asset_type_id === 7">
                                        <img src="https://asset.storyscale.com/Website.svg" alt="" v-if="nowViewing.asset_type_id === 2 || nowViewing.asset_type_id === 3 || nowViewing.asset_type_id === 4 || nowViewing.asset_type_id === 5">
                                        <img src="https://asset.storyscale.com/PDF1.svg" alt="" v-if="nowViewing.asset_type_id === 1">
                                    </div>

                                    <img
                                        :src="nowViewing.cover_image_url"
                                        :alt="nowViewing.asset_friendly_name"
                                        style="width: 60px; min-width: 60px; border-radius: 5px; max-height: 45px; height: 45px; border: 2px solid #e4e6f1;"
                                        v-if="!dataObj.show_icon"
                                    >
                                    <p
                                        class="storyscale_span-content"
                                        style="margin: 0 0 0 8px; word-break: break-word;"
                                        data-tab="now"
                                        :style="[
                                            dataObj.style_guide_id ?
                                            {
                                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                            } : {}
                                        ]"
                                    >
                                        {{ nowViewing.asset_friendly_name }}<br />
                                        <span style="color: #5a5e88; font-weight: 700; font-size: 10.5px" v-if="nowViewing.asset_type_id === 1">PDF</span>
                                        <span style="color: #5a5e88; font-weight: 700; font-size: 10.5px" v-if="nowViewing.asset_type_id === 2 || nowViewing.asset_type_id === 3 || nowViewing.asset_type_id === 4 || nowViewing.asset_type_id === 5">WEB</span>
                                        <span style="color: #5a5e88; font-weight: 700; font-size: 10.5px" v-if="nowViewing.asset_type_id === 6 || nowViewing.asset_type_id === 7">VIDEO</span>
                                    </p>
                                </div>
                            </div>
                            <div class="storyscale_recomend-holder tab-feature">
                                <h3 v-if="primarySeq && primarySeq.assets.length > 1" class="storyscale_label-heading" :style="[
                                    dataObj.style_guide_id ?
                                    {
                                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                    } : {}
                                ]">Recommended for you</h3>
                                <div >
                                    <div
                                        style="display: flex; margin-bottom: 15px;"
                                        v-for="( lib, i ) in primarySeq.assets"
                                        :key="i"
                                        v-if="primarySeq && primarySeq.assets.length > 0 && !lib.now"
                                        @click.prevent="showContent( lib, i, true )"
                                    >
<!--                                        class="storyscale_icon-w-content"-->
                                        <div style="width: auto !important;" class="storyscale_icon-w-content" v-if="dataObj.show_icon">
                                            <img src="https://asset.storyscale.com/Video.svg" alt="" v-if="lib.asset_type_id === 6 || lib.asset_type_id === 7">
                                            <img src="https://asset.storyscale.com/Website.svg" alt="" v-if="lib.asset_type_id === 2 || lib.asset_type_id === 3 || lib.asset_type_id === 4 || lib.asset_type_id === 5">
                                            <img src="https://asset.storyscale.com/PDF1.svg" alt="" v-if="lib.asset_type_id === 1">
                                        </div>
                                        <img
                                            :src="lib.cover_image_url"
                                            :alt="lib.asset_friendly_name"
                                            style="width: 60px; min-width: 60px; border-radius: 5px; max-height: 45px; height: 45px; border: 2px solid #e4e6f1;"
                                            v-if="!dataObj.show_icon"
                                        >
                                        <p class="storyscale_span-content" style="margin: 0 0 0 8px; word-break: break-word; font-weight: 500;" data-tab="tab-1" :style="[
                                            dataObj.style_guide_id ?
                                            {
                                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                            } : {}
                                        ]">
                                            {{ lib.asset_friendly_name }}<br/>
                                            <span style="color: #5a5e88; font-weight: 500; font-size: 10.5px;" v-if="lib.asset_type_id === 1">PDF</span>
                                            <span style="color: #5a5e88; font-weight: 500; font-size: 10.5px;" v-if="lib.asset_type_id === 2 || lib.asset_type_id === 3 || lib.asset_type_id === 4 || lib.asset_type_id === 5">WEB</span>
                                            <span style="color: #5a5e88; font-weight: 500; font-size: 10.5px;" v-if="lib.asset_type_id === 6 || lib.asset_type_id === 7">VIDEO</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="storyscale_channel-holder">
                                <h3 class="storyscale_label-heading" v-if="dataObj.sequences.length>0" :style="[
                                    dataObj.style_guide_id ?
                                    {
                                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                    } : {}
                                ]">
                                    View our channels
                                </h3>
                                <div style="width: 100% !important;" class="storyscale_icon-w-content" v-for="( sq, sq_i ) in dataObj.sequences" :key="sq_i">
                                    <a
                                        href="#"
                                        class="storyscale_span-content"
                                        @click.prevent="moveToPrimary( { ...sq } )"
                                        :style="[
                                            dataObj.style_guide_id ?
                                            {
                                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                            } : {}
                                        ]"
                                        style="word-break: break-word;"
                                    >
                                        {{ sq.sequence_name }}
                                    </a>
                                </div>
                            </div>
                            <a href="https://storyscale.com/" target="_blank">
                                <div class="storyscale_icon-w-content" style="margin-top: 30px; align-items: flex-end; width: 100% !important;">
                                    <h3 class="storyscale_label-heading storyscale_ss_pb_0" :style="[
                                        dataObj.style_guide_id ?
                                        {
                                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                        } : {}
                                    ]"
                                    style="padding: 0px;
                                           margin-bottom: 1px;">
                                        Powered by:
                                    </h3>
    <!--                                <img class="storyscale_story-img" src="https://asset.storyscale.com/asset-files/cover-images/1601762955Whitelogo.png" alt="icon">-->
                                    <img
                                        class="storyscale_logo-img"
                                        src="https://asset.storyscale.com/asset-files/cover-images/1601762955Whitelogo.png"
                                        alt="logo"
                                        style="width: 78px !important; margin-left: 7px !important;vertical-align: middle;"
                                    >
                                </div>
                            </a>

                        </div>
                        <div
                            class="storyscale_right-side-holder"
                            :style="[ max ? { marginLeft: `418px`, height: '100%' } : { marginLeft: `318px` } ]"
                        >
<!--                            <div class="storyscale_progress-bar"></div>-->
                            <div id="now" class="storyscale_tab-content current">
                                <div class="storyscale_heading-part" >

                                    <h2 :style="[
                                        dataObj.style_guide_id ?
                                        {
                                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                        } : {}
                                    ]">
                                        <span v-if="comingUp">{{ comingUp }}</span>
                                        <span v-else>{{ nowViewing.asset_friendly_name }}</span>
                                    </h2>
                                    <div class="storyscale_icon-holder">
                                        <span
                                            v-if="$props.obj.show_sharing_icons && dataObj.style_guide_id.social_media && dataObj.style_guide_id.social_media.length > 0"
                                            style="display: inline-flex;"
                                            v-for="( social, s_i ) in dataObj.style_guide_id.social_media"
                                            :key="s_i"
                                        >
                                            <a href="#" class="storyscale_fb-anchor" v-if="social.social_media_type.id === 1">
                                                <img src="https://asset.storyscale.com/facebook.svg" alt="icon">
                                            </a>
                                            <a href="#" class="storyscale_twitter-anchor" v-if="social.social_media_type.id === 2">
                                                <img src="https://asset.storyscale.com/twitter.svg" alt="icon">
                                            </a>
                                            <a href="#" class="storyscale_lnk-anchor" v-if="social.social_media_type.id === 3">
                                                <img src="https://asset.storyscale.com/linkedin.svg" alt="icon">
                                            </a>
                                        </span>

                                        <!-- Zoom -->
                                        <a href="#" class="comp-anchor"
                                         @click.prevent="zoomIn"
                                         v-show="nowViewing.asset_type_id === 1 && !loading">
                                            <img src="https://asset.storyscale.com/zoomIn.svg" alt="icon">
                                        </a>

                                        <a href="#" class="comp-anchor"
                                         @click.prevent="zoomOut"
                                         v-show="nowViewing.asset_type_id === 1 && !loading">
                                             <img src="https://asset.storyscale.com/zoomOut.svg" alt="icon">
                                        </a>
                                        <!-- Zoom -->

                                        <a :href="`mailto:?subject=Storyscale Experience Share Link&body=Experience Link: ${ share_url }`" class="storyscale_mail-anchor">
                                            <img src="https://asset.storyscale.com/mail.svg" alt="icon">
                                        </a>
                                        <a href="#" class="storyscale_share-anchor">
                                            <img src="https://asset.storyscale.com/share.svg">
                                        </a>
<!--                                        <a href="#" class="storyscale_mail-anchor" @click="zoomIn">-->
<!--&lt;!&ndash;                                            <img src="https://asset.storyscale.com/mail.svg" alt="icon">&ndash;&gt;-->
<!--                                            zoom in-->
<!--                                        </a>-->
<!--                                        <a href="#" class="storyscale_mail-anchor">-->
<!--&lt;!&ndash;                                            <img src="https://asset.storyscale.com/mail.svg" alt="icon">&ndash;&gt;-->
<!--                                            zoom out-->
<!--                                        </a>-->
                                        <a href="#" class="comp-anchor" @click.prevent="maxMinWindow" v-if="$props.depTyp !== 'embed'">
                                            <img src="https://asset.storyscale.com/normalScreen.svg" alt="icon">
                                        </a>
                                        <a href="#" class="down-anchor" @click.prevent="() => {
                                            if ( dataObj.require_form_to_download && dataObj.conversion_type === 'paywall' ) {
                                                if ( restrict ) {
                                                    toast( `Fill The Form To Download`, 'i' );
                                                } else if ( dataObj.conversion_type === 'paywall' && !is_paywall_submitted ) {
                                                    showPayWallCTA = true;
                                                    restrict = true;
                                                    payWallSubmission();
                                                    toast( `Fill The Form To Download`, 'i' );
                                                } else {
                                                    downloadItem( nowViewing );
                                                }
                                            } else {
                                                downloadItem( nowViewing );
                                            }
                                        }">
                                            <img src="https://asset.storyscale.com/download.svg" alt="icon">
                                        </a>
                                    </div>
                                </div>
                                <div class="storyscale_story-holder" :style="[ max ? { height: `${ contentHeight - 150 }px`, overflow: `hidden` } : primarySeq && primarySeq.assets.length > 1 ?  { height: `calc(90vh - 147px)` } : {} ]">
                                    <div
                                        class="storyscale_inner-story-holder" id="viewerContainer"
                                        style="height: 100%;"
                                    >
                                        <div
                                            style="left: 0;
                                            height: inherit;
                                            background: #efefefb0;
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            border-radius: 7px;"
                                            v-if="restrict"
                                        ></div>
<!--                                        LOADER-->
                                        <div style="width: 100%; height: 100%; background: #ffffff7a; display: flex; align-items: center; justify-content: center;" v-show="loading">
                                            <img
                                                src="https://asset.storyscale.com/Ripple-1.7s-200px.gif"
                                                style="width: 80px; height: 80px;"
                                                alt="Loading..."
                                                class="loading-key"
                                            />
                                        </div>
<!--                                        IMAGE-->
                                        <div
                                            style="width: 100%;
                                            height: 0px !important;
                                            overflow-y: hidden; display: flex;
                                            align-items: center; justify-content: center; background: #cfd3e0;"
                                            v-show="nowViewing.asset_type_id === 4 && !loading"
                                        >
                                            <img
                                                style="width: 100%;"
                                                :src="nowViewing.asset_url"
                                                alt=""
                                            />
                                        </div>
<!--                                        VIDEO-->
                                        <video-player v-show="nowViewing.asset_type_id === 6 && !loading"
                                            style="
                                            max-height: 626px !important; width: 100%;"
                                            ref="videoPlayer"
                                            :options="playerOptions"
                                            :playsinline="true"
                                            customEventName="customstatechangedeventname">
                                        </video-player>
<!--                                        PDF-->
                                        <div
                                            id="pdfContainer"
                                            style="width: 100%; height: 100%; text-align: center"
                                            v-show="nowViewing.asset_type_id === 1 && !loading"
                                        >
                                            <div
                                                ref="progressBar"
                                                style="
                                                height: 6px;
                                                position: absolute;
                                                border-radius: 0 0 5px 5px;
                                                top: 56px;"
                                                :style="[
                                                    dataObj.style_guide_id ?
                                                    {
                                                        background: dataObj.style_guide_id.primary_accent_color,
                                                    } : {
                                                        background: `#4b60eb`,
                                                    }
                                                ]"
                                            ></div>
                                            <div
                                                id="pdfViewer" class="pdfViewer"
                                                style="width: 100%; height: 100%;"
                                                :style="[ restrict ? { overflowX: `hidden`, overflowY: `hidden` } : { overflowX: `auto`, overflowY: `scroll` } ]"
                                                @scroll="handleScroll"
                                                ref="pdfViewer"
                                            >
                                            </div>
                                        </div>
<!--                                        WEBSITE AND YOUTUBE-->
                                        <iframe
                                            width="100%" height="100%"
                                            class="iframe-asset-holder" id="pdfIframe"
                                            oncontextmenu="return false"
                                            style="width: 100%;overflow-y: auto;max-height: 98%;"
                                            :src="nowViewing.asset_url && nowViewing.asset_type_id !== 3 ? `${nowViewing.asset_url}#toolbar=0&navpanes=0&scrollbar=0` : `https://securnyx360.s3.ap-south-1.amazonaws.com/lazy_logo.png`"
                                            v-show="(!nowViewing.readability_enabled && (nowViewing.asset_type_id === 5 || nowViewing.asset_type_id === 7 && !loading))"
                                        ></iframe>

                                        <readable-iframe
                                            width="100%" height="100%"
                                            class="iframe-asset-holder" id="pdfIframe2"
                                            oncontextmenu="return false"
                                            style="width: 100%;overflow-y: auto;max-height: 98%;"
                                            :src="nowViewing.asset_url"
                                            v-show="(nowViewing.asset_type_id === 5 && nowViewing.readability_enabled)  && !loading"
                                        ></readable-iframe>

<!--                                        DOC FILE-->
                                        <iframe
                                            width="100%" height="100%"
                                            class="iframe-asset-holder"
                                            style="width: 100%;
                                            height: 100%;
                                            overflow-y: scroll;"
                                            :src="nowViewing.asset_url ? `https://view.officeapps.live.com/op/embed.aspx?src=${ nowViewing.asset_url }` : `https://securnyx360.s3.ap-south-1.amazonaws.com/lazy_logo.png`"
                                            v-show="nowViewing.asset_type_id === 3 && !loading"
                                        ></iframe>

                                        <div
                                            class="animated bounceIn faster"
                                            style="position: absolute;
                                                background: #fff;
                                                border-radius: 4px;
                                                top: 85px; color: #141945;
                                                right: 30px; font-size: 14px;
                                                padding: 10px 18px;
                                            "
                                            :style="[
                                                dataObj.style_guide_id ?
                                                {
                                                    fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                                } : {}
                                            ]"
                                            v-if="showTimer"
                                        >
                                            <span
                                                style="font-weight: 700; font-size: 23px;"
                                                :style="[
                                                    dataObj.style_guide_id ?
                                                    {
                                                        color: dataObj.style_guide_id.primary_accent_color,
                                                    } : {
                                                        color: `#4b60eb`,
                                                    }
                                                ]"
                                            >
                                                {{ secondsCounter - 1 }}
                                            </span>
                                            <span style="position: relative; top: -4px;">
                                                Loading
                                                {{ Math.round(progress * 100) === 0 || Math.round(progress * 100) <= 30? 'Previous' : '' }}
                                                {{ Math.round(progress * 100) === 100 || Math.round(progress * 100) >= 80 ? 'Next' : '' }}
                                                Asset
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="storyscale_form-box" style="padding: 10px 17px; height: auto; width: auto;" v-if="!ctaDismissed && showPayWallCTA && dataObj.is_conversion">
<!--                                <h3 v-if="dataObj.conversion_type === 'paywall'">{{ dataObj.paywall_cta_id.name }}</h3>-->
<!--                                <h3 v-if="dataObj.conversion_type === 'nudge'">{{ dataObj.nudge_cta_id.name }}</h3>-->
                                <p>
<!--                                    Register for our content channel to download this report and more premium content.-->
<!--                                    It's fast, easy, and we respect your privacy.-->
                                </p>
                                <div
                                    style="width: 100%; height: auto;"
                                    id="payWallForm"
                                    v-if="dataObj.paywall_cta_id && dataObj.conversion_type === 'paywall'"
                                >
                                    <VueScriptComponent :script="dataObj.paywall_cta_id.text" />
                                </div>
                                <div
                                    style="width: 100%; height: auto;"
                                    id="nudgeForm"
                                    v-if="dataObj.nudge_cta_id && dataObj.conversion_type === 'nudge'"
                                >
                                    <VueScriptComponent :script="dataObj.nudge_cta_id.text" />
                                </div>
<!--                                <form class="" style="width: 100%; display: inline-flex;" >-->
<!--                                    <div class="" style="width: 100%;">-->
<!--                                        <label>Name</label>-->
<!--                                        <input type="text" name="" style="padding: 7px; width: 100%;">-->
<!--                                    </div>-->
<!--                                    <div  style="width: 100%;">-->
<!--                                        <label>E-mail</label>-->
<!--                                        <input type="email" name="" style="padding: 7px; width: 100%;">-->
<!--                                    </div>-->
<!--                                    <div style="margin-top: 25px;">-->
<!--                                        <a class="" style="background: #4b60eb; color: #fff; padding: 11px; margin-left: 5px;" href="#">Download</a>-->
<!--                                    </div>-->
<!--                                </form>-->
<!--                                </div>-->
                                <button class="storyscale_close-form" style="margin: -19px -13px;" v-if="dataObj.conversion_type === 'nudge'" @click.prevent="closeNudgeForm">&times;</button>
                            </div>
                            <div class="storyscale_now-holder storyscale_fixed-storyscale_now-holder" style="width: 25%;" v-if="showNext">
                                <h3 class="storyscale_label-heading" style="margin-top: -4px;" :style="[
                                    dataObj.style_guide_id ?
                                    {
                                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                    } : {}
                                ]">Next</h3>
                                <div class="storyscale_icon-w-content" style="margin-top: -10px; width: 100% !important;" @click.prevent="showContent( upNext, currentIndex )">
                                    <img src="https://asset.storyscale.com/Video.svg" alt="" v-if="upNext.asset_type_id === 6 || upNext.asset_type_id === 7">
                                    <img src="https://asset.storyscale.com/Website.svg" alt="" v-if="upNext.asset_type_id === 2 || upNext.asset_type_id === 3 || upNext.asset_type_id === 4 || upNext.asset_type_id === 5">
                                    <img src="https://asset.storyscale.com/PDF1.svg" alt="" v-if="upNext.asset_type_id === 1">
                                    <p class="storyscale_span-content" style="margin-left: 8px; word-break: break-word;" :style="[
                                        dataObj.style_guide_id ?
                                        {
                                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                        } : {}
                                    ]">
                                        {{ upNext.asset_friendly_name }}
                                    </p>
                                </div>
                            </div>

                            <button 
                                v-if="primarySeq && primarySeq.assets.length > 1"
                                style="display: block; width: 100%; cursor: pointer;" class="storyscale_ss_btn storyscale_unique-btn storyscale_nxt-btn storyscale_cstm-margin" @click.prevent="showContent( upNext, currentIndex, true )" :style="[
                                dataObj.style_guide_id ?
                                {
                                    fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                                } : {}
                            ]">View Next</button>
                        </div>
                        <!-- <button style="display: block;" class="storyscale_ss_btn storyscale_unique-btn storyscale_nxt-btn storyscale_cstm-margin" @click.prevent="showContent( upNext, currentIndex )" :style="[
                            dataObj.style_guide_id ?
                            {
                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                            } : {}
                        ]">View Next</button> -->
                    </div>
                </div>
            </section>
        </div>{{ sequenceChange }}{{ adjustRatio }}
    </div>
</template>
<script>
    import { remove } from 'lodash';
    import {videoPlayer} from "vue-video-player";
    import $ from 'jquery';
    import * as pdfjsLib from 'pdfjs-dist/build/pdf';
    import * as pdfjsViewer from 'pdfjs-dist/lib/web/pdf_viewer';
    import WebFont from 'webfontloader';
    import VueScriptComponent from 'vue-script-component'
    import AnalyticsTracking from "@/analytics";
    import BigPicture from "@/big-picture";
    import ReadableIframe from "@/components/readable-content/iframe";

    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

    var _idleSecondsTimer = null;
    var _idleSecondsCounter = 0;
    export default {
        props: [ 'obj' ,'showSequenceId','showAssetId', 'onClose', 'depTyp' ],
        components: {
            videoPlayer,
            VueScriptComponent,
            ReadableIframe,
        },
        created() {
            if ( this.$props.depTyp !== 'embed' ) {
                document.querySelector('html').style.overflow = 'hidden';
            }
            // Deep Link
            this.showSequence(this.showSequenceId);
            // Deep Link

            this.primarySeq = this.dataObj.primary_sequence;
            this.primarySeq.assets = this.primarySeq.assets.map( ( lib ) => {
                lib.now = false;
                return lib;
            });
            this.primarySeq.assets[ 0 ].now = true;

            this.nowViewing = this.primarySeq.assets[ 0 ];
            this.upNext = this.primarySeq.assets[ 1 ];

            WebFont.load({
                google: {
                    families: [ this.dataObj.style_guide_id.font ]
                }
            });

            // Deep link
            if(this.showAssetId && !this.linkAssetShown){
                this.linkAssetShown = true;
                this.showAsset(this.showAssetId);
            }
            // Deep link

            this.videoLoad(this.nowViewing);
            this.getFonts();
            this.animationClass = 'fadeIn';
            this.showModal = true;
            this.showView = true;
            if ( this.nowViewing.asset_type_id === 1 ) {
                this.loadPDF( this.nowViewing.asset_url );
            }
            if ( this.showModal ) {
                if ( this.dataObj.conversion_type === 'paywall' && !localStorage[ `paywall_exp_${ this.dataObj.id }` ] ) {
                    this.is_paywall_submitted = false;
                }
                // window.addEventListener("contextmenu",this.contextMenuEventListener, false);
                // document.addEventListener("keydown", (e) => {
                //     //document.onkeydown = function(e) {
                //     // "I" key
                //     if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                //         this.disabledEvent(e);
                //     }
                //     // "J" key
                //     if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                //         this.disabledEvent(e);
                //     }
                //     // "S" key + macOS
                //     if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                //         this.disabledEvent(e);
                //     }
                //     // "U" key
                //     if (e.ctrlKey && e.keyCode === 85) {
                //         this.disabledEvent(e);
                //     }
                //     // "F12" key
                //     if (e.keyCode === 123) {
                //         this.disabledEvent(e);
                //     }
                // }, false);
                document.addEventListener('pagesinit', () => {
                    pdfjsViewer.currentScaleValue = 'page-width';
                });
            }

            // Analytics
            this.trackData = this.initAnalytics();

            // BigPicture
            if(this.trackData){
                BigPicture.details((customerData) => {
                    this.customerData = customerData;
                    this.trackData?.initialEvents(this.analyticsData())
                    this.trackData?.addVistorEvent(this.analyticsData());
                    this.trackData?.addAssetViewEvent(this.analyticsData());
                    window.onbeforeunload = () => {
                        this.trackData?.onClose(this.analyticsData());
                    }
                });
            }

            this.platform_token = this.generateBase64({
                platform_customer_id: this.dataObj.user.platform_customer_id,
                experience_id: this.dataObj.id,
                experience_type: 'viewer',
            });
            let url = `${ process.env.VUE_APP_WIDGET_URL }?rptn=${ this.platform_token }&clfn=viewer`;
            this.share_url = encodeURIComponent(  url  );


            this.nextInterval = setInterval(() => {
                if ( this.upNext && this.upNext.asset_type_id === 1 && this.nowViewing.asset_type_id === 1 && this.upNext.id !== this.primarySeq.assets[ 0 ].id && Math.round(this.progress * 100) >= 65 ) {
                    this.comingUp = `Upcoming Asset - ${ this.upNext.asset_friendly_name }`;
                    this.showContent( this.upNext, this.currentIndex, false );
                    setTimeout( () => {
                        this.comingUp = null;
                    }, 2000 );
                }
            }, 5000);

        },
        mounted(){
            // Drag Scroll
            // this.$refs.pdfViewer.style.cursor = 'grab';
            // this.$refs.pdfViewer.addEventListener('mousedown', this.mouseDownHandler);
            // this.$refs.pdfViewer.addEventListener('touchstart', this.touchStartHandler);
            // Drag Scroll
        },
        updated() {
            if (this.dataObj.paywall_cta_id && this.dataObj.is_conversion && this.dataObj.gated_display === 'timebased') {
                document.onclick = () => {
                    _idleSecondsCounter = 0;
                };
                document.onscroll = () => {
                    _idleSecondsCounter = 0;
                };

                document.onmousemove = () => {
                    _idleSecondsCounter = 0;
                };

                document.onkeypress = () => {
                    _idleSecondsCounter = 0;
                };
                _idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
            }
        },
        destroyed() {
            window.removeEventListener("contextmenu", this.contextMenuEventListener, false);
            window.onbeforeunload = null;
            this.pdfResponse && this.pdfResponse.destroy()
            clearInterval( this.nextInterval );
        },
        data () {
            return {
                comingUp: null,
                nextInterval: null,
                // Analytics data
                trackData: null,
                customerData: {},
                // Analytics data
                showModal: false,
                animationClass: 'fadeOut',
                loading: false,
                dataObj: this.obj,
                primarySeq: {
                    assets: [],
                },
                platform_token: null,
                share_url: null,
                nowViewing: null,
                showView: false,
                playerOptions: {
                    // videojs options
                    autoplay: true,
                    muted: true,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [],
                    poster: null,
                },
                ctaDismissed: false,
                showPayWallCTA: false,
                upNext: null,
                fontObj: {},
                fontFile: null,
                currentIndex: 1,
                showNext: false,
                goToPrev: false, // Go to previous
                container: null,
                viewer: null,
                pdfViewer: null,
                currentPage: 0,
                pageCount: 0,
                max: false,
                // Zoom %
                zoomScale: 100,
                zoomAmount: 20,
                currentZoomScale: 100,
                zoomInLimit: 200,
                zoomOutLimit: 40,
                pdfScrollGap: 400,
                // Zoom
                contentWidth: window.innerWidth,
                contentHeight: window.innerHeight,
                totalPage: 0,
                progress: 0,
                pdfResponse: null,
                showLeftPanel: false,
                bgoverlay: false,
                restrict: false,
                is_paywall_submitted: true,
                secondsTimer: null,
                secondsCounter:  5,
                showTimer: false,
                // Deep Link
                linkAssetShown: false,
                // Deep Link

                initialLoad: true,

                // Drag Scroll
                // dragPos: { top: 0, left: 0, x: 0, y: 0 },
                // dragScrollPrevElementHeight: 0,
                // dragScrollNextElementHeight: 0,
                // shouldScrollPrev: false,
                // shouldScrollNext: false,
                // dragScrollLimit: 100,
                // Drag Scroll
            };
        },
        methods: {
            CheckIdleTime() {
                _idleSecondsCounter++;
                if ( Number(_idleSecondsCounter) >= Number(this.dataObj.visitor_wait_before_interaction ) ) {
                    if ( this.dataObj.conversion_type === 'paywall' && !localStorage[ `paywall_exp_${ this.dataObj.id }` ] ) {
                        this.showPayWallCTA = true;
                        this.restrict = true;
                        this.payWallSubmission();
                    } else if (this.dataObj.conversion_type === 'nudge' && (!localStorage[ `nudge_exp_${ this.dataObj.id }` ] || sessionStorage.getItem("nudge_exp_dismissed"))) {
                        this.showPayWallCTA = true;
                        this.payWallSubmission( false );
                    }
                    window.clearInterval(_idleSecondsTimer);
                }
            },
            CheckTimer ( next, index ) {
                this.countDownTimer(3)((count) => {
                    this.showTimer = true;
                    this.secondsCounter = count;
                }).then(() => {
                    this.showContent( next, index );
                    this.showTimer = false;
                })
            },
            handleScroll() {
                const progress = this.$refs.pdfViewer.scrollTop / (this.$refs.pdfViewer.scrollHeight - this.$refs.pdfViewer.clientHeight);
                if (progress > 1) {
                    this.progress = 1;
                } else if (progress < 0) {
                    this.progress = 0;
                } else {
                    this.progress = progress;
                }
                this.$refs.progressBar.style.width = `${ Math.round(this.progress * 100) }%`;

                // Go to previous
                // if(Math.round(this.progress * 100) === 0){
                //     if(this.goToPrev){
                //         this.goToPrev = false;
                //         let assetIndex = 0;
                //         this.primarySeq.assets.forEach(({now}, index) => {
                //             if(now) return assetIndex = index - 1;
                //         });
                //         if(assetIndex >= 0){
                //             this.upNext = this.primarySeq.assets[assetIndex + 1];
                //             this.currentIndex -= 2;
                //             this.CheckTimer( this.primarySeq.assets[assetIndex], this.currentIndex );
                //         }
                //     }
                // }

                // if ( Math.round(this.progress * 100) >= 15 ) {
                //     this.goToPrev = true;
                // }
                // Go to previous


                if ( Math.round(this.progress * 100) >= 90 ) {
                    // this.showNext = true;
                    this.comingUp = `Upcoming Asset - ${ this.upNext.asset_friendly_name }`;
                }

                if ( Math.round(this.progress * 100) === 100 ) {
                    // Next Asset
                    if ( this.upNext ) {
                        this.comingUp = null;
                        this.showContent( this.upNext, this.currentIndex, false );
                    }
                }

                if (
                    this.dataObj.is_conversion &&
                    this.dataObj.gated_display === 'navigation'
                ) {
                    if ( Math.round(this.progress * 100) >= Number(this.dataObj.visitor_scroll_before_interaction) ) {
                        if ( this.dataObj.paywall_cta_id && this.dataObj.conversion_type === 'paywall' && !localStorage[ `paywall_exp_${ this.dataObj.id }` ] ) {
                            this.showPayWallCTA = true;
                            this.restrict = true;
                            this.payWallSubmission();
                        } else if (this.dataObj.nudge_cta_id && this.dataObj.conversion_type === 'nudge' && !localStorage[ `nudge_exp_${ this.dataObj.id }` ]) {
                            this.showPayWallCTA = true;
                            this.payWallSubmission( false );
                        }
                    }
                }
            },
            closeNudgeForm(){
                this.showPayWallCTA = false; 
                this.ctaDismissed = true; 
                window.sessionStorage.setItem('nudge_exp_dismissed', true)
            },
            payWallSubmission ( payWall = true ) {
                setTimeout(() => {
                    let payWallForm = payWall ? document.getElementById('payWallForm') : document.getElementById('nudgeForm');
                    if ( payWall ) {
                        this.is_paywall_submitted = true;
                    }
                    if ( payWallForm?.querySelector('form') ) {
                        payWallForm.querySelector('form').addEventListener( 'submit', (e) => {
                            if (payWall) {
                                localStorage.setItem( `paywall_exp_${ this.dataObj.id }`, true );
                                this.restrict = false;
                                setTimeout(() => {
                                    this.showPayWallCTA = false;
                                }, 3500);
                            } else {
                                localStorage.setItem( `nudge_exp_${ this.dataObj.id }`, true );
                                setTimeout(() => {
                                    this.showPayWallCTA = false;
                                }, 3500);
                            }

                            this.trackData?.addConversionEvent(this.analyticsData());
                        } );
                    } else {
                        let embeddedForm = payWallForm?.querySelector('iframe').contentWindow.document.querySelector('form');
                        embeddedForm?.addEventListener( 'submit', (e) => {
                            if (payWall) {
                                localStorage.setItem( `paywall_exp_${ this.dataObj.id }`, true );
                                this.restrict = false;
                                setTimeout(() => {
                                    this.showPayWallCTA = false;
                                }, 3500);
                            } else {
                                localStorage.setItem( `nudge_exp_${ this.dataObj.id }`, true );
                                setTimeout(() => {
                                    this.showPayWallCTA = false;
                                }, 3500);
                            }

                            this.trackData?.addConversionEvent(this.analyticsData());
                        } );
                    }
                }, 1000);
            },
            contextMenuEventListener(e){
                e.preventDefault();
                if(e.keyCode === 123) {
                    if (e.stopPropagation) {
                        e.stopPropagation();
                    } else if (window.event) {
                        window.event.cancelBubble = true;
                    }
                    e.preventDefault();
                    return false;
                }
            },
            disabledEvent(event) {
                if (event.stopPropagation) {
                    event.stopPropagation();
                } else if (window.event) {
                    window.event.cancelBubble = true;
                }
                event.preventDefault();
                return false;
            },
            closeModal () {
                if ( this.$props.depTyp !== 'embed' ) {
                    document.querySelector('html').style.overflow = 'auto';
                }
                clearInterval( this.nextInterval );
                window.removeEventListener("contextmenu", (e) => {
                    e.preventDefault();
                    if(e.keyCode === 123) {
                        this.disableEvent(e);
                    }
                });
                document.removeEventListener("keydown", (e) => {
                    //document.onkeydown = function(e) {
                    // "I" key
                    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                        this.disabledEvent(e);
                    }
                    // "J" key
                    if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                        this.disabledEvent(e);
                    }
                    // "S" key + macOS
                    if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                        this.disabledEvent(e);
                    }
                    // "U" key
                    if (e.ctrlKey && e.keyCode === 85) {
                        this.disabledEvent(e);
                    }
                    // "F12" key
                    if (e.keyCode === 123) {
                        this.disabledEvent(e);
                    }
                }, false);
                this.animationClass = 'fadeOut';
                setTimeout( () => {
                    this.showModal = false;
                }, 450 );

                this.trackData?.addTotalTimeEvent(this.analyticsData());

                this.$emit("onClose");
            },
            loadPDF ( url ) {
                return new Promise( ( resolve, reject ) => {

                    if ( this.initialLoad ) {
                        $('canvas').remove();

                        // Drag Scroll
                        $('.content-indicator').remove();
                        // Drag Scroll

                        this.loading = true;
                        if ( this.$refs.progressBar ) {
                            this.$refs.progressBar.style.width = `0%`;
                        }
                        if ( this.$refs.pdfViewer ) {
                            this.$refs.pdfViewer.scrollTop = 0;
                        }
                        this.progress = 0;
                        this.pdfResponse && this.pdfResponse.destroy()
                        this.pdfResponse = null;
                        this.showNext = false;
                        pdfjsLib.disableRange = true;
                        pdfjsLib.disableStream = true;
                    }
                    this.$Progress.start();

                    pdfjsLib.getDocument(`${ process.env.VUE_APP_CORS_URL }${ url }`).promise.then((pdf) => {
                        this.pdfResponse = pdf;
                        this.pdfViewer = document.getElementById("pdfViewer");
                        // let scale = 1.2;
                        for(let page = 1; page <= pdf.numPages; page++) {
                            var canvas = document.createElement('canvas');
                            canvas.className ="pdf-page-canvas";
                            canvas.id = `pdf-${page}`;
                            canvas.style.height = 'auto';
                            canvas.style.width = '100%';
                            this.pdfViewer.appendChild(canvas);
                            this.renderPage( page, canvas, pdf, this.max ? 2 : 1.45 );
                        }
                        // PDF Scroll Gap
                        let cnv = document.createElement('canvas');
                        cnv.className ="pdf-page-canvas";
                        cnv.id = `pdf-${pdf.numPages + 1}`;
                        cnv.style.height = `${this.pdfScrollGap}px`;
                        cnv.style.width = '100%';
                        this.pdfViewer.appendChild(cnv);
                        const ctx = cnv.getContext('2d');
                        ctx.beginPath();
                        ctx.rect(0, 0, 0, 0);
                        ctx.fill();
                        ctx.stroke();

                        resolve( true );

                        // Drag Scroll
                        // if(!this.isFirstAssetActive()){
                        //     let firstElement = document.createElement('section');
                        //     let logo = document.createElement('img');
                        //     let text = document.createElement('p');
                        //     logo.src = "https://securnyx360.s3.ap-south-1.amazonaws.com/lazy_logo.png"
                        //     text.textContent = "Swipe down for previous content"
                        //     firstElement.appendChild(logo)
                        //     firstElement.appendChild(text)
                        //     firstElement.className = "content-indicator"
                        //     this.pdfViewer.prepend(firstElement);
                        // }

                        // let lastElement = document.createElement('section');
                        // let logo = document.createElement('img');
                        // let text = document.createElement('p');
                        // logo.src = "https://securnyx360.s3.ap-south-1.amazonaws.com/lazy_logo.png"
                        // text.textContent = "Swipe up for next content"
                        // lastElement.appendChild(logo)
                        // lastElement.appendChild(text)
                        // lastElement.className = "content-indicator"
                        // this.pdfViewer.append(lastElement);
                        // Drag Scroll
                    });
                    // Zoom
                    this.zoomReset();
                } );
            },
            renderPage( pageNumber, canvas, thePdf, scale ) {
                // this.loading = true;
                this.$Progress.start();
                thePdf.getPage(pageNumber).then((page) => {
                    var viewport = page.getViewport({scale});
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    let ctx = canvas.getContext('2d');
                    page.render({canvasContext: ctx, viewport: viewport})
                    .promise.then(() => {
                        this.loading = false;
                    })
                });
            },
            maxMinWindow () {
                this.max = !this.max;
                $('canvas').remove();
                for(let page = 1; page <= this.pdfResponse.numPages; page++) {
                    var canvas = document.createElement('canvas');
                    pdfjsLib.disableStream = true;
                    canvas.className ="pdf-page-canvas";
                    canvas.id = `pdf-${page}`;
                    canvas.style.height = 'auto';
                    canvas.style.width = '100%';
                    this.pdfViewer.appendChild(canvas);
                    this.renderPage( page, canvas, this.pdfResponse, this.max ? 2 : 1.45 );
                }
            },
            // Zoom
            zoomIn(){
                this.currentZoomScale += this.zoomAmount

                if(this.currentZoomScale > this.zoomInLimit) this.currentZoomScale = this.zoomInLimit;

                this.zoomPdf(this.currentZoomScale);
            },
            zoomOut(){
                this.currentZoomScale -= this.zoomAmount;

                if(this.currentZoomScale < this.zoomOutLimit) this.currentZoomScale = this.zoomOutLimit;

                this.zoomPdf(this.currentZoomScale);
            },
            zoomPdf(scale){
                const canvasList = this.pdfViewer.querySelectorAll("canvas");

                canvasList.forEach((canvas, index) => {
                    if(scale > 100 && scale < (100 + (this.zoomAmount * 2))){
                        canvas.style.scrollSnapAlign = "center";
                    }

                    if(index < canvasList.length) canvas.style.width = scale + "%";

                    if(scale == this.zoomOutLimit){
                         canvas.style.border = "3px solid transparent";
                         this.$refs.pdfViewer.style.overflowX = 'hidden';
                    }else{
                         canvas.style.removeProperty("border");
                         this.$refs.pdfViewer.style.removeProperty("overflow-x");
                    }

                    setTimeout(() => {
                        canvas.style.removeProperty("scroll-snap-align")
                    }, 700)
                });

                const progress = this.$refs.pdfViewer.scrollTop / (this.$refs.pdfViewer.scrollHeight - this.$refs.pdfViewer.clientHeight);
                const scrollPercent = Math.round(progress * 100);

                if(scrollPercent == 100){
                    this.$refs.pdfViewer.scrollTop = this.$refs.pdfViewer.scrollTop - this.pdfScrollGap
                }
            },
            zoomReset(){
                this.currentZoomScale = this.zoomScale;
            },
            // Zoom
            moveToPrimary ( newSq ) {
                // Go to previous
                this.goToPrev = false;
                this.initialLoad = true;
                this.$Progress.start();
                if ( this.showLeftPanel && this.bgoverlay ) {
                    this.menuAction();
                }
                let old = { ...this.primarySeq };
                this.primarySeq = { ...newSq };
                this.primarySeq.assets.map( ( lib ) => {
                    lib.now = false;
                    return lib;
                } );
                this.primarySeq.assets[ 0 ].now = true;
                this.nowViewing = this.primarySeq.assets[ 0 ];
                if ( this.nowViewing.asset_type_id === 1 ) {
                    $('canvas').remove();
                    if ( this.$refs.progressBar ) {
                        this.$refs.progressBar.style.width = `0%`;
                    }
                    if ( this.$refs.pdfViewer ) {
                        this.$refs.pdfViewer.scrollTop = 0;
                    }
                    this.progress = 0;
                    this.pdfResponse && this.pdfResponse.destroy()
                    this.pdfResponse = null;
                    this.showNext = false;
                    pdfjsLib.disableRange = true;
                    pdfjsLib.disableStream = true;
                    setTimeout( () => {
                        this.loadPDF( this.nowViewing.asset_url ).then( ( response ) => {
                            if ( response ) {
                                this.$Progress.finish();
                                this.loading = true;
                            }
                        } );
                    }, 300 );
                }
                this.upNext = this.primarySeq.assets[ 1 ];
                this.showNext = true;
                old.assets.map( ( oldLib ) => {
                    oldLib.now = false;
                    return oldLib;
                } );
                this.dataObj.sequences.push( old );
                remove( this.dataObj.sequences, function ( sq ) {
                    return sq.id === newSq.id;
                } );
                this.obj.primary_sequence = newSq;
            },
            showContent ( libNow, index, clicked = false ) {
                // Go to previous
                this.goToPrev = false;
                this.showTimer = false;
                // this.secondsCounter = 5;
                // this.secondsTimer = null;

                if ( this.showLeftPanel && this.bgoverlay ) {
                    this.menuAction();
                }

                this.$Progress.start();
                // let old = { ...this.nowViewing };
                // old.now = false;
                this.primarySeq.assets.map( ( lib, i ) => {
                    lib.now = lib.id === libNow.id;

                    if ( lib.id === libNow.id ) {
                        if ( typeof( this.primarySeq.assets[ i + 1 ] ) != "undefined" ) {
                            this.upNext = this.primarySeq.assets[ i + 1 ];
                        } else {
                            this.upNext = this.primarySeq.assets[ 0 ];
                        }
                    }

                    return lib;
                } );
                // if ( ( this.primarySeq.assets.length - 1 ) <= index ) {
                //     this.upNext = this.primarySeq.assets[ 0 ];
                //     this.showNext = false;
                //     this.currentIndex = 1;
                // } else {
                //     this.upNext = this.primarySeq.assets[ index + 1 ];
                //     this.showNext = true;
                //     this.currentIndex = index + 1;
                // }
                this.videoLoad( libNow );
                this.nowViewing = { ...libNow };
                this.nowViewing.now = true;

                this.initialLoad = false;
                if ( clicked ) {
                    this.initialLoad = true;
                }
                if ( this.nowViewing.id === this.primarySeq.assets[ 0 ].id ) {
                    this.initialLoad = true;
                }

                if ( libNow.asset_type_id === 1 ) {
                    if ( this.initialLoad ) {
                        $('canvas').remove();
                        if ( this.$refs.progressBar ) {
                            this.$refs.progressBar.style.width = `0%`;
                        }
                        if ( this.$refs.pdfViewer ) {
                            this.$refs.pdfViewer.scrollTop = 0;
                        }
                        this.progress = 0;
                        this.pdfResponse && this.pdfResponse.destroy()
                        this.pdfResponse = null;
                        this.showNext = false;
                        pdfjsLib.disableRange = true;
                        pdfjsLib.disableStream = true;
                    }
                    setTimeout( () => {
                        this.loadPDF( libNow.asset_url ).then( ( response ) => {
                            if ( response ) {
                                this.$Progress.finish();
                            }
                        } );
                    }, 300 );
                }

                if ( this.nowViewing.asset_type_id !== 1 || clicked ) {
                    this.comingUp = null;
                }

                if ( libNow.asset_type_id !== 6 ) {
                    setTimeout( () => {
                        this.$Progress.finish();
                    }, 450 );
                }

                this.trackData?.addAssetViewEvent(this.analyticsData());
            },
            async videoLoad ( lib ) {
                if ( lib.asset_type_id === 6 ) {
                    try {
                        this.loading = true;
                        const dataObj = await this.getFileTypeBlob( lib.asset_url );
                        this.playerOptions.sources = [{
                            type: dataObj,
                            src: lib.asset_url,
                        }];
                        this.playerOptions.poster = lib.cover_image_url;
                        this.loading = false;
                    } catch (e) {
                        console.log(e);
                        this.loading = false;
                    }
                } else {
                    return true;
                }
            },
            downloadItem ({ asset_url, asset_friendly_name }) {
                this.toast( `Starting Download`, `s` );
                this.$Progress.start();
                this.axios( {
                    url: `${ process.env.VUE_APP_CORS_URL }${ asset_url }`,
                    method: 'GET',
                    responseType: 'blob',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                } )
                .then( ( response ) => {
                    const url = window.URL.createObjectURL( new Blob( [ response.data ] ) );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    let ext = response.data.type.split( "/" );
                    link.setAttribute( 'download', `${ asset_friendly_name }.${ ext[ 1 ] }` );
                    document.body.appendChild( link );
                    link.click();
                    this.$Progress.finish();
                } )
                .catch( ( error ) => {
                    console.log(error.response);
                } )
            },
            getFonts () {
                // this.loading = true;
                // this.axios.get( `https://www.googleapis.com/webfonts/v1/webfonts?key=${ process.env.VUE_APP_GOOGLE_API_KEY }` )
                // .then( ( response ) => {
                //     const { items } = response.data;
                //     let fontObj = _.filter( items, { 'family': this.dataObj.style_guide_id.font, } );
                //     this.fontObj = fontObj[0];
                //     this.fontFile = this.fontObj.files.regular;
                //     this.loading = false;
                // } )
                // .catch( ( errorRes ) => {
                //     this.loading = false;
                //     console.log( errorRes );
                // } )
            },
            // created by sayan saha
            menuAction () {
                this.showLeftPanel = !this.showLeftPanel;
                this.bgoverlay = !this.bgoverlay;
            },
            // Analytics Functions
            initAnalytics(){
              return new AnalyticsTracking({
                    platform_customer_id: this.dataObj.platform_customer_id,
              })
            },
            analyticsData() {
                return {
                    experience:{
                        id: parseInt(this.obj.id),
                        name: this.obj.experience_name,
                        experience_type: this.obj.experience_type,
                        delivery_type: this.obj.delivery_type || "share"
                    },
                    asset: {
                        id: parseInt(this.nowViewing.id),
                        name: this.nowViewing.asset_friendly_name,
                    },
                    sequence: {
                        id: parseInt(this.primarySeq.id),
                        name: this.primarySeq.sequence_name,
                    },
                    customer: this.customerData
                }
            },
            // Analytics Functions
            // Deep Link
            showSequence(sequenceId = 0){
                if(!sequenceId) return

                const primarySequence = this.dataObj.primary_sequence;
                this.dataObj.sequences = [...this.dataObj.sequences, primarySequence]
                const currentSequence = this.dataObj.sequences.find(({id}) => id == sequenceId);
                this.dataObj.primary_sequence = currentSequence
                this.dataObj.sequences = this.dataObj.sequences.filter(({id}) => id != currentSequence.id);
            },
            showAsset(){
                if(!this.showAssetId) return;

                let assetIndex = this.primarySeq.assets.findIndex(({id}) => id == this.showAssetId);
                assetIndex = assetIndex == -1 ? 0 : assetIndex;
                this.primarySeq.assets[ assetIndex ].now = true;
                this.nowViewing = this.primarySeq.assets[ assetIndex ];
                let upNextIndex = assetIndex == this.primarySeq.assets.length - 1 ? assetIndex : assetIndex + 1
                this.upNext = this.primarySeq.assets[ upNextIndex ];
            },
            // Deep Link

            // Drag Scroll
            mouseDownHandler(e) {
                this.$refs.pdfViewer.style.cursor = 'grabbing';
                this.$refs.pdfViewer.style.userSelect = 'none';

                this.dragPos = {
                    left: this.$refs.pdfViewer.scrollLeft,
                    top: this.$refs.pdfViewer.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                this.$refs.pdfViewer.addEventListener('mousemove', this.mouseMoveHandler);
                this.$refs.pdfViewer.addEventListener('mouseup', this.mouseUpHandler);
                this.$refs.pdfViewer.addEventListener('mouseleave', this.mouseLeaveHandler);
            },
            mouseMoveHandler(e) {
                // How far the mouse has been moved
                const dx = e.clientX - this.dragPos.x;
                const dy = e.clientY - this.dragPos.y;

                // Scroll the element
                this.$refs.pdfViewer.scrollTop = this.dragPos.top - dy;
                this.$refs.pdfViewer.scrollLeft = this.dragPos.left - dx;

                this.handleSwipe(dx, dy);
            },
            mouseUpHandler() {
                this.handleSwipeRelease();
            },
            mouseLeaveHandler() {
                this.dragScrollPrevElementHeight = 0;
                this.$refs.pdfViewer.firstElementChild.style.marginBottom = `${this.dragScrollPrevElementHeight}px`;

                this.dragScrollNextElementHeight = 0
                this.$refs.pdfViewer.lastElementChild.style.marginTop = `${this.dragScrollNextElementHeight}px`;


                this.$refs.pdfViewer.style.cursor = 'grab';
                this.$refs.pdfViewer.style.removeProperty('user-select');

                this.$refs.pdfViewer.removeEventListener('mousemove', this.mouseMoveHandler);
                this.$refs.pdfViewer.removeEventListener('mouseup', this.mouseUpHandler);
            },
            touchStartHandler(e) {
                e.preventDefault()

                this.dragPos = {
                    left: this.$refs.pdfViewer.scrollLeft,
                    top: this.$refs.pdfViewer.scrollTop,
                    // Get the current mouse position
                    x: e.targetTouches[0].clientX,
                    y: e.targetTouches[0].clientY,
                };

                this.$refs.pdfViewer.addEventListener('touchmove', this.touchMoveHandler);
                this.$refs.pdfViewer.addEventListener('touchend', this.touchEndHandler);
            },
            touchMoveHandler(e) {
                e.preventDefault()

                // How far the mouse has been moved
                const dx = e.targetTouches[0].clientX - this.dragPos.x;
                const dy = e.targetTouches[0].clientY - this.dragPos.y;

                // Scroll the element
                this.$refs.pdfViewer.scrollTop = this.dragPos.top - dy;
                this.$refs.pdfViewer.scrollLeft = this.dragPos.left - dx;

                this.handleSwipe(dx, dy);
            },
            touchEndHandler() {
                this.handleSwipeRelease();
            },
            handleSwipe(dx, dy){
                let maxheight = (this.$refs.pdfViewer.scrollHeight - this.$refs.pdfViewer.clientHeight)

                if(maxheight == 0) maxheight = 1;

                let progress = this.$refs.pdfViewer.scrollTop / maxheight;

                let isNegative = (progress == 0)

                let scrollPercent = Math.round(progress * 100);
                let scrollNextPercent = scrollPercent;

                if(isNegative) {
                    scrollNextPercent = 100
                }

                // Prev Asset
                const isFirstAsset = this.isFirstAssetActive()
                if(!isFirstAsset && scrollPercent == 0 && dy > 0){
                    let value = dy - 100
                    if(value < 0) value = 0
                    this.dragScrollPrevElementHeight = value;

                    if(this.dragScrollPrevElementHeight > this.dragScrollLimit){
                        this.dragScrollPrevElementHeight = this.dragScrollLimit
                    }

                    this.$refs.pdfViewer.firstElementChild.style.marginBottom = `${this.dragScrollPrevElementHeight}px`;
                }

                // Next Asset
                if(scrollNextPercent == 100 && dy < 0){
                    let value = Math.abs(dy) - 100
                    if(value < 0) value = 0
                    this.dragScrollNextElementHeight = value;

                    if(this.dragScrollNextElementHeight > this.dragScrollLimit){
                        this.dragScrollNextElementHeight = this.dragScrollLimit
                    }

                    this.$refs.pdfViewer.lastElementChild.style.marginTop = `${this.dragScrollNextElementHeight}px`;

                    if(this.dragScrollNextElementHeight > 0){
                        this.$refs.pdfViewer.scrollTop = this.$refs.pdfViewer.scrollHeight
                    }
                }
            },
            handleSwipeRelease(){
                let isPrevScrolled = false;

                // Prev Asset
                if(this.dragScrollPrevElementHeight < this.dragScrollLimit){
                    this.dragScrollPrevElementHeight = 0
                    this.$refs.pdfViewer.firstElementChild.style.marginBottom = `${this.dragScrollPrevElementHeight}px`;
                }else{
                    let assetIndex = 0;
                    this.primarySeq.assets.forEach(({now}, index) => {
                        if(now) return assetIndex = index - 1;
                    });
                    if(assetIndex >= 0){
                        this.upNext = this.primarySeq.assets[assetIndex + 1];
                        this.currentIndex -= 2;
                        this.showContent( this.primarySeq.assets[assetIndex], this.currentIndex );
                        isPrevScrolled = true;
                    }
                }

                // Next Asset
                if(!isPrevScrolled && this.dragScrollNextElementHeight < this.dragScrollLimit){
                     this.dragScrollNextElementHeight = 0
                     this.$refs.pdfViewer.lastElementChild.style.marginTop = `${this.dragScrollNextElementHeight}px`;

                }else if(this.dragScrollNextElementHeight == this.dragScrollLimit){
                    this.showContent(this.upNext, this.currentIndex)
                }

                this.dragScrollNextElementHeight = 0;
                this.dragScrollPrevElementHeight = 0;

                this.$refs.pdfViewer.style.cursor = 'grab';
                this.$refs.pdfViewer.style.removeProperty('user-select');

                this.$refs.pdfViewer.removeEventListener('mousemove', this.mouseMoveHandler);
                this.$refs.pdfViewer.removeEventListener('mouseup', this.mouseUpHandler);
                this.$refs.pdfViewer.removeEventListener('touchmove', this.mouseMoveHandler);
                this.$refs.pdfViewer.removeEventListener('touchend', this.mouseUpHandler);
            },
            isFirstAssetActive(){
                return this.primarySeq.assets[0].now === true;
            }
            // Drag Scroll
        },
        computed: {
            sequenceChange () {
                if ( this.dataObj.primary_sequence) {
                    document.addEventListener('pagesinit', function () {
                        pdfjsViewer.currentScaleValue = 'page-width';
                    });
                    if ( this.dataObj.primary_sequence.assets.length > 0 ) {
                        this.primarySeq = this.dataObj.primary_sequence;
                        this.primarySeq.assets = this.primarySeq.assets.map( ( lib ) => {
                            lib.now = false;
                            return lib;
                        });

                        if(!this.showAssetId && !this.linkAssetShown){ //Deep link
                            this.primarySeq.assets[ 0 ].now = true;
                            this.nowViewing = this.primarySeq.assets[ 0 ];
                            this.upNext = this.primarySeq.assets[ 1 ];
                        }

                        // this.primarySeq.assets[ 0 ].now = true;
                        // this.nowViewing = this.primarySeq.assets[ 0 ];
                        // this.upNext = this.primarySeq.assets[ 1 ];

                        // if ( this.nowViewing.asset_type_id === 1 ) {
                        //     this.loadPDF( this.nowViewing.asset_url );
                        // }
                        // this.videoLoad( this.primarySeq.assets[ 0 ] );

                        this.showNext = false;
                    }
                }
            },
            adjustRatio () {
                if (window.innerHeight) {
                    window.addEventListener('resize', () => {
                        this.contentWidth = window.innerWidth;
                        this.contentHeight = window.innerHeight;
                    });
                }
            }
        },
    }
</script>
<style>
    .video-js {
        max-height: 700px !important;
        height: 700px !important;
    }
    .vjs_video_3-dimensions {
        display: flex !important;
        text-align: center !important;
        justify-content: center !important;align-items: center !important;
    }
    .vjs-big-play-button {
        left: auto !important;
        top: auto !important;
    }
    .vjs-playing, .vjs-paused {
        max-width: 100% !important;
        width: 100% !important;
        display: flex !important;
        text-align: center !important;
        justify-content: center !important;align-items: center !important;
    }
    .video-js .vjs-control {
        width: 5em !important;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
    }
    .vjs-play-control .vjs-control .vjs-button .vjs-paused {
        width: 150px !important;
    }
    .pdfViewer{
         scroll-snap-type: y proximity;
    }
    .pdf-page-canvas {
        width: 100%;
        height: 100%;
    }
    /* Drag Scroll */
    #pdfViewer .content-indicator{
         display: flex;
         justify-content: center;
         align-items: center;
         padding-top: 10px;
         padding-bottom: 15px;
    }
    #pdfViewer .content-indicator img{
         width: 30px;
         height: 30px;
    }
    #pdfViewer .content-indicator p{
        padding-left: 10px;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 0.9em;
    }
    /* Drag Scroll */
</style>
<!--<style lang="sass">-->
<!--    @font-face {-->
<!--        font-family: { fontObj.family } !important;-->
<!--        src: url('{{fontFile}}');-->
<!--    }-->
<!--</style>-->
