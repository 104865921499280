var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showRec)?_c('div',[_c('div',{staticClass:"storyscale_widget-holder",staticStyle:{"z-index":"111"}},[_c('div',{staticStyle:{"display":"flex","width":"100%"}},[_c('h3',{staticClass:"storyscale_heading-3",staticStyle:{"width":"95%"},style:([
                    _vm.dataObj.style_guide_id ?
                    {
                        fontFamily: _vm.dataObj.style_guide_id.font.toLocaleString(),
                    } : {}
                ])},[_vm._v(" Recommended Content ")]),_c('a',{staticStyle:{"font-size":"19px","text-decoration":"none","outline":"none","margin-top":"-4px","color":"#858586","font-weight":"600"},style:([
                    _vm.dataObj.style_guide_id ?
                    {
                        fontFamily: _vm.dataObj.style_guide_id.font.toLocaleString(),
                    } : {}
                ]),attrs:{"href":"#"},on:{"click":function($event){_vm.showRec = false}}},[_vm._v("×")])]),(_vm.dataObj.recommend_type === 'experience')?_c('div',_vm._l((_vm.sequence.assets),function(a,i){return _c('div',{key:i,staticClass:"storyscale_each-list-holder"},[_c('div',{staticClass:"storyscale_img-holder",staticStyle:{"max-height":"56px !important","height":"56px !important","width":"133px","max-width":"133px"}},[_c('img',{staticClass:"storyscale_cstm-img-fluid",staticStyle:{"height":"100% !important"},attrs:{"src":a.cover_image_url,"alt":"image"}})]),_c('h4',{staticStyle:{"width":"100% !important","text-align":"left !important","padding-left":"10px"},style:([
                        _vm.dataObj.style_guide_id ?
                        {
                            fontFamily: _vm.dataObj.style_guide_id.font.toLocaleString(),
                        } : {}
                    ])},[_vm._v(_vm._s(a.asset_friendly_name))]),_c('div',[_c('a',{staticClass:"storyscale_widget-border-icon",attrs:{"href":"#"},on:{"click":function($event){return _vm.showModal( a )}}},[_c('svg',{attrs:{"width":"6","height":"10","viewBox":"0 0 6 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.293124 9.70749C-0.0975278 9.3173 -0.0977343 8.68446 0.292663 8.29401L4.29266 4.29349C4.68306 3.90304 5.31622 3.90284 5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651L1.70734 9.70703C1.31694 10.0975 0.683775 10.0977 0.293124 9.70749Z","fill":_vm.dataObj.style_guide_id.primary_accent_color}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.293123 0.29251C0.683775 -0.097683 1.31694 -0.0974761 1.70734 0.292972L5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651C5.31669 6.0967 4.68306 6.09696 4.29266 5.70651L0.292662 1.70599C-0.0977346 1.31554 -0.0975282 0.682704 0.293123 0.29251Z","fill":_vm.dataObj.style_guide_id.primary_accent_color}})])])])])}),0):_vm._e(),(_vm.dataObj.recommend_type === 'links')?_c('div',_vm._l((_vm.link_assets),function(a,i){return _c('div',{key:i,staticClass:"storyscale_each-list-holder"},[_c('div',{staticClass:"storyscale_img-holder",staticStyle:{"max-height":"56px !important","height":"56px !important","width":"133px","max-width":"133px"}},[_c('img',{staticClass:"storyscale_cstm-img-fluid",staticStyle:{"height":"100% !important"},attrs:{"src":a.cover_image_url,"alt":"image"}})]),_c('h4',{staticStyle:{"width":"100% !important","text-align":"left !important","padding-left":"10px"},style:([
                        _vm.dataObj.style_guide_id ?
                        {
                            fontFamily: _vm.dataObj.style_guide_id.font.toLocaleString(),
                        } : {}
                    ])},[_vm._v(_vm._s(a.asset_friendly_name))]),_c('div',[_c('a',{staticClass:"storyscale_widget-border-icon",attrs:{"href":a.link,"target":"_blank"},on:{"click":(event) => _vm.linkClicked(event, a.link, a)}},[_c('svg',{attrs:{"width":"6","height":"10","viewBox":"0 0 6 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.293124 9.70749C-0.0975278 9.3173 -0.0977343 8.68446 0.292663 8.29401L4.29266 4.29349C4.68306 3.90304 5.31622 3.90284 5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651L1.70734 9.70703C1.31694 10.0975 0.683775 10.0977 0.293124 9.70749Z","fill":_vm.dataObj.style_guide_id.primary_accent_color}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M0.293123 0.29251C0.683775 -0.097683 1.31694 -0.0974761 1.70734 0.292972L5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651C5.31669 6.0967 4.68306 6.09696 4.29266 5.70651L0.292662 1.70599C-0.0977346 1.31554 -0.0975282 0.682704 0.293123 0.29251Z","fill":_vm.dataObj.style_guide_id.primary_accent_color}})])])])])}),0):_vm._e(),(_vm.dataObj.primary_cta_id)?_c('a',{staticClass:"storyscale_ss_btn storyscale_explore-btn",staticStyle:{"text-decoration":"none"},style:([
                _vm.dataObj.style_guide_id ?
                {
                    fontFamily: _vm.dataObj.style_guide_id.font.toLocaleString(),
                    color: _vm.dataObj.style_guide_id.primary_accent_color,
                } : {}
            ]),attrs:{"href":_vm.dataObj.primary_cta_id.cta_links_to,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.dataObj.primary_cta_id.text)+" ")]):_vm._e()]),(_vm.dataObj.recommend_type === 'experience')?_c('div',[(_vm.experience && _vm.experience.experience_type === 'viewer' && _vm.passObject && _vm.show)?_c('lightViewer',{attrs:{"obj":_vm.passObject,"showAssetId":_vm.currentAssetId,"showSequenceId":_vm.sequence.id},on:{"onClose":_vm.closeModal}}):(_vm.experience && _vm.experience.experience_type === 'spotlight' && _vm.passObject && _vm.show)?_c('spotlightLight',{attrs:{"obj":_vm.passObject,"showAssetId":_vm.currentAssetId,"showSequenceId":_vm.sequence.id},on:{"onClose":_vm.closeModal}}):_vm._e()],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }