import Vue from 'vue'
import App from './App.vue'
import WebWidget from './web-widget'
import WebLauncher from './web-launcher'
import HubWidget from './hub-widget'
import TourWidget from './tour/widget'
import TourLauncher from './web-launcher/tour'

import AgentWidget from './agent/widget'
import AgentLauncher from './web-launcher/agent'

import 'video.js/dist/video-js.css'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueProgressBar from 'vue-progressbar'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import mixin from './mixin'
import directives from './mixin/directives'
import { parseObjectString } from './utils'

const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL, timeout: 100000 });

window.axios = axiosInstance;
Vue.use(VueAxios, axiosInstance);

Vue.use(vueCustomElement)
Vue.use(VueProgressBar, { color: '#4B60EB', failedColor: 'red', height: '4px', thickness: '5px' })
Vue.use(VueToast)

mixin(Vue)
directives(Vue)

Vue.config.productionTip = false

const components = {
  'widget': App,
  'hub': HubWidget,
  'web-widget': WebWidget,
  'launcher': WebLauncher,
  'tour-launcher': TourLauncher,
  'agent-launcher': AgentLauncher
}

const prefixes = ['storyscale', 'showpilot']

prefixes.forEach(prefix => {
  Object.entries(components).forEach(([name, component]) => {
    Vue.customElement(`${prefix}-${name}`, component)
  })
})

function domReady() {
  return new Promise(resolve => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", resolve)
    } else {
      resolve()
    }
  })
}

function appendStylesheet() {
  try {
    const script = document.currentScript
    if (script) {
      const origin = new URL(script.src).origin
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.type = 'text/css'
      link.href = `${origin}/css/app.css`
      document.head.appendChild(link)
    }
  } catch (error) {
    console.log("[Widget]: Error appending stylesheet:", error.message)
  }
}

function createWidget(element, widgetName, props, experienceType = null) {
  const widget = document.createElement(widgetName)
  
  Object.entries(props).forEach(([key, value]) => {
    if (value) widget.setAttribute(key, value)
  })
  
  element.parentElement.insertBefore(widget, element)
  element.remove()
}

function initializeWidgets() {
  prefixes.forEach(prefix => {
    initializeWidgetType(`${prefix}-widget`, "widget")
    initializeWidgetType(`${prefix}-hub`, "hub")
    initializeWidgetType(`${prefix}-web-widget`, "web-widget")
    initializeLauncher(`${prefix}-launcher`)
    initializeTourWidget(`${prefix}-tour`)
    initializeTourLauncher(`${prefix}-tour-launcher`)

    initializeAgentWidget(`${prefix}-agent`);
    initializeAgentLauncher(`${prefix}-agent-launcher`);
  })
}

function initializeWidgetType(selector, widgetName) {
  document.querySelectorAll(`[class*=${selector}]`).forEach(element => {
    const props = parseWidgetProps(element.id)
    let experienceType = null
    if (props.token) {
      try {
        experienceType = JSON.parse(atob(props.token) || '{}').experience_type
      } catch (error) {
        console.log(`Error parsing token for ${widgetName}:`, error)
      }
    }
    createWidget(element, `storyscale-${widgetName}`, props, experienceType)
  })
}

function parseWidgetProps(id) {
  const props = {}
  id.split(',').forEach(item => {
    const [key, value] = item.split(':')
    if (value) props[key] = value
  })
  return props
}

function initializeLauncher(selector) {
  const buttons = [
    ...document.querySelectorAll(`[class*=${selector}]`),
    ...document.querySelectorAll(`[${selector}]`),
    ...document.querySelectorAll(`[href*='${process.env.VUE_APP_SHARE_URL}']`)
  ]

  buttons.forEach(button => {
    const token = getLauncherToken(button, selector)
    if (token) addLauncherWidget(button, token, 'storyscale-launcher')
  })
}

function getLauncherToken(button, selector) {
  if (button.classList.contains(selector)) {
    return button.className.split(`${selector}-`)[1]
  } 
  else if (button.hasAttribute(selector)) {
    return button.getAttribute(selector)
  } 
  else {
    try {
      const url = new URL(button.getAttribute("href"))
      const params = Object.fromEntries(url.searchParams)
      if (params.launcher === "true" && params.clfn !== "tour" && params.rptn) {
        return params.rptn
      }
    } catch (e) {
      console.log("Error parsing URL:", e)
    }
  }
  return null
}

function addLauncherWidget(button, token, widgetName) {
  const tokenId = `story-${token}`
  if (!document.getElementById(tokenId)) {
    const launcher = document.createElement(widgetName)
    launcher.setAttribute("token", token)
    launcher.setAttribute("id", tokenId)
    document.body.appendChild(launcher)
  }
  button.addEventListener("click", (event) => {
    event.preventDefault()
    document.getElementById(tokenId).__vue_custom_element__.$children[0].showModal()
  })
}

function initializeTourWidget(selector) {
  document.querySelectorAll(`[${selector}]`).forEach(element => {
    const token = element.getAttribute(selector)
    if (token) {
      const props = parseProps(element.getAttribute('storyscale-props'))
      TourWidget.createWidget({ token, element, delivery: 'embed', props })
    }
  })
}

function initializeTourLauncher(selector) {
  const buttons = [
    ...document.querySelectorAll(`[${selector}]`),
    ...document.querySelectorAll(`[class*=${selector}]`),
    ...document.querySelectorAll(`[href*='${process.env.VUE_APP_SHARE_URL}']`)
  ]

  buttons.forEach(button => {
    const token = getTourLauncherToken(button, selector)
    if (token) addTourLauncherWidget(button, token)
  })
}

function getTourLauncherToken(button, selector) {
  if (button.hasAttribute(selector)) {
    return button.getAttribute(selector)
  } else if (button.className.includes(selector)) {
    return button.className.split(`${selector}-`)[1]
  } else {
    try {
      const url = new URL(button.getAttribute("href"))
      const params = Object.fromEntries(url.searchParams)
      if (params.launcher === "true" && params.clfn === "tour" && params.rptn) {
        return params.rptn
      }
    } catch (e) {
      console.log("Error parsing URL:", e)
    }
  }
  return null
}

function addTourLauncherWidget(button, token) {
  const tokenId = `story-${token}`
  if (!document.getElementById(tokenId)) {
    const launcher = document.createElement("storyscale-tour-launcher")
    launcher.setAttribute("token", token)
    launcher.setAttribute("id", tokenId)
    document.body.appendChild(launcher)
  }
  const props = parseProps(button.getAttribute('storyscale-props'))
  button.addEventListener("click", (event) => {
    event.preventDefault()
    document.getElementById(tokenId).__vue_custom_element__.$children[0].showModal(props)
  })
}

function initializeAgentWidget(selector) {
  document.querySelectorAll(`[${selector}]`).forEach(element => {
    const token = element.getAttribute(selector)
    if (token) {
      const props = parseProps(element.getAttribute('storyscale-props'))
      AgentWidget.createWidget({ token, element, delivery: 'embed', props })
    }
  })
}

function initializeAgentLauncher(selector) {
  const buttons = [
    ...document.querySelectorAll(`[${selector}]`),
    ...document.querySelectorAll(`[class*=${selector}]`),
    ...document.querySelectorAll(`[href*='${process.env.VUE_APP_SHARE_URL}']`)
  ]

  buttons.forEach(button => {
    const token = getAgentLauncherToken(button, selector)
    if (token) addAgentLauncherWidget(button, token)
  })
}

function getAgentLauncherToken(button, selector) {
  if (button.hasAttribute(selector)) {
    return button.getAttribute(selector)
  } else if (button.className.includes(selector)) {
    return button.className.split(`${selector}-`)[1]
  } else {
    try {
      const url = new URL(button.getAttribute("href"))
      const params = Object.fromEntries(url.searchParams)
      if (params.launcher === "true" && params.clfn === "agent" && params.rptn) {
        return params.rptn
      }
    } catch (e) {
      console.log("Error parsing URL:", e)
    }
  }
  return null
}

function addAgentLauncherWidget(button, token) {
  const tokenId = `story-${token}`
  if (!document.getElementById(tokenId)) {
    const launcher = document.createElement("showpilot-agent-launcher")
    launcher.setAttribute("token", token)
    launcher.setAttribute("id", tokenId)
    document.body.appendChild(launcher)
  }
  const props = parseProps(button.getAttribute('props'))
  button.addEventListener("click", (event) => {
    event.preventDefault()
    document.getElementById(tokenId).__vue_custom_element__.$children[0].showModal(props)
  })
}

function parseProps(propsString) {
  if (!propsString) return {}
  try {
    return parseObjectString(propsString)
  } catch (error) {
    console.log("Error parsing props:", error)
    return {}
  }
}

appendStylesheet()
domReady().then(initializeWidgets)