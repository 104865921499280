<template>
  <section
    class="pdf-viewer-container custom-scrollbar empty"
    ref="pdfViewerContainer"
    @scroll="handleScroll"
  >
    <section class="pdf-viewer-toolbar">
      <div class="pdf-viewer-toolbar-actions">
        <div>
          <h6 style="color: #475569">{{ title }}</h6>
        </div>
        <div class="text-center">
          <h6 style="color: #475569">{{currentPageNumber}} / {{ totalPages }}</h6>
        </div>
        <div class="d-flex justify-content-end align-items-center" style="gap: 10px;">
          <button class="pdf-viewer-btn p-0" @click="zoomIn">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#4b60eb" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"/><path fill="#4b60eb" d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2z"/></svg>
          </button>
          <button class="pdf-viewer-btn p-0" @click="zoomOut">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#4b60eb" d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.26 4.25c.41.41 1.07.41 1.48 0l.01-.01c.41-.41.41-1.07 0-1.48zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14m-2-5h4c.28 0 .5.22.5.5s-.22.5-.5.5h-4c-.28 0-.5-.22-.5-.5s.22-.5.5-.5"/></svg>
          </button>
          <button class="pdf-viewer-btn p-0" @click="toggleFullscreen">
            <span v-if="!isFullscreen">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="#4b60eb" d="M6 14c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1H7v-2c0-.55-.45-1-1-1m0-4c.55 0 1-.45 1-1V7h2c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1m11 7h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1zM14 6c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V6c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1"/></svg>
            </span>
            <span v-else>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="#4b60eb" d="M5 16h3v3h2v-5H5zm3-8H5v2h5V5H8zm6 11h2v-3h3v-2h-5zm2-11V5h-2v5h5V8z"/></svg>
            </span>
          </button>
        </div>
      </div>
      <div class="pdf-viewer-progress" ref="progressBar" :style="{ width: progressBarWidth }"></div>
    </section>
    <section class="pdf-viewer" :class="{'pdf-shimmer': loading}" ref="pdfViewer"></section>
  </section>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";

export default {
  props: ["title", "url", "onScroll"],
  data() {
    return {
      loading: false,
      pdfResponse: null,
      currentPageNumber: 1,
      totalPages: 1,
      // Fullscreen
      isFullscreen: false,
      // Zoom
      defaultZoomScale: 80,
      zoomAmount: 20,
      currentZoomScale: 80,
      zoomInLimit: 100,
      zoomOutLimit: 40,
      // Scroll
      progress: 0,
    };
  },
  created() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
  },
  mounted() {
    this.load(this.url);

    document.addEventListener('fullscreenchange', this.fullscreenChanged);
  },
  destroyed() {
    this.pdfResponse && this.pdfResponse.destroy();

    document.removeEventListener('fullscreenchange', this.fullscreenChanged);
  },
  methods: {
    load(url) {
      this.loading = true;

      return pdfjsLib.getDocument(url).promise.then((pdf) => {
        this.pdfResponse = pdf;
        this.totalPages = pdf.numPages;

        for (let page = 1; page <= pdf.numPages; page++) {
          const canvas = document.createElement("canvas");
          canvas.className = "pdf-page";
          canvas.id = `pdf-${page}`;
          canvas.style.height = "auto";
          canvas.style.width = "100%";

          this.pdfViewer.appendChild(canvas);

          this.renderPage(page, canvas, pdf, this.max ? 2 : 1.5);
        }

        this.zoomReset();

        return pdf
      }).then(() => {
        this.loading = false;
      });
    },
    renderPage(pageNumber, canvas, pdfInstance, scale) {
      pdfInstance.getPage(pageNumber).then((page) => {
        const viewport = page.getViewport({ scale });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const ctx = canvas.getContext("2d");

        page
          .render({ canvasContext: ctx, viewport: viewport })
          .promise.then(() => {});
      });
    },
    // Zoom
    zoomIn() {
      this.currentZoomScale += this.zoomAmount;

      if (this.currentZoomScale > this.zoomInLimit)
        this.currentZoomScale = this.zoomInLimit;

      this.zoomPdf(this.currentZoomScale);
    },
    zoomOut() {
      this.currentZoomScale -= this.zoomAmount;

      if (this.currentZoomScale < this.zoomOutLimit)
        this.currentZoomScale = this.zoomOutLimit;

      this.zoomPdf(this.currentZoomScale);
    },
    zoomReset() {
      this.currentZoomScale = this.defaultZoomScale;

      this.zoomPdf(this.currentZoomScale);
    },
    zoomPdf(scale) {
      const canvasList = this.pdfViewer.querySelectorAll("canvas");

      canvasList.forEach((canvas, index) => {
        if (scale > 100 && scale < 100 + this.zoomAmount * 2) {
          canvas.style.scrollSnapAlign = "center";
        }

        if (index < canvasList.length) canvas.style.width = scale + "%";

        if (scale == this.zoomOutLimit) {
          canvas.style.margin = "3px";
          this.$refs.pdfViewer.style.overflowX = "hidden";
        } else {
          canvas.style.removeProperty("margin");
          this.$refs.pdfViewer.style.removeProperty("overflow-x");
        }
      });
    },
    // Scroll
    handleScroll() {
      const progress = this.pdfViewerContainer.scrollTop / (this.pdfViewerContainer.scrollHeight - this.pdfViewerContainer.clientHeight);

      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }

      const pageHeight = this.pdfViewerContainer.scrollHeight / this.totalPages;
      const newPageNumber = Math.ceil((this.pdfViewerContainer.scrollTop + this.pdfViewerContainer.clientHeight / 2) / pageHeight);
     
      this.currentPageNumber = newPageNumber;

      this.$emit("onScroll", Math.round(this.progress * 100));
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$el.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable fullscreen: ${err.message}`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    fullscreenChanged() {
      this.isFullscreen = !!document.fullscreenElement;
    },
  },
  computed: {
    pdfViewerContainer() {
      return this.$refs.pdfViewerContainer;
    },
    pdfViewer() {
      return this.$refs.pdfViewer;
    },
    progressBarWidth() {
      return `${Math.round(this.progress * 100)}%`;
    },
  },
};
</script>

<style>
.pdf-viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.pdf-viewer-container:fullscreen {
  background-color: white;
  width: 100vw;
  height: 100vh;
}

.pdf-viewer-container:fullscreen .pdf-viewer {
  max-width: 900px;
  margin: 0 auto;
}

.pdf-viewer-toolbar {
  position: sticky;
  top: 0px;
  background: white;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.pdf-viewer-toolbar-actions {
  display: flex;
  align-items: center;
  padding: 8px 15px 4px 15px;
}
.pdf-viewer-toolbar-actions div{
  flex: 1;
}
.pdf-viewer-btn{
  all: unset;
  width: 30px;
  height: 30px;
  padding: 5px !important;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
}
.pdf-viewer-btn:hover{
  background-color: #ededed;
}
.pdf-viewer-progress {
  height: 6px;
  width: 10px;
  border-radius: 0 5px 5px 0;
  background: #4b60eb;
  transition: width 0.1s;
}

.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #f8fafc;
}
.pdf-viewer .pdf-page {
  transition: width 0.2s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.pdf-viewer .pdf-page:first-child {
  margin-top: 20px;
}
.pdf-viewer .pdf-page:last-child {
  margin-bottom: 20px;
}

.pdf-shimmer {
    position: relative;
    overflow: hidden;
    background-color: #f6f7f8;
    background-image: linear-gradient(90deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-size: 200% 100%;
    animation: pdf-shimmer 1.5s infinite;
}

@keyframes pdf-shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
</style>