<template>
    <iframe
        :srcdoc="readableContent"
        :src="src"
    ></iframe>
</template>
<script>
    export default {
        props: ['src'],
        created () {
            this.setReadableWebsiteContent();
        },
        data () {
            return {
                mainReadableContent: "",
                resultSet: {},
            };
        },
        methods: {
            setReadableWebsiteContent(){
                if(this.resultSet[this.src]){
                    this.mainReadableContent = this.resultSet[this.src];
                }else{
                    this. mainReadableContent = `
                        <div style="width: 100%; height: 95vh; background: #ffffff7a; display: flex; align-items: center; justify-content: center;">
                            <img
                                src="https://asset.storyscale.com/Ripple-1.7s-200px.gif"
                                style="width: 80px; height: 80px;"
                                alt="Loading..."
                                class="loading-key"
                            />
                        </div>
                    `;

                    this.getReadableArticleHtml(this.src).then((html) => {
                        this.resultSet[this.src] = html;
                        this.mainReadableContent = html;
                    });
                }
            }
        },
        watch: {
            src: function(){
                this.setReadableWebsiteContent();
            }
        },
        computed: {
            readableContent: function (){
                return this.mainReadableContent;
            }
        }
    }
</script>
