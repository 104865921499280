export default class GuideDrawer {
    constructor(options = {}) {
        this.doc = options.doc || document;
    }

    createGuideElement(options = {}) {
        const guideElement = this.doc.createElement('div');

        try {
            const { width, height, x, y, } = this.calculatePosition({
                getPositionProps() {
                    return options.getPositionProps();
                },
                getContainer() {
                    return options.getContainer();
                }
            });

            guideElement.className = options.className == undefined ? 'storyscale-ghost-guide' : options.className
            guideElement.id = options.elementId;
            guideElement.style.position = 'absolute'; // TODO
            guideElement.style.top = y + 'px';
            guideElement.style.left = x + 'px';
            guideElement.style.width = width + 'px';
            guideElement.style.height = height + 'px';

            this.doc.body.appendChild(guideElement);

            this.createResizeObserver(guideElement, {
                getPositionProps() {
                    return options.getPositionProps();
                },
                getContainer() {
                    return options.getContainer();
                }
            });
        } catch (error) {
            console.log(error);
        }

        return guideElement;
    }

    calculatePosition(options) {
        const { width: containerWidth, height: containerHeight } = options.getContainer().getBoundingClientRect();

        const props = options.getPositionProps();

        const newX = (props.elementX * containerWidth) / props.containerWidth;
        const newY = (props.elementY * containerHeight) / props.containerHeight;
        const newWidth = (props.elementWidth * containerWidth) / props.containerWidth;
        const newHeight = (props.elementHeight * containerHeight) / props.containerHeight;

        return {
            width: newWidth,
            height: newHeight,
            x: newX,
            y: newY,
        }
    }

    onContainerResize(options) {
        try {
            const { width, height, x, y, } = this.calculatePosition({
                getPositionProps() {
                    return options.getPositionProps();
                },
                getContainer() {
                    return options.getContainer();
                }
            });

            options.element.style.width = width + 'px';
            options.element.style.height = height + 'px';
            options.element.style.left = x + 'px';
            options.element.style.top = y + 'px';
        } catch (error) {
            console.log(error);
        }
    }

    createResizeObserver(element, options = {}) {
        const containerResizeObserver = new ResizeObserver(() => {
            window.requestIdleCallback(() => {
                this.onContainerResize({
                    element,
                    getPositionProps() {
                        return options.getPositionProps();
                    },
                    getContainer() {
                        return options.getContainer();
                    }
                })
            })
        });

        containerResizeObserver.observe(options.getContainer());

        element.resizeObserverCleanup = () => {
            containerResizeObserver && containerResizeObserver.disconnect();
        }
    }
}