function convertHtmlToVariableString(html) {
  const parser = new DOMParser();

  const doc = parser.parseFromString(html, 'text/html');

  const variableTags = [...doc.body.querySelectorAll("[data-original-variable]")];

  variableTags.forEach(tag => {
    const variableName = tag.getAttribute("data-original-variable");
    const variableType = tag.getAttribute("data-variable-type");
    let newNode = null

    if (variableType === 'image') {
      newNode = document.createElement('img');
      let imageDimensions = tag.getAttribute("style");

      newNode.setAttribute('src', variableName);
      newNode.setAttribute('style', imageDimensions);
    } else {
      newNode = document.createTextNode(variableName);
    }

    tag.replaceWith(newNode);
  });

  return doc.body.innerHTML;
}

export function shouldRenderVariables(content){
  if(!content) return false;
  
  return !content.match(/{{(.*?)}}/gi) ? false : true;
}

export function renderVariables({ content = "", variables }) {
  const getVariableValue = !(typeof variables === "function")
      ? (key) => (variables && variables[key]) || ""
      : (key) => variables()[key] || "";

  content = convertHtmlToVariableString(content);

  const result = content.replace(/{{(.*?)}}/gi, (match, key) => {
    let value = "";

    try { 
      let valueFromMap = getVariableValue(key?.toLowerCase()) || ""; 

      if (typeof valueFromMap === "function") {
        value = valueFromMap()
      } else {
        value = valueFromMap
      }
    } catch (error) { 
      console.log(error) 
    }

    return value;
  });

  let finalResult = result;

  try {
    finalResult = result.replace(/&nbsp;/g, ' ');
  } catch (error) {
    console.log(error);
  }

  return finalResult;
}

export function generateVariableMap(variablesList) {
  const variableMap = {}

  variablesList.map(variable => {
      if (variable.type === "datetime") {
          variableMap[variable.name] = variable.rule
      } else {
        variableMap[variable.name] = variable.default_value === "" ? variable.label : variable.default_value
      }
  })

  return variableMap;
}