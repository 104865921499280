<template>
<!--    <div style="width: 100%; display: flex;-->
<!--        align-items: center;-->
<!--        justify-content: center;"-->
<!--    >-->
    <div style="position: fixed; bottom: 50px; right: 50px; z-index: 1040;" v-if="show"
    >
        <div
            style="position: absolute;
            right: 5px;
            top: -23px;"
        >
            <a href="#"
                style="font-size: 40px;
                width: 100%;
                font-weight: 600;"
               @click="show = false"
            >&times;</a>
        </div>
        <div>
            <a :href="$props.obj.badge_url ? $props.obj.badge_url : '#'" target="_blank" style="width: 100%;" v-if="$props.obj.open_in_new_tab"><br>
                <img :src="$props.obj.badge_asset_id.asset_url" style="width: 336px; height: 280px;" alt="" />
            </a>
            <a :href="$props.obj.badge_url ? $props.obj.badge_url : '#'" style="width: 100%;" v-if="!$props.obj.open_in_new_tab"><br>
                <img :src="$props.obj.badge_asset_id.asset_url" style="width: 336px; height: 280px;" alt="" />
            </a>
        </div>
    </div>
</template>
<script>
    import AnalyticsTracking from "@/analytics";
    import BigPicture from "@/big-picture";

    export default {
        props: ['obj'],
        data(){
            return {
                analyticsVisitorCaller: null,
                analyticsAssetViewCaller: null,
                analyticsTimerCaller: null,
                isAnalyticsTimerStarted: false,
                show: true,
            }
        },
        created(){
            // Analytics
            this.trackData = this.initAnalytics();

            // BigPicture
            if(this.trackData){
                BigPicture.details((customerData) => {
                    this.customerData = customerData;
                });
            }

            this.analyticsVisitorCaller = this.singleTonFunction();
            this.analyticsTimerCaller = this.singleTonFunction();
            this.analyticsAssetViewCaller = this.singleTonFunction();
        },
        mounted(){
            this.addVisibilityListener()
        },
        destroyed(){
            this.removeEventListener();
        },
        methods: {
            addVisibilityListener(){
                document.addEventListener('scroll', this.isInViewPortListener, {passive: true});
            },
            removeEventListener(){
                document.removeEventListener('scroll', this.isInViewPortListener, {passive: true});
            },
            isInViewport(el) {
                const rect = el.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },
            isInViewPortListener(){
                if(this.isInViewport(this.$refs.badgeExperience)){
                    this.badgeIsVisible();
                }else{
                    this.badgeIsNotVisible();
                }
            },
            badgeIsVisible(){
                this.analyticsVisitorCaller(() => {
                    this.trackData?.addVistorEvent(this.analyticsData());
                })

                this.analyticsAssetViewCaller(() => {
                    this.trackData?.addAssetViewEvent(this.analyticsData());
                })

                this.analyticsTimerCaller(() => {
                    this.trackData.startTimer();
                    this.isAnalyticsTimerStarted = true;
                })
            },
            badgeIsNotVisible(){
                this.analyticsVisitorCaller.reset();

                this.analyticsAssetViewCaller.reset();
                this.trackData?.cancelAssetViewEvent()

                if(this.isAnalyticsTimerStarted){
                    this.isAnalyticsTimerStarted = false;
                    this.trackData?.addTotalTimeEvent(this.analyticsData());
                }
                this.analyticsTimerCaller.reset();
            },
            // Analytics Functions
            initAnalytics(){
              return new AnalyticsTracking({
                    manageTimer: true,
                    throttleVisitor: true,
                    platform_customer_id: this.obj.platform_customer_id,
              });
            },
            analyticsData() {
                return {
                    experience:{
                        id: parseInt(this.obj.id),
                        name: this.obj.experience_name,
                        experience_type: this.obj.experience_type,
                        delivery_type: this.obj.delivery_type || "share"
                    },
                    asset: {
                        id: parseInt(this.obj.badge_asset_id.id),
                        name: this.obj.badge_asset_id.asset_friendly_name,
                    },
                    customer: this.customerData
                }
            },
            // Analytics Functions
            singleTonFunction(){
                let isCalled = false;

                function single(callback){
                    if(!isCalled){
                        callback();
                    }
                    isCalled = true;
                }

                single.reset = () => {
                    isCalled = false
                }

                return single;
            },
            linkClicked(){
                if(this.$props.obj.badge_url){
                    window.open(this.$props.obj.badge_url)
                    this.trackData?.addConversionEvent(this.analyticsData());
                }
            },
        }
    }
</script>
