<template>
    <div id="hub-container" >
        <vue-progress-bar />
        <div style="width: 100%; height: 100%; background: #ffffff7a; position: fixed; display: flex; align-items: center; justify-content: center;" v-show="loading">
            <img
                src="https://asset.storyscale.com/Ripple-1.7s-200px.gif"
                style="width: 80px; height: 80px;"
                alt="Loading..."
                class="loading-key"
            />
        </div>
        <TwoColumnLayout v-if="dataObj && dataObj.hub_layout === 'two-column'" :obj="dataObj" />
        <ThreeColumnLayout v-if="dataObj && dataObj.hub_layout === 'three-column'" :obj="dataObj" />
    </div>
</template>

<script>
    import TwoColumnLayout from './components/two-column-layout';
    import ThreeColumnLayout from './components/three-column-layout';

    export default {
        components: {
            TwoColumnLayout,
            ThreeColumnLayout,
        },
        created () {
            if ( this.init ) {
                this.getExperienceDetails( localStorage[ 'token' ] );
            }
            // setTimeout(() => {
            //
            //     this.threeColumnDynamicLoad();
            // }, 2000)
        },
        data () {
            return {
                loading: false,
                dataObj: null,
                init: true,
            };
        },
        methods: {
            getExperienceDetails ( token ) {
                this.init = true;
                this.$Progress.start();
                this.loading = true;
                this.axios.get(`frontend/view-experience?encoded_input=${token}`)
                    .then((response) => {
                        const { data } = response.data;
                        this.dataObj = {
                            ...data.experience,
                            display_pages: data.display_pages,
                            nudge_cta_id: data.nudge_details,
                            paywall_cta_id: data.paywall_cta,
                            tracking_integrations: data.tracking_integrations,
                            user: data.user,
                            primary_sequence: null,
                            sequences: [],
                        };
                        data.sequences.map( ( sq ) => {
                            if ( sq.pivot.is_primary || sq.pivot.is_primary === 1 ) {
                                this.dataObj.primary_sequence = { ...sq };
                            } else {
                                this.dataObj.sequences.push( { ...sq } );
                            }
                            return sq;
                        } );
                        this.init = false;

                        this.$Progress.finish();

                        this.setupStyleGuide();

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log( "error", error );
                        this.loading = false;
                        const {message} = error.response.data;
                        console.log(message);
                        this.$Progress.fail();
                    })
            },
            injectCSS(code = "", id = null){
                return new Promise(function (resolve, reject) {
                    function appendStyleTag(code = "", id = null){
                        const styleTag = document.createElement('style');

                        styleTag.innerText = code;

                        if(id) styleTag.id = id;

                        document.head.append(styleTag);

                        return styleTag;
                    }

                    let styleTag = null;

                    const cssCode = String(code);

                    try{
                        if(id){
                            styleTag = document.getElementById(id);

                            if(styleTag && styleTag.tagName == "STYLE") {
                                styleTag.innerText = cssCode;
                            }else{
                                styleTag = appendStyleTag(cssCode, id)
                            }
                        }else{
                            styleTag = appendStyleTag(cssCode)
                        }

                        resolve(styleTag);
                    }catch(err){
                        reject(err)
                    }
                });
            },
            setupStyleGuide(){
                const styleGuideItem = this.dataObj.hub_style_guide;

                let cssText = "";

                if(styleGuideItem && styleGuideItem.id){
                    if(this.dataObj.hub_layout == "two-column"){
                        cssText = styleGuideItem.two_column_layout_css
                    }
                    if(this.dataObj.hub_layout == "three-column"){
                        cssText = styleGuideItem.three_column_layout_css
                    }
                }

                this.injectCSS(cssText, `storyscale-hub-6567-${this.dataObj.id}`)
            },
        },
    }

</script>
