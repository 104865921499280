<template>
  <div class="widget_panel" >
    <vue-progress-bar />
    <lightViewer v-if="tokenData && typeData && typeData === 'viewer' && dataObj && show" :obj="dataObj" :depTyp="`embed`" />
    <spotlightLight v-if="tokenData && typeData && typeData === 'spotlight' && dataObj && show" :obj="dataObj" :depTyp="`embed`" />
    <Badge v-if="tokenData && typeData && typeData === 'badge' && dataObj && show" :obj="dataObj" />
    <RecommendLight v-if="tokenData && typeData && typeData === 'recommend' && dataObj && show" :obj="dataObj" />
    <global-web-tag v-if="$props.webtag && $props.webtag === 'global'" :webtag="$props.webtag" :ptkn="$props.ptkn" :dtp="$props.dtp" />

    <global-share v-if="getParameterByName('clfn') && getParameterByName('rptn')" :clfn="getParameterByName('clfn')" :rptn="getParameterByName('rptn')" />

    <!-- Deep Linking -->
    <!-- <global-share v-else/> -->
    <!-- Deep Linking -->
  </div>
</template>
<script>
  import lightViewer from "./components/viewer/light.vue";
  import spotlightLight from "./components/spotlight/spotlightLight";
  import globalWebTag from "./components/globalWebTag";
  import globalShare from "./components/globalShare";
  import Badge from "./components/badge/badge";
  import RecommendLight from "./components/recommend/recommendLight";

  var _idleSecondsTimer = null;
  var _idleSecondsCounter = 0;

  export default {
    props: [ 'type', 'token', 'webtag', 'ptkn', 'dtp' ],
    created () {
      if ( this.token ) {
        this.getExperienceDetails( this.token );
      }
      // else if ( this.getParameterByName('clfn') && this.getParameterByName('rptn') ) {
      //   this.typeData = this.getParameterByName('clfn');
      //   this.tokenData = this.getParameterByName('rptn');
      //   this.getExperienceDetails( this.tokenData );
      // }
      // let recaptchaScript = document.createElement('script');
      // recaptchaScript.setAttribute('src', 'https://embed.typeform.com/embed.js');
      // document.head.appendChild(recaptchaScript)
      // console.log(document.location);
      // console.log(navigator);
    },
    components: {
      lightViewer,
      spotlightLight,
      globalWebTag,
      globalShare,
      Badge,
      RecommendLight,
    },
    data () {
      return {
        dataObj: null,
        show: false,
        tokenData: this.token,
        typeData: this.type,

      };
    },
    updated() {
      // if ( this.dataObj.experience_display_trigger === 'exitintent' ) {
      //   var addEvent = function (obj, evt, fn) {
      //     if (obj.addEventListener) {
      //       obj.addEventListener(evt, fn, false);
      //     } else if (obj.attachEvent) {
      //       obj.attachEvent("on" + evt, fn);
      //     }
      //   };
      //
      //   addEvent(window, "mouseout", (event) => {
      //     event = event ? event : window.event;
      //     var from = event.relatedTarget || event.toElement;
      //     if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
      //       this.showTrue();
      //     }
      //   });
      // } else if ( this.dataObj.experience_display_trigger === 'scrollto' ) {
      //   window.addEventListener('scroll', () => {
      //     let scrollTop = window.scrollY;
      //     let docHeight = document.body.offsetHeight;
      //     let winHeight = window.innerHeight;
      //     let scrollPercent = scrollTop / (docHeight - winHeight);
      //     let scrollPercentRounded = Math.round(scrollPercent * 100);
      //     if ( Number(this.dataObj.experience_display_trigger_idlescroll) <= Number(scrollPercentRounded) ) {
      //       this.showTrue();
      //     }
      //   });
      // } else if ( this.dataObj.experience_display_trigger === 'idleduration' ) {
      //   document.onclick = () => {
      //     _idleSecondsCounter = 0;
      //   };
      //   document.onscroll = () => {
      //     _idleSecondsCounter = 0;
      //   };
      //
      //   document.onmousemove = () => {
      //     _idleSecondsCounter = 0;
      //   };
      //
      //   document.onkeypress = () => {
      //     _idleSecondsCounter = 0;
      //   };
      //   _idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
      // } else {
        this.showTrue();
      // }
    },
    methods: {
      CheckIdleTime() {
        _idleSecondsCounter++;
        if ( Number(_idleSecondsCounter) >= Number(this.dataObj.experience_display_trigger_idleduration) ) {
          this.showTrue();
          window.clearInterval(_idleSecondsTimer);
        }
      },
      showTrue() {
        this.show = true;
      },
      getExperienceDetails ( token ) {
        this.$Progress.start();
        this.axios.get( `frontend/view-experience?encoded_input=${ token }` )
        .then( ( response ) => {
          const { data } = response.data;
          if ( this.type ) {
            const exp = data.experience;
            this.dataObj = {
              ...exp,
              display_pages: [],
              paywall_cta_id: data.paywall_cta,
              primary_cta_id: data.primary_cta,
              nudge_cta_id: data.nudge_details,
              style_guide_id: data.style_guide,
              tracking_integrations: data.tracking_integrations,
              primary_sequence: null,
              sequences: [],
              keen_data: data.user.keen_data,
            };
            if ( this.dtp ) {
              this.dataObj.delivery_type = this.dtp;
            }
            if ( data.display_pages.length > 0 ) {
              data.display_pages.map( ( link ) => {
                this.dataObj.display_pages.push( link.page_url );
                return link;
              } );
            }
            this.dataObj.visitor_scroll_before_interaction = String( this.dataObj.visitor_scroll_before_interaction );
            this.dataObj.visitor_wait_before_interaction = String( this.dataObj.visitor_wait_before_interaction );
            if ( data.sequences ) {
              data.sequences.map((sq) => {
                // sq.now = false;
                if (sq.pivot.is_primary === 1) {
                  // sq.now = true;
                  this.dataObj.primary_sequence = {...sq};
                } else {
                  this.dataObj.sequences.push({...sq});
                }
                return sq;
              });
            }
            switch ( this.type ) {
              case 'viewer':
                this.dataObj.user = data.user;
                break;
              case 'spotlight':
                if ( data.experience.launch_with_splash ) {
                  this.dataObj.splash_asset_id = data.splash_image;
                } else {
                  this.dataObj.splash_asset_id = null;
                }
                break;
              case 'badge':
                this.dataObj.badge_asset_id = data.badge_asset;
                this.dataObj.badge_url = data.badge_url;
                break;
              case 'recommend':
                this.dataObj.recommend_experience = data.recommend_experience ? data.recommend_experience : null;
                this.dataObj.recommend_experience_links = data.recommend_experience_links.length > 0 ? data.recommend_experience_links : [];
                this.dataObj.recommend_type = data.recommend_type;
                break;
              case 'blank':
                break;
              default:
                console.log( "Invalid Type" );
            }
            if ( this.dataObj.tracking_integrations.integrate_google_tag_manager ) {
              this.integrateTagManager( this.dataObj.tracking_integrations.google_tag_manager_id );
            }
          }
          this.$Progress.finish();
        } )
        .catch( ( error ) => {
          console.log(error)
          const { message } = error.response.data;
          console.log( message );
          this.$Progress.fail();
        } )
      },
    }
  }
</script>

<style>
  /*@import "assets/css/all.css";*/
  /*@import "assets/css/all.min.css";*/
  @import "assets/css/style.css";
  @import "assets/css/style_viewer.css";
  @import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css";
  /*@import "./assets/bootstrap/scss/bootstrap.css";*/

  .modal-header:after {
    background: transparent !important;
  }
</style>
