<template>
    <transition name="welcome">
        <div class="storyscale-welcome-screen" :class="`${theme}`">
            <svg @click="$emit('onStart')" xmlns="http://www.w3.org/2000/svg" width="27%" height="27%" viewBox="0 0 24 24"><path fill="#334155" d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475t-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712" /></svg>
        </div>
    </transition>
</template>
<script>
export default {
  name: "WelcomeScreen",
  props: {
    theme: {
      type: String,
      default: "light"
    }
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.storyscale-welcome-screen {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: #e8ebf5a8;
  z-index: 2147482647;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.storyscale-welcome-screen svg{
    animation: welcome-icon-enter .5s;
}
.storyscale-welcome-screen svg path {
    cursor: pointer;
    transform-origin: center;
    transition: .3s;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.storyscale-welcome-screen svg path:hover{
    transform: scale(1.1);
}
.storyscale-welcome-screen svg path:active{
    transform: scale(.9);
}

.storyscale-welcome-screen.light{
  background: #e8ebf5a8;
}
.storyscale-welcome-screen.light > svg path{
  fill: #334155;
}

.storyscale-welcome-screen.dark{
  background: #334155bd;
}
.storyscale-welcome-screen.dark > svg path{
  fill: #e8ebf5;
}

@keyframes welcome-icon-enter {
    from { transform: scale(.8);}    
    to { transform: scale(1); }    
}

.welcome-enter-active,
.welcome-leave-active {
  transition: opacity 0.1s ease;
}

.welcome-enter-from,
.welcome-leave-to {
  opacity: 0;
}
</style>
