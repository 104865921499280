import 'requestidlecallback-polyfill';
import panzoom from "panzoom";
import { getBoundingClientRectPadded, progress } from "@/utils";

const DEFAULT_LOOKAT_PARAMS = { duration: 1000, padding: 50 };

export default class PreviewWidget {
    constructor(options) {
        const { element } = options;

        this.element = element;

        this.setup();
    }

    setup() {
        this.panzoomInstance = panzoom(this.element, {
            maxZoom: 2,
            minZoom: 0.1,
            smoothScroll: false,
            // bounds: true,
            // boundsPadding: 0.2,
            initialZoom: 0,
            beforeWheel(event) {
                return false;
            },
            beforeMouseDown(event) {
                return !event.ctrlKey;
            },  
            onTouch() {
                return false;
            },
            onDoubleClick() {
                return false;
            },
        });

        this.prevPosition = { x: 0, y: 0, scale: 0 }

        window.previewWidget = this;
        window.panzoomInstance = this.panzoomInstance;
    }

    setInitialView(element) {
        this.panzoomInstance.showRectangle(getBoundingClientRectPadded(element, 500));

        this.prevPosition = { ...this.panzoomInstance.getTransform() };

        this.panzoomInstance.moveTo(this.prevPosition.x, this.prevPosition.y);
        this.panzoomInstance.zoomAbs(0, 0, this.prevPosition.scale);

        this.lookAt(element, { duration: 500, padding: 40 });
    }

    setPosition({ x, y }) {
        if (this.panzoomInstance) {
            this.panzoomInstance.smoothMoveTo(x, y);
        }
    }

    lookAt(element, options = DEFAULT_LOOKAT_PARAMS) {
        const self = this;
        const {
            duration,
            padding,
        } = options;

        const ANIMATION_DURATION = duration;

        self.panzoomInstance.showRectangle(getBoundingClientRectPadded(element, padding));

        const { x, y, scale } = self.panzoomInstance.getTransform();

        let prevX = self.prevPosition.x;
        let prevY = self.prevPosition.y;
        let prevScale = self.prevPosition.scale;

        self.prevPosition = { x, y, scale };

        self.panzoomInstance.moveTo(prevX, prevY);
        self.panzoomInstance.zoomAbs(0, 0, prevScale);

        function updatePosition() {
            self.panzoomInstance.moveTo(prevX, prevY);
            self.panzoomInstance.zoomAbs(0, 0, prevScale);
        }

        if (duration === 0) {
            self.panzoomInstance.moveTo(x, y);
            self.panzoomInstance.zoomAbs(0, 0, scale);

            return;
        }

        progress(prevScale, scale, ANIMATION_DURATION, (value) => {
            prevScale = value;
            requestIdleCallback(updatePosition);
        });

        progress(prevX, x, ANIMATION_DURATION, (value) => {
            prevX = value;
            requestIdleCallback(updatePosition);
        });

        progress(prevY, y, ANIMATION_DURATION, (value) => {
            prevY = value;
            requestIdleCallback(updatePosition);
        });
    }

    moveTo(x, y, zoom){
        this.panzoomInstance.zoomAbs(0, 0, zoom);
        this.panzoomInstance.moveTo(x, y);
    }

    smoothMoveTo(x, y, zoom, options = {}) {
        const self = this;

        const {
            duration = 1500,
        } = options;

        const ANIMATION_DURATION = duration;

        let { x: prevX, y: prevY, scale: prevScale } = self.panzoomInstance.getTransform();

        function updatePosition() {
            self.panzoomInstance.zoomAbs(0, 0, prevScale);
            self.panzoomInstance.moveTo(prevX, prevY);
        }

        progress(prevScale, zoom, ANIMATION_DURATION, (value) => {
            prevScale = value;
            requestIdleCallback(updatePosition);
        });

        progress(prevX, x, ANIMATION_DURATION, (value) => {
            prevX = value;
            requestIdleCallback(updatePosition);
        });

        progress(prevY, y, ANIMATION_DURATION, (value) => {
            prevY = value;
            requestIdleCallback(updatePosition);
        });
    }
}