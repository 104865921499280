<template>
    <div v-if="showRec">
        <div class="storyscale_widget-holder" style="z-index: 111;">
            <div style="display: flex; width: 100%;">
                <h3
                    class="storyscale_heading-3"
                    style="width: 95%;"
                    :style="[
                        dataObj.style_guide_id ?
                        {
                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                        } : {}
                    ]"
                >
                    Recommended Content
                </h3>
                <a href="#"
                    :style="[
                        dataObj.style_guide_id ?
                        {
                            fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                        } : {}
                    ]"
                    style="font-size: 19px; text-decoration: none; outline: none;
                    margin-top: -4px; color: #858586;
                    font-weight: 600;"
                    @click="showRec = false"
                >&times;</a>
            </div>


            <div v-if="dataObj.recommend_type === 'experience'">
                <div class="storyscale_each-list-holder" v-for="( a, i ) in sequence.assets"  :key="i">
                    <div class="storyscale_img-holder" style="max-height: 56px !important; height: 56px !important; width: 133px; max-width: 133px;">
                        <img class="storyscale_cstm-img-fluid" :src="a.cover_image_url" alt="image" style="height: 100% !important;">
                    </div>
                    <h4
                        style="width: 100% !important; text-align: left !important; padding-left: 10px;"
                        :style="[
                            dataObj.style_guide_id ?
                            {
                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                            } : {}
                        ]"
                    >{{ a.asset_friendly_name }}</h4>
                    <div>
                        <a href="#" class="storyscale_widget-border-icon" @click="showModal( a )">
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293124 9.70749C-0.0975278 9.3173 -0.0977343 8.68446 0.292663 8.29401L4.29266 4.29349C4.68306 3.90304 5.31622 3.90284 5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651L1.70734 9.70703C1.31694 10.0975 0.683775 10.0977 0.293124 9.70749Z" :fill="dataObj.style_guide_id.primary_accent_color" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293123 0.29251C0.683775 -0.097683 1.31694 -0.0974761 1.70734 0.292972L5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651C5.31669 6.0967 4.68306 6.09696 4.29266 5.70651L0.292662 1.70599C-0.0977346 1.31554 -0.0975282 0.682704 0.293123 0.29251Z" :fill="dataObj.style_guide_id.primary_accent_color" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>


            <div v-if="dataObj.recommend_type === 'links'">
                <div class="storyscale_each-list-holder" v-for="( a, i ) in link_assets"  :key="i">
                    <div class="storyscale_img-holder" style="max-height: 56px !important; height: 56px !important; width: 133px; max-width: 133px;">
                        <img class="storyscale_cstm-img-fluid" :src="a.cover_image_url" alt="image" style="height: 100% !important;">
                    </div>
                    <h4
                        style="width: 100% !important; text-align: left !important; padding-left: 10px;"
                        :style="[
                            dataObj.style_guide_id ?
                            {
                                fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                            } : {}
                        ]"
                    >{{ a.asset_friendly_name }}</h4>
                    <div>
                        <a :href="a.link" @click="(event) => linkClicked(event, a.link, a)" class="storyscale_widget-border-icon" target="_blank">
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293124 9.70749C-0.0975278 9.3173 -0.0977343 8.68446 0.292663 8.29401L4.29266 4.29349C4.68306 3.90304 5.31622 3.90284 5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651L1.70734 9.70703C1.31694 10.0975 0.683775 10.0977 0.293124 9.70749Z" :fill="dataObj.style_guide_id.primary_accent_color" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.293123 0.29251C0.683775 -0.097683 1.31694 -0.0974761 1.70734 0.292972L5.70688 4.29303C6.09753 4.68322 6.09773 5.31606 5.70734 5.70651C5.31669 6.0967 4.68306 6.09696 4.29266 5.70651L0.292662 1.70599C-0.0977346 1.31554 -0.0975282 0.682704 0.293123 0.29251Z" :fill="dataObj.style_guide_id.primary_accent_color" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>


            <a
                :href="dataObj.primary_cta_id.cta_links_to"
                class="storyscale_ss_btn storyscale_explore-btn"
                v-if="dataObj.primary_cta_id"
                target="_blank"
                style="text-decoration: none;"
                :style="[
                    dataObj.style_guide_id ?
                    {
                        fontFamily: dataObj.style_guide_id.font.toLocaleString(),
                        color: dataObj.style_guide_id.primary_accent_color,
                    } : {}
                ]"
            >
                {{ dataObj.primary_cta_id.text }}
            </a>
        </div>

        <div v-if="dataObj.recommend_type === 'experience'">
            <lightViewer
                v-if="experience && experience.experience_type === 'viewer' && passObject && show"
                :obj="passObject"
                :showAssetId="currentAssetId"
                :showSequenceId="sequence.id"
                @onClose="closeModal"
            />

            <spotlightLight
                v-else-if="experience && experience.experience_type === 'spotlight' && passObject && show"
                :obj="passObject"
                :showAssetId="currentAssetId"
                :showSequenceId="sequence.id"
                @onClose="closeModal"
            />
        </div>
    </div>
</template>
<script>

    import lightViewer from "../viewer/light";
    import spotlightLight from "../spotlight/spotlightLight";
    import WebFont from "webfontloader";
    import AnalyticsTracking from "@/analytics";
    import BigPicture from "@/big-picture";

    export default {
        props: [ 'obj' ],
        components: {
            lightViewer,
            spotlightLight,
        },
        created () {
            WebFont.load({
                google: {
                    families: [ this.dataObj.style_guide_id.font ]
                }
            });

            if ( this.dataObj.recommend_type === 'experience' && this.dataObj.recommend_experience ) {
                this.experience = this.dataObj.recommend_experience;
                this.sequence = this.dataObj.recommend_experience.sequence;
                this.platform_token = this.dataObj.recommend_experience.platform_token;
                this.getExperienceDetails();
            }

            if ( this.dataObj.recommend_type === 'links' && this.dataObj.recommend_experience_links ) {
                this.dataObj.recommend_experience_links.map((l) => {
                    this.link_assets.push({
                        asset_friendly_name: l.asset.asset_friendly_name,
                        cover_image_url: l.asset.cover_image_url,
                        link: this.validURL( l.link ) ? l.link : `http://${ l.link }`,
                    });
                    return l;
                });
            }

            // Analytics
            this.trackData = this.initAnalytics();

            // BigPicture
            if(this.trackData){
                BigPicture.details((customerData) => {
                    this.customerData = customerData;
                    this.trackData?.initialEvents(this.analyticsData())
                    this.trackData?.addVistorEvent(this.analyticsData());
                    window.onbeforeunload = () => {
                        this.trackData?.onClose(this.analyticsData());
                    }
                });
            }
        },
        data () {
            return {
                dataObj: this.$props.obj,
                sequence: null,
                experience: null,
                link_assets: [],
                platform_token: null,
                passObject: {},
                currentAssetId: null,
                show: false,
                showRec: true,
                // Analytics
                trackData: null,
                customerData: {},
                // Analytics
            };
        },
        methods: {
            validURL ( link ) {
                let url;
                try {
                    url = new URL( link );
                } catch ( e ) {
                    return false;
                }
                return url.protocol === "http:" || url.protocol === "https:";
            },
            getExperienceDetails () {
                this.loading = true;
                this.$Progress.start();
                this.axios.get( `frontend/view-experience?encoded_input=${this.platform_token}` )
                .then( response => {
                    const { data } = response.data;
                    const exp = data.experience;
                    this.passObject = {
                        ...exp,
                        display_pages: [],
                        paywall_cta_id: data.paywall_cta,
                        primary_cta_id: data.primary_cta,
                        nudge_cta_id: data.nudge_details,
                        style_guide_id: data.style_guide,
                        primary_sequence: null,
                        sequences: [],
                        Analytics_data: data.user.Analytics_data,
                    };
                    data.sequences.map( ( sq ) => {
                        if ( sq.pivot.is_primary === 1 ) {
                            this.passObject.primary_sequence = { ...sq };
                        } else {
                            this.passObject.sequences.push( { ...sq } );
                        }
                        return sq;
                    });

                    switch(exp.experience_type){
                        case 'viewer':
                            break;
                        case 'spotlight':
                            if ( data.experience.launch_with_splash ) {
                                this.passObject.splash_asset_id = data.splash_image;
                            } else {
                                this.passObject.splash_asset_id = null;
                            }
                            break;
                    }
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch( ( error ) => {
                    const { message } = error.response.data;
                    this.$Progress.fail();
                    console.log(message);
                })
            },
            showModal(asset){
                this.currentAssetId = asset.id;

                const structure = this.analyticsData();

                this.trackData?.addAssetViewEvent({...structure, asset: {
                    id: parseInt(asset.id),
                    name: asset.asset_friendly_name,
                }});

                this.show = true;
            },
            closeModal(){
                setTimeout(() => {
                    this.show = false;
                },100)
            },
            linkClicked(event, link, asset = {}){
                if(link){
                    event.preventDefault();

                    window.open(link)

                    const structure = this.analyticsData();

                    this.trackData?.addConversionEvent({...structure, asset: {
                        id: parseInt(asset.id),
                        name: asset.asset_friendly_name,
                    }});
                }
            },
            // Analytics Functions
            initAnalytics(){
              return new AnalyticsTracking({
                    platform_customer_id: this.dataObj.platform_customer_id,
              });
            },
            analyticsData() {
                return {
                    experience:{
                        id: parseInt(this.dataObj.id),
                        name: this.dataObj.experience_name,
                        experience_type: this.dataObj.experience_type,
                        delivery_type: this.dataObj.delivery_type || "share"
                    },
                    customer: this.customerData
                }
            },
            // Analytics Functions
        },
    }
</script>
