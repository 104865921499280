import BigPicture from 'bigpicture-node';
const bigPicture = BigPicture(process.env.VUE_APP_BIGPICTURE_API_KEY);

async function getCompanyDetails(ip_address) {
    const Ip = bigPicture.Ip;
    try {
        const response = await Ip.find({
            ip: ip_address,
            type: "business"
        });

        return response;        
    } catch (error) {
        console.log(error);
    }
}

// function getDetails(ip_address, callback = () => {}) {
//     if (!ip_address) throw new Error("Ip Address Required")

//     const apiKey = process.env.VUE_APP_BIGPICTURE_API_KEY;
//     if (!apiKey) throw new Error("Invalid Api Key");

//     const headers = new Headers();
//     headers.append('Authorization', 'Bearer ' + apiKey);

//     const url = `https://ip.bigpicture.io/v2/companies/ip?ip=${ip_address}`;

//     fetch(url, {
//         method: 'GET',
//         headers: headers,
//     }).then(response => response.json())
//         .then(data => {
//            callback(data)
//         }).catch(() => callback({}));
// }

function getDetails(ip_address, callback = () => {}) {
    // ip_address = '65.2.88.212'; // ! use this ip_address for testing in local

    if (!ip_address) throw new Error("Ip Address Required")

    const apiKey = process.env.VUE_APP_BIGPICTURE_API_KEY;
    if (!apiKey) throw new Error("Invalid Api Key");

    getCompanyDetails(ip_address).then((data) => {
        callback(data)
    }).catch(() => {
        callback({});
    });
}

function getIp(callback = () => {}) {
    let found = false;

    fetch("https://api.ipify.org?format=json").then(res => res.json()).then((data) => {
        if(data.ip) {
            found = true;
            
            return callback(data.ip);
        }
    }).finally(() => {
        if(!found){
            fetch(`${process.env.VUE_APP_API_URL}ip`)
            .then(response => response.json())
            .then(({ ip }) => callback(ip)).catch(() => callback({}));
        }
    });
}

function details(callback = () => {}) {
    getIp(ip => getDetails(ip, (customerData) => {
        callback(customerData)
    }));
}

function detailsPromise() {
    return new Promise((resolve, reject) => {
        try {
            // resolve({});

            getIp(ip => getDetails(ip, (customerData) => {
                resolve(customerData)
            }));
        } catch (error) {}
    })
}

export default {
    getDetails,
    getIp,
    details,
    detailsPromise
}