export function castStyleValue(key, value) {
    if (key == "letter-spacing") {
        return `${value ? value : 0}px`;
    }

    return value
}

export function convertYoutubeUrlToEmbedUrl(youtubeUrl, params = {}) {
    let videoId = "";

    const regularMatch = youtubeUrl.match(/[?&]v=([^&]+)/);

    if (regularMatch && regularMatch[1]) {
        videoId = regularMatch[1];
    } else {
        const shortMatch = youtubeUrl.match(/youtu\.be\/([^&]+)/);
        
        if (shortMatch && shortMatch[1]) {
            videoId = shortMatch[1];
        } else {
            return null;
        }
    }

    let embedUrl = `https://www.youtube.com/embed/${videoId}`;

    const queryParams = new URLSearchParams();

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            queryParams.append(key, params[key]);
        }
    }

    if (queryParams.toString() !== '') {
        embedUrl += `?${queryParams.toString()}`;
    }

    return embedUrl;
}

export function isYoutubeUrl(url) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)[\w-]{11}/;

    return youtubeRegex.test(url);
}

export function convertLoomUrlToEmbedUrl(shareUrl = "") {
    if(!shareUrl) return "";

    const videoId = shareUrl.split('/').pop();

    return `https://www.loom.com/embed/${videoId}`;
}

export function isLoomUrl(url) {
    const shareUrlPattern = /https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9_-]+)/;

    if (shareUrlPattern.test(url)) {
        return true;
    }

    const embedUrlPattern = /https:\/\/www\.loom\.com\/embed\/([a-zA-Z0-9_-]+)/;

    if (embedUrlPattern.test(url)) {
        return true;
    }

    return false;
}

export function modifyHtmlAsDom(html = "", callback) {
    if (!html) return "";

    const doc = new DOMParser().parseFromString(html, "text/html");

    callback(doc)

    return doc.body.outerHTML;
}

export function generateStyleObject(styleString) {
    const styleArray = styleString.split(";");

    const styleObject = styleArray.reduce((acc, item) => {
        const [property, value] = item.split(":");

        if (property && value) acc[property.trim()] = value.trim();

        return acc;
    }, {});

    return styleObject
}

export function generateStyleString(styleObject) {
    return Object.entries(styleObject).map(([property, value]) => `${property}: ${value}`).join(";")
}

export function increaseStylePriorityInHtml(html) {
    try {
        return modifyHtmlAsDom(html, (doc) => {
            const elementsWithStyle = [...doc.body.querySelectorAll("[style]")];
    
            elementsWithStyle.forEach((element) => {
                const cssText = element.style.cssText;
    
                const styleObj = generateStyleObject(cssText);
    
                Object.entries(styleObj).forEach(([key, value]) => {
                    element.style.setProperty(key, value, "important");
                });
            });
        });
    } catch (error) {
        return html;
    }
}