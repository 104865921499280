<template>
  <span class="typewriter-element" ref="typewriterElement">{{ currentText }} <span class="blink" v-if="isAnimating">|</span></span>
</template>

<script>
export default {
  props: ["text", "onFinish"],
  data() {
    return {
      index: 0,
      currentText: "",
      isAnimating: false,
    };
  },
  mounted() {
    this.isAnimating = true;

    this.type();
  },
  methods: {
    type() {
      if (this.index < this.text.length) {
        this.currentText += this.text[this.index] === "\n" ? "<br/>" : this.text[this.index];

        this.index++;

        setTimeout(() => this.type(), 20);
      } else {
        setTimeout(() => {
          this.isAnimating = false;
        }, 1000);

        this.$emit("onFinish");
      }
    },
  },
};
</script>

<style>
.typewriter-element {
  display: inline-block;
  padding-right: 5px;
}

@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
.blink {
  animation: blink-caret 0.75s step-end infinite;
}
</style>