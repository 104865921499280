import { renderDocument, renderHtml, renderIframe } from './render';
import { generateAST } from './generate';
import toString from './toString';

export default {
    renderDocument,
    renderHtml,
    renderIframe,
    toString,
    generateAST,
}