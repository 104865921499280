export function createCursor(){
    const cursor = document.createElement('div');
    cursor.style.position = 'fixed';
    cursor.style.width = '20px';
    cursor.style.height = '20px';
    cursor.style.pointerEvents = 'none';
    cursor.style.zIndex = '9999';
    cursor.style.visibility = 'hidden';

    cursor.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="48" viewBox="0 0 24 24"><path fill="#a7f3d0" stroke="#000" stroke-width="2" stroke-linejoin="round" d="M18 14.88 8.16 3.15c-.26-.31-.76-.12-.76.28v15.31c0 .36.42.56.7.33l3.1-2.6 1.55 4.25c.08.22.33.34.55.26l1.61-.59a.43.43 0 0 0 .26-.55l-1.55-4.25h4.05c.36 0 .56-.42.33-.7Z"></path></svg>
    `;

    document.body.appendChild(cursor);

    return cursor;
}

export function animateCursor(options) {
    let { start, end, action, cursor, target = null} = options;

    cursor.style.left = `${start.x}px`;
    cursor.style.top = `${start.y}px`;

    const duration = 1500 + Math.random() * 1000;
    const fps = 60;
    const totalFrames = duration / (1000 / fps);
    let currentFrame = 0;

    const numControlPoints = 2 + Math.floor(Math.random() * 2); 
    const controlPoints = [start];

    for (let index = 0; index < numControlPoints; index++) {
        controlPoints.push({
            x: start.x + (Math.random() * 0.8 + 0.1) * (end.x - start.x),
            y: start.y + (Math.random() * 0.8 + 0.1) * (end.y - start.y)
        });
    }

    controlPoints.push(end);

    function animate(resolve) {
        if (currentFrame >= totalFrames) {
            performAction(action);
            resolve();
            return;
        }

        const progress = easeInOutCubic(currentFrame / totalFrames);
        const position = getSplinePoint(controlPoints, progress);

        cursor.style.left = `${position.x}px`;
        cursor.style.top = `${position.y}px`;

        const shake = Math.sin(currentFrame * 0.5) * 0.5;
        cursor.style.transform = `translate(${shake}px, ${shake}px)`;

        currentFrame++;
        requestAnimationFrame(() => animate(resolve));
    }

    function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    function getSplinePoint(points, t) {
        const n = points.length - 1;
        let x = 0;
        let y = 0;

        for (let i = 0; i <= n; i++) {
            const coefficient = binomialCoefficient(n, i) * Math.pow(1 - t, n - i) * Math.pow(t, i);
            x += coefficient * points[i].x;
            y += coefficient * points[i].y;
        }

        return { x, y };
    }

    function binomialCoefficient(n, k) {
        let result = 1;
        for (let i = 1; i <= k; i++) {
            result *= (n - i + 1) / i;
        }
        return result;
    }

    function performAction(action) {
        if(!target) return;

        const clickAnimation = [
            { transform: 'scale(1)', opacity: 1 },
            { transform: 'scale(0.95)', opacity: 0.8 },
            { transform: 'scale(1)', opacity: 1 }
        ];

        const hoverAnimation = [
            { transform: 'scale(1)' },
            { transform: 'scale(1.05)' },
            { transform: 'scale(1)' }
        ];

        const animationTiming = {
            duration: 1000,
            iterations: 2,
            easing: 'ease-in-out'
        };

        if (action === 'click') {
            target.animate(clickAnimation, animationTiming);
        } else if (action === 'hover') {
            target.animate(hoverAnimation, animationTiming); 
        }
    }

    target.scrollIntoView({ behavior: 'smooth', block: 'center' });

    return new Promise((resolve) => {
        animate(resolve);
    });
}

export function getElementScreenCordinate(element) {
    const rect = element.getBoundingClientRect();
    const x = rect.left + window.scrollX;
    const y = rect.top + window.scrollY;
    return { x, y };
}