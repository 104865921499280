<template>
  <section :class="['showpilot_agent_exp', { chat_opened: isChatEnable }]">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
    
    <Loading :loading="loading" solid />

    <div :class="['sp_menubar_right_holder', { open: isOpen }]" class="stagger-appear" style="--stagger-delay: 0.2s">
      <div class="sp_logo_holder">
        <img class="img-fluid large" src="../../../assets/img/SPDarkLogo.png" />
      </div>

      <div :class="['sp_tablet_collapse_holder pointer', {}]" @click="toggleSidebar">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{ transform: `rotate(${!isOpen ? -180 : 0}deg)`, transition: 'transform 0.5s' }">
          <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E5E7EA" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46938 9.96984C8.76203 9.67685 9.23665 9.6767 9.52949 9.9695L12.5299 12.9695C12.8227 13.2623 12.8229 13.7372 12.5302 14.0302C12.2376 14.3231 11.763 14.3233 11.4701 14.0305L8.46973 11.0305C8.17689 10.7377 8.17674 10.2628 8.46938 9.96984Z" fill="#7D8597" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5306 9.96984C15.8233 10.2628 15.8231 10.7377 15.5303 11.0305L12.5302 14.0302C12.2376 14.3231 11.763 14.3233 11.4701 14.0305C11.1775 13.7375 11.1773 13.2623 11.4701 12.9695L14.4705 9.9695C14.7633 9.6767 15.238 9.67685 15.5306 9.96984Z" fill="#7D8597" />
        </svg>
      </div>

      <div class="sp_menu_toggler_holder" @click="toggleSidebar">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03016 2.46938C8.32315 2.76203 8.3233 3.23665 8.0305 3.52949L5.0305 6.52988C4.73771 6.82272 4.26283 6.82287 3.96984 6.53023C3.67685 6.23758 3.6767 5.76296 3.9695 5.47012L6.9695 2.46973C7.26229 2.17689 7.73717 2.17674 8.03016 2.46938Z" fill="#7D8597" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.03016 9.53062C7.73717 9.82326 7.26229 9.82311 6.9695 9.53027L3.96984 6.53023C3.67685 6.23758 3.6767 5.76296 3.9695 5.47012C4.26249 5.17748 4.73771 5.17728 5.0305 5.47012L8.0305 8.47051C8.3233 8.76335 8.32315 9.23797 8.03016 9.53062Z" fill="#7D8597" />
        </svg>
      </div>

      <div class="sp_right_menulist_holder overflow-hidden">
        <div class="sp_menulist_heading_holder">
          <div class="sp_left_holder">
            <h6 class="sp_heading">My playlist</h6>
            <div class="sp_info_holder">
              <span>{{ playlist.length }} topics</span> | <span>18min 10 sec</span>
            </div>
          </div>
          <div class="sp_right_holder">
            <button class="sp_rounded_icon_btn sp_bg_white" @click="() => (prevContent ? selectContent(prevContent.content_id, prevContent.content_type) : null)" :style="{ opacity: prevContent ? 1 : 0.6 }">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7069 3.29251C11.0975 3.6827 11.0977 4.31554 10.7073 4.70599L6.70734 8.70651C6.31694 9.09696 5.68378 9.09716 5.29312 8.70697C4.90247 8.31678 4.90227 7.68394 5.29266 7.29349L9.29266 3.29297C9.68306 2.90252 10.3162 2.90232 10.7069 3.29251Z" fill="#060E2B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7069 12.7075C10.3162 13.0977 9.68306 13.0975 9.29266 12.707L5.29312 8.70697C4.90247 8.31678 4.90227 7.68394 5.29266 7.29349C5.68331 6.9033 6.31694 6.90304 6.70734 7.29349L10.7073 11.294C11.0977 11.6845 11.0975 12.3173 10.7069 12.7075Z" fill="#060E2B" />
              </svg>
            </button>
            <button class="sp_rounded_icon_btn sp_bg_white" @click="() => (nextContent ? selectContent(nextContent.content_id, nextContent.content_type) : null)" :style="{ opacity: nextContent ? 1 : 0.6 }">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 12.7075C4.90247 12.3173 4.90227 11.6845 5.29266 11.294L9.29266 7.29349C9.68306 6.90304 10.3162 6.90284 10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651L6.70734 12.707C6.31694 13.0975 5.68378 13.0977 5.29312 12.7075Z" fill="#060E2B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 3.29251C5.68378 2.90232 6.31694 2.90252 6.70734 3.29297L10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651C10.3167 9.0967 9.68306 9.09696 9.29266 8.70651L5.29266 4.70599C4.90227 4.31554 4.90247 3.6827 5.29312 3.29251Z" fill="#060E2B" />
              </svg>
            </button>
          </div>
        </div>
        <ul class="sp_multilist_holder sp_vertical_scrollbar" ref="scrollContainer">
          <li :class="['sp_each_list animated fadeInRight', { active: currentSelectedPage.type == 'content' && currentSelectedPage.id == content.id }]" v-for="content of playlist" :key="content.id" @click="() => selectContent(content.content_id, content.content_type)" :id="`content-${content.content_id + content.content_type}`">
            <div class="sp_img_holder">
              <img class="img-fluid" :src="content.image" />
            </div>
            <div class="sp_right_info_holder">
              <h6 class="sp_heading">{{ content.name }}</h6>
              <p class="sp_badge_holder">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.84217 4.88569L3.17665 0.322494C2.86882 0.11161 2.55866 0 2.30086 0C1.80245 0 1.49414 0.40001 1.49414 1.06957V10.932C1.49414 11.6008 1.80206 12 2.29931 12C2.5575 12 2.8627 11.8883 3.17121 11.6768L9.83984 7.11372C10.2687 6.81979 10.5062 6.42424 10.5062 5.99947C10.5063 5.57498 10.2715 5.17953 9.84217 4.88569Z" fill="#C55E5E" />
                </svg>
                <span>{{ ucFirst(content.content_extension) }}</span>
              </p>
            </div>
          </li>

          <li class="sp_each_list p-0" v-if="isRecomendationLoading">
            <div class="skeleton-container animated fadeInRight faster">
              <div class="skeleton-image"></div>
              <div class="skeleton-content">
                <div class="skeleton-title"></div>
                <div class="skeleton-text"></div>
                <div class="skeleton-text"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="sp_main_content_holder" style="transition: all 0.2s ease">
      <div class="sp_navbar_header stagger-appear" style="--stagger-delay: 0.5s">
        <div class="sp_left_holder">
          <div class="sp_profile_holder">
            <img src="https://avatars.githubusercontent.com/u/13297792?v=4" class="w-100 rounded-circle" />
            <div class="sp_info_holder" @click="toggleUserInfo">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_261_2434)">
                  <path d="M10.243 1.75704C7.89985 -0.585876 4.10073 -0.586137 1.75729 1.75704C-0.585893 4.10023 -0.585632 7.89935 1.75729 10.2428C4.10047 12.5855 7.89959 12.5857 10.243 10.2428C12.586 7.89935 12.5857 4.10049 10.243 1.75704ZM6.78253 8.60867C6.78253 9.04094 6.43217 9.3913 5.9999 9.3913C5.56763 9.3913 5.21728 9.04094 5.21728 8.60867V5.47817C5.21728 5.0459 5.56763 4.69554 5.9999 4.69554C6.43217 4.69554 6.78253 5.0459 6.78253 5.47817V8.60867ZM5.98607 4.1404C5.53528 4.1404 5.23475 3.82109 5.24415 3.42691C5.23475 3.01368 5.53528 2.70402 5.99521 2.70402C6.45539 2.70402 6.74653 3.01394 6.75618 3.42691C6.75592 3.82109 6.45565 4.1404 5.98607 4.1404Z" fill="#1C45D6" />
                </g>
                <defs>
                  <clipPath id="clip0_261_2434">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div v-if="isOpenUserInfo" class="sp_user_info_popup">
              <h6 class="sp_heading">Sandy Smith</h6>
              <p class="sp_content">National Accounts Manager</p>
              <p class="sp_content">206.993.4567</p>
              <a href="#" class="sp_email_info">ssmith@teradata.com</a>

              <div class="sp_btn_holder">
                <button class="sp_cstm_btn sp_primary_btn sp_rounded_btn">Meet with Me</button>
                <button class="sp_cstm_btn sp_outlined_sec_btn sp_rounded_btn">Get Suggestions</button>
              </div>
            </div>
          </div>
          <div class="sp_chat_outer_holder">
            <input class="sp_chat_input" type="text" placeholder="Ask me anything" @focus="showChatWindow" />
            <button class="sp_chat_btn_holder">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_244_2419)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2687 0.0567257C15.4354 -0.0306076 15.6367 -0.0159409 15.79 0.0927257C15.9434 0.202059 16.022 0.387392 15.9947 0.574059L14.1614 12.9074C14.1394 13.0534 14.0534 13.1834 13.9267 13.2607C13.8474 13.3087 13.7574 13.3334 13.6667 13.3334C13.612 13.3334 13.5574 13.3241 13.5054 13.3067L6.5167 10.9181L13.3887 2.63873L4.50803 10.2321L0.33803 8.80673C0.149363 8.74206 0.0166963 8.57139 0.00136299 8.37139C-0.0133037 8.17206 0.0913631 7.98273 0.268697 7.89006L15.2687 0.0567257ZM5.83331 14.8333V11.7413L8.54465 12.668L6.73598 15.1293C6.63998 15.26 6.48931 15.3333 6.33331 15.3333C6.28131 15.3333 6.22865 15.3253 6.17731 15.3087C5.97198 15.2407 5.83331 15.0493 5.83331 14.8333Z" fill="#060E2B" />
                </g>
                <defs>
                  <clipPath id="clip0_244_2419">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>

            <div class="sp_chatbox_holder" :class="{ invisible: !isChatEnable }">
              <div class="sp_close_holder" @click="hideChatWindow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E5E7EA" />
                  <path d="M15.4631 9.92218C15.8456 9.53966 15.8456 8.91947 15.4631 8.53695C15.0805 8.15443 14.4603 8.15443 14.0778 8.53695L12 10.6147L9.92218 8.53689C9.53966 8.15437 8.91947 8.15437 8.53694 8.53689C8.15442 8.91941 8.15442 9.5396 8.53694 9.92212L10.6148 12L8.53689 14.0779C8.15437 14.4604 8.15437 15.0806 8.53689 15.4631C8.91941 15.8456 9.5396 15.8456 9.92212 15.4631L12 13.3852L14.0779 15.4631C14.4604 15.8456 15.0806 15.8456 15.4631 15.4631C15.8456 15.0805 15.8456 14.4603 15.4631 14.0778L13.3853 12L15.4631 9.92218Z" fill="#7D8597" />
                </svg>
              </div>
              <div class="sp_message_holder sp_vertical_scrollbar" :class="{ 'p-0': !isChatEnable }" :style="{ height: isChatEnable ? '400px' : '100px' }" ref="chatWindow">
                <template v-if="isChatEnable">
                  <div v-for="(messageItem, index) of chatMessages" :key="index" class="d-flex flex-column" :class="{ 'align-items-end': !messageItem.isAiMessage }">
                    <div class="animated" :class="{ 'sp_client_end_msg slideInLeft': messageItem.isAiMessage, 'sp_user_end_msg faster zoomIn': !messageItem.isAiMessage }">
                      <div class="sp_each_msg_holder" :class="{ 'bg-primary text-white': !messageItem.isAiMessage }">{{ messageItem.message }}</div>
                    </div>

                    <div class="sp_file_msg_holder" v-if="messageItem.recomendation" @click="() => addToPlaylist(messageItem.recomendation.content_id, messageItem.recomendation.content_type, { select: true })">
                      <div class="sp_each_msg_holder">
                        <div class="sp_icon_holder">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 1.095C11.0471 1.12292 11.0894 1.15839 11.125 1.2L13.25 4H11V1.095ZM11 5C10.7348 5 10.4804 4.89464 10.2929 4.70711C10.1054 4.51957 10 4.26522 10 4V1H4C3.60218 1 3.22064 1.15804 2.93934 1.43934C2.65804 1.72064 2.5 2.10218 2.5 2.5V13.5C2.5 13.8978 2.65804 14.2794 2.93934 14.5607C3.22064 14.842 3.60218 15 4 15H12C12.3978 15 12.7794 14.842 13.0607 14.5607C13.342 14.2794 13.5 13.8978 13.5 13.5V5H11Z" fill="#F64A91" />
                          </svg>
                        </div>
                        <div class="sp_file_info_holder">
                          <h4 class="sp_heading">{{ messageItem.recomendation.name }}</h4>
                          <p>{{ ucFirst(messageItem.recomendation.content_extension) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sp_outer_suugestion_holder" v-if="false">
                    <div class="sp_inner_suugestion_holder">
                      <h4 class="sp_heading">Suggestions:</h4>
                      <div class="sp_each_msg_holder">Are you SOC II Certified? What's SLA?</div>
                      <div class="sp_each_msg_holder">Are you SOC II Certified? What's SLA?</div>
                    </div>
                    <div class="text-right">
                      <div class="hide_sugg_link">Hide suggestions</div>
                    </div>
                  </div>

                  <div class="sp_client_end_msg" v-if="isChatLoading">
                    <div class="chat-bubble animated faster zoomIn">
                      <div class="typing">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <form class="sp_btm_btn_holder" @submit.prevent="sendChatMessage" v-if="isChatEnable">
                <input class="sp_chat_input" type="text" placeholder="Ask me anything" v-model="chatInput" v-focus />

                <button type="button" class="sp_chat_btn_holder" @click="sendChatMessage">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_244_2419)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2687 0.0567257C15.4354 -0.0306076 15.6367 -0.0159409 15.79 0.0927257C15.9434 0.202059 16.022 0.387392 15.9947 0.574059L14.1614 12.9074C14.1394 13.0534 14.0534 13.1834 13.9267 13.2607C13.8474 13.3087 13.7574 13.3334 13.6667 13.3334C13.612 13.3334 13.5574 13.3241 13.5054 13.3067L6.5167 10.9181L13.3887 2.63873L4.50803 10.2321L0.33803 8.80673C0.149363 8.74206 0.0166963 8.57139 0.00136299 8.37139C-0.0133037 8.17206 0.0913631 7.98273 0.268697 7.89006L15.2687 0.0567257ZM5.83331 14.8333V11.7413L8.54465 12.668L6.73598 15.1293C6.63998 15.26 6.48931 15.3333 6.33331 15.3333C6.28131 15.3333 6.22865 15.3253 6.17731 15.3087C5.97198 15.2407 5.83331 15.0493 5.83331 14.8333Z" fill="#060E2B" />
                    </g>
                    <defs>
                      <clipPath id="clip0_244_2419">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>

        <div class="sp_right_holder">
          <div class="sp_btn_holder">
            <button class="sp_cstm_btn sp_primary_btn sp_rounded_btn">Meet with Me</button>
            <button class="sp_cstm_btn sp_outlined_sec_btn sp_rounded_btn">Get Suggestions</button>
          </div>

          <div class="sp_viewing_holder" v-if="currentSelectedPage.type == 'content'">
            <div>
              <h6 class="sp_small_heading">Now viewing</h6>
              <h4 class="sp_normal_heading">{{ currentSelectedPage.item.name }}</h4>
            </div>
            <div class="d-flex" style="gap: 5px">
              <button class="sp_rounded_icon_btn sp_bg_white" @click="() => (prevContent ? selectContent(prevContent.content_id, prevContent.content_type) : null)" :style="{ opacity: prevContent ? 1 : 0.6 }">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7069 3.29251C11.0975 3.6827 11.0977 4.31554 10.7073 4.70599L6.70734 8.70651C6.31694 9.09696 5.68378 9.09716 5.29312 8.70697C4.90247 8.31678 4.90227 7.68394 5.29266 7.29349L9.29266 3.29297C9.68306 2.90252 10.3162 2.90232 10.7069 3.29251Z" fill="#060E2B" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7069 12.7075C10.3162 13.0977 9.68306 13.0975 9.29266 12.707L5.29312 8.70697C4.90247 8.31678 4.90227 7.68394 5.29266 7.29349C5.68331 6.9033 6.31694 6.90304 6.70734 7.29349L10.7073 11.294C11.0977 11.6845 11.0975 12.3173 10.7069 12.7075Z" fill="#060E2B" />
                </svg>
              </button>
              <button class="sp_rounded_icon_btn sp_bg_white" @click="() => (nextContent ? selectContent(nextContent.content_id, nextContent.content_type) : null)" :style="{ opacity: nextContent ? 1 : 0.6 }">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 12.7075C4.90247 12.3173 4.90227 11.6845 5.29266 11.294L9.29266 7.29349C9.68306 6.90304 10.3162 6.90284 10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651L6.70734 12.707C6.31694 13.0975 5.68378 13.0977 5.29312 12.7075Z" fill="#060E2B" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 3.29251C5.68378 2.90232 6.31694 2.90252 6.70734 3.29297L10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651C10.3167 9.0967 9.68306 9.09696 9.29266 8.70651L5.29266 4.70599C4.90227 4.31554 4.90247 3.6827 5.29312 3.29251Z" fill="#060E2B" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="sp_inner_content_holder sp_vertical_scrollbar stagger-appear" style="--stagger-delay: 0.6s">
        <div class="d-flex flex-column justify-content-between align-items-center h-100 position-relative" v-if="currentSelectedPage.id">
          <!-- Toolbar -->
          <div v-if="currentSelectedPage.type == 'content'" class="d-flex justify-content-end align-items-center animated fadeIn faster" style="width: 85%; gap: 20px; transition: 0.2s; overflow-y: hidden" :style="{ height: !isSideBarEnabled ? '50px' : '0px', 'margin-top': !isSideBarEnabled ? '10px' : '0px' }">
            <div class="d-flex align-items-center py-1 pr-1" style="gap: 15px">
              <h5 style="font-size: 18px">
                {{ currentSelectedPage.item.name }}
              </h5>
              <div class="d-flex align-items-center" style="gap: 10px">
                <div @click="() => (prevContent ? selectContent(prevContent.content_id, prevContent.content_type) : null)" style="background-color: #f9f9f9; padding: 5px; border-radius: 5px" role="button" :style="{ opacity: prevContent ? 1 : 0.6 }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#4b60ebb6" d="M15.41 7.41L14 6l-6 6l6 6l1.41-1.41L10.83 12z" /></svg>
                </div>
                <div @click="() => (nextContent ? selectContent(nextContent.content_id, nextContent.content_type) : null)" style="background-color: #f9f9f9; padding: 5px; border-radius: 5px" role="button" :style="{ opacity: nextContent ? 1 : 0.6 }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#4b60ebb6" d="M9.29 6.71a.996.996 0 0 0 0 1.41L13.17 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01" /></svg>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center border-left pl-4" v-if="nextContent">
              <h5 style="font-size: 18px; font-weight: 400; color: #475569">Next</h5>
              <h6 style="font-weight: 500">{{ nextContent.name }}</h6>
            </div>
          </div>

          <!-- Intro -->
          <div v-if="currentSelectedPage.intro" class="w-100 d-flex justify-content-end mr-5 pt-3 animated fadeIn">
            <svg @click="goToNext" role="button" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="#475569" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10s10-4.48 10-10M4 12c0-4.42 3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8s-8-3.58-8-8m12 0l-4 4l-1.41-1.41L12.17 13H8v-2h4.17l-1.59-1.59L12 8z"/></svg>
          </div>

          <!-- Viewer container -->
          <div class="mb-3" :class="`${currentSelectedPage.intro ? 'mt-0' : 'mt-4'}`" style="width: 80%; height: 100%">
            <div style="height: 100%; z-index: 0 !important" class="position-relative">
              <!-- Navigation -->
              <Transition name="fade">
                <div v-if="isNavigationScreenEnabled" class="navigation-container">
                  <template v-if="nextContent">
                    <div class="navigation-card-wrapper">
                      <h6 class="w-100 text-center mb-3" style="color: #eee">Up next</h6>

                      <div class="navigation-card">
                        <div>
                          <img :src="nextContent.image" :alt="nextContent.name" />
                        </div>
                        <div class="d-flex align-items-center">
                          <div>
                            <h4 class="mb-2 text-white">
                              {{ nextContent.name }}
                            </h4>
                            <p>{{ ucFirst(nextContent.content_extension) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="navigation-actions">
                        <button class="sp_cstm_btn sp_outlined_sec_btn sp_rounded_btn" style="background-color: #F2F3F9; border: 1px solid #060E2B; color: #060E2B">Start over</button>
                        <button class="sp_cstm_btn sp_border_rounded_btn sp_primary_btn" @click="() => (nextContent ? selectContent(nextContent.content_id, nextContent.content_type) : null)">View now</button>
                      </div>
                    </div>
                  </template>
                </div>
              </Transition>

              <!-- Viewers -->
              <div style="position: absolute; top: 0; left: 0; width: 100%; z-index: 1" class="iframe-container" :class="{ 'apply-box-shadow-2x': currentSelectedPage.type == 'content' }" ref="iframeContainer" :style="iframeContainerStyle">
                <!-- Content viewer -->
                <iframe v-show="currentSelectedPage.type == 'content' && !['pdf', 'image', 'video'].includes(currentSelectedPage.item.content_extension)" :style="iframeStyle" class="iframe" :src="currentSelectedPage.item.content_type == 'tour' ? getTourShareCode({ publicId: currentSelectedPage.item.content_public_id, query: { interaction: sessionId }}) : getContentUrl(currentSelectedPage.item)" ref="iframeRef" allow="autoplay" />

                <img v-if="currentSelectedPage.item.content_extension == 'image'" :src="currentSelectedPage.item.url" style="width: 100%; height: 100%; object-fit: contain" />

                <PdfViewer v-if="currentSelectedPage.item.content_extension == 'pdf'" :key="currentSelectedPage.item.content_id + currentSelectedPage.item.content_type" :title="currentSelectedPage.item.name" :url="currentSelectedPage.item.url" @onScroll="onPdfScroll" />

                <VideoPlayer v-if="currentSelectedPage.item.content_extension == 'video'" :src="currentSelectedPage.item.url" :poster="currentSelectedPage.item.image" style="width: 100%; height: 100%" @onProgress="(state) => onVideoProgress(state.progress)" autoplay />

                <!-- Journey -->
                <div class="w-100 h-100 mt-5 journey-container" v-if="currentSelectedPage.type == 'question'">
                  <template v-if="!isQuestionLoading">
                    <h2 class="mb-4 journey-question">
                      <Typewriter :text="currentSelectedPage.item.question_preview" :key="currentSelectedPage.item.id" @onFinish="questionTypingAnimationOnFinish" />
                    </h2>

                    <div class="animated fadeIn" v-if="!isQuestionTypingAnimationEnabled">
                      <!-- Open ended | Message | Boolean -->
                      <div v-if="!isSingleSelect && !isMultiSelect">
                        <template v-if="currentSelectedPage.item.type == 'open_ended'">
                          <form class="d-flex align-items-center" @submit.prevent="goToNext">
                            <input class="journey-open-ended-input" type="text" placeholder="Type here..." style="background-color: transparent; border: none; outline: none; padding: 10px 0px; flex: 1; font-size: 20px" v-model="currentSelectedPage.item.userResponse" v-focus />

                            <div>
                              <button style="all: unset;" type="submit" role="button">
                                <svg role="button" width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="40" height="40" rx="20" fill="#1C45D6"/>
                                  <g clip-path="url(#clip0_368_17278)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2687 12.0567C27.4354 11.9694 27.6367 11.9841 27.79 12.0927C27.9434 12.2021 28.022 12.3874 27.9947 12.5741L26.1614 24.9074C26.1394 25.0534 26.0534 25.1834 25.9267 25.2607C25.8474 25.3087 25.7574 25.3334 25.6667 25.3334C25.612 25.3334 25.5574 25.3241 25.5054 25.3067L18.5167 22.9181L25.3887 14.6387L16.508 22.2321L12.338 20.8067C12.1494 20.7421 12.0167 20.5714 12.0014 20.3714C11.9867 20.1721 12.0914 19.9827 12.2687 19.8901L27.2687 12.0567ZM17.8333 26.8333V23.7413L20.5446 24.668L18.736 27.1293C18.64 27.26 18.4893 27.3333 18.3333 27.3333C18.2813 27.3333 18.2286 27.3253 18.1773 27.3087C17.972 27.2407 17.8333 27.0493 17.8333 26.8333Z" fill="white"/>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_368_17278">
                                      <rect width="16" height="16" fill="white" transform="translate(12 12)"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                            </div>
                          </form>
                        </template>

                        <template v-if="currentSelectedPage.item.type == 'boolean'">
                          <div class="d-flex mt-4" style="gap: 10px">
                            <button
                              class="sp_cstm_btn sp_border_rounded_btn sp_primary_btn px-4"
                              @click="
                                () => {
                                  currentSelectedPage.item.userResponse = true;
                                  goToNext();
                                }
                              "
                            >
                              {{ getMeta(currentSelectedPage.item, "true_button_text", "Yes") }}
                            </button>
                            <button
                              class="sp_cstm_btn sp_border_rounded_btn sp_outlined_secondary_btn px-4"
                              style="color: #ffffff; background-color: #6c757d; border-color: #6c757d"
                              @click="
                                () => {
                                  currentSelectedPage.item.userResponse = false;
                                  goToNext();
                                }
                              "
                            >
                              {{ getMeta(currentSelectedPage.item, "false_button_text", "No") }}
                            </button>
                          </div>
                        </template>

                        <button class="sp_cstm_btn sp_border_rounded_btn sp_outlined_secondary_btn mt-5" @click="() => goToNext({ skip: true })">Skip</button>
                      </div>

                      <!-- Single | Multi select -->
                      <template v-else>
                        <div class="d-flex align-items-center" style="gap: 10px">
                          <div v-for="option of currentSelectedPage.item.options" :key="option.id" role="button" class="p-4 border sp_cstm_btn sp_border_rounded_btn" :class="{ sp_primary_btn: isSingleSelect ? currentSelectedPage.item.userResponse.id == option.id : currentSelectedPage.item.userResponse.some((item) => item.id == option.id) }" @click="() => selectOption(option.id)" v-show="option.text">
                            {{ option.text }}
                          </div>
                        </div>

                        <div class="mt-5 d-flex align-items-center justify-content-between" style="gap: 30px">
                          <button class="sp_cstm_btn sp_border_rounded_btn sp_outlined_secondary_btn" @click="() => goToNext({ skip: true })">Skip</button>
                          <button class="sp_cstm_btn sp_border_rounded_btn sp_primary_btn d-flex align-items-center" style="gap: 5px" v-if="(isSingleSelect && currentSelectedPage.item.userResponse.id) || (isMultiSelect && currentSelectedPage.item.userResponse.length)" @click="goToNext">
                            <span>
                              {{ getMeta(currentSelectedPage.item, "next_button_text", "Next") }}
                            </span>
                            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 12.7075C4.90247 12.3173 4.90227 11.6845 5.29266 11.294L9.29266 7.29349C9.68306 6.90304 10.3162 6.90284 10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651L6.70734 12.707C6.31694 13.0975 5.68378 13.0977 5.29312 12.7075Z" fill="white" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29312 3.29251C5.68378 2.90232 6.31694 2.90252 6.70734 3.29297L10.7069 7.29303C11.0975 7.68322 11.0977 8.31606 10.7073 8.70651C10.3167 9.0967 9.68306 9.09696 9.29266 8.70651L5.29266 4.70599C4.90227 4.31554 4.90247 3.6827 5.29312 3.29251Z" fill="white" />
                            </svg>
                          </button>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <h2 class="mb-4 journey-question">
                      <span class="blink">|</span>
                    </h2>
                  </template>
                </div>
              </div>

              <!-- Chat window -->
              <Transition name="fade">
                <div v-if="false" class="chat-wrapper apply-box-shadow-2x animated faster slideInUp">
                  <div class="d-flex justify-content-end mb-2 pt-3 pr-3">
                    <svg role="button" @click="hideChatWindow" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="#17171aa2" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" /></svg>
                  </div>
                  <div ref="chatWindow" class="chat-window w-100 p-3">
                    <div v-for="(messageItem, index) of chatMessages" :key="index" class="d-flex flex-column">
                      <div class="message-item animated faster zoomIn" :class="{ 'ai-message slideInLeft': messageItem.isAiMessage }">
                        <span>{{ messageItem.message }}</span>

                        <div v-if="messageItem.recomendation" @click="() => addToPlaylist(messageItem.recomendation.content_id, messageItem.recomendation.content_type, { select: true })" class="mt-3 animated faster zoomIn message-item-recomendation" style="--animate-repeat: 0; background-color: white; border-radius: 10px; width: 244px; display: flex; padding: 10px; justify-content: space-between; align-items: center; gap: 10px; cursor: pointer">
                          <div class="d-flex align-items-center" style="gap: 10px">
                            <img :src="messageItem.recomendation.image" class="border" alt="" style="width: 50px; height: 50px; border-radius: 10px; object-fit: cover" />
                            <div>
                              <p class="mb-1" style="font-size: 12px; font-weight: 700; text-wrap: balance">{{ messageItem.recomendation.name }}</p>
                              <p style="font-size: 10px; font-weight: 300; text-wrap: balance">{{ ucFirst(messageItem.recomendation.content_extension) }}</p>
                            </div>
                          </div>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24"><path fill="gray" d="M6.23 20.23L8 22l10-10L8 2L6.23 3.77L14.46 12z" /></svg>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="chat-bubble animated faster zoomIn" v-if="isChatLoading">
                      <div class="typing">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>

          <!-- Chat widget -->
          <div v-if="false" class="chat-widget d-flex justify-content-between align-items-center p-4 mb-5" style="">
            <div>
              <img src="https://avatars.githubusercontent.com/u/13297792?v=4" style="border-radius: 50px; width: 60px; height: 60px" alt="" />
            </div>
            <div class="w-100">
              <form action="" @submit.prevent="sendChatMessage">
                <input class="w-75" type="text" name="" id="" placeholder="Ask me any question" style="border: none; outline: none !important" @focus="showChatWindow" v-model="chatInput" />
              </form>
            </div>
            <div>
              <svg role="button" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" @click="sendChatMessage">
                <rect width="40" height="40" rx="4" fill="#1F26AA" fill-opacity="0.16" />
                <g clip-path="url(#clip0_6500_14185)">
                  <path d="M17.8359 23.7412V26.8332C17.8359 27.0492 17.9746 27.2405 18.1799 27.3085C18.2313 27.3252 18.2839 27.3332 18.3359 27.3332C18.4919 27.3332 18.6426 27.2599 18.7386 27.1292L20.5473 24.6679L17.8359 23.7412Z" fill="#1F26AA" />
                  <path d="M27.79 12.0927C27.6367 11.9841 27.4354 11.9694 27.2687 12.0567L12.2687 19.8901C12.0914 19.9827 11.9867 20.1721 12.0014 20.3714C12.0167 20.5714 12.1494 20.7421 12.338 20.8067L16.508 22.2321L25.3887 14.6387L18.5167 22.9181L25.5054 25.3067C25.5574 25.3241 25.612 25.3334 25.6667 25.3334C25.7574 25.3334 25.8474 25.3087 25.9267 25.2607C26.0534 25.1834 26.1394 25.0534 26.1614 24.9074L27.9947 12.5741C28.022 12.3874 27.9434 12.2021 27.79 12.0927Z" fill="#1F26AA" />
                </g>
                <defs>
                  <clipPath id="clip0_6500_14185">
                    <rect width="16" height="16" fill="white" transform="translate(12 12)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div v-else></div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import PdfViewer from "../PdfViewer";
import VideoPlayer from "../VideoPlayer";
import Typewriter from "../Typewriter";
import Analytics from "@/analytics/agent";
import { queryParams, contrastColor, addQueryParam, getTourShareCode } from "@/utils";

function toggleOptionInUserResponse(arr, option) {
  const index = arr.findIndex((item) => item.id == option.id);

  if (index !== -1) {
    arr.splice(index, 1);
  } else {
    arr.push(option);
  }

  return arr;
}

function generateWelcomeMessage() {
  const welcomeMessages = ["Hello! I'm here to help you. How can I assist you today?", "Hi there! I'm here to assist you. What do you need help with?", "Greetings! How can I assist you today?", "Hey! I'm here to help. Feel free to ask me anything.", "Welcome! I'm here to assist you. How can I be of service?", "Hello! I'm at your service. How can I help?", "Hi! Let me know what you need.", "Greetings! How can I assist you today?", "Hey there! I'm here to help. Ask me anything you need.", "Welcome! How may I assist you today?", "Hello! How can I assist you today?", "Hi there! How can I help you today?", "Greetings! How can I be of assistance?", "Hey! How can I help you today?", "Welcome! What can I do for you today?"];

  const randomIndex = Math.floor(Math.random() * welcomeMessages.length);

  return welcomeMessages[randomIndex];
}

export default {
  props: ["publicId"],
  components: {
    Loading,
    Typewriter,
    PdfViewer,
    VideoPlayer,
  },
  data() {
    return {
      isOpen: true,
      isOpenUserInfo: false,
      isOpenChatBox: false,

      loading: true,

      isManual: false,

      iframeStyle: {},
      iframeContainerStyle: {},
      deviceWidth: 1620,

      currentSelectedPage: {},

      demoCenter: {},
      playlist: [],
      resources: [],
      resourceCollection: [],
      journeys: [],
      isRecomendationLoading: false,

      user: {
        name: "",
        email: "",
      },

      // Sidebar
      isSideBarEnabled: true,
      // Journey
      isQuestionLoading: false,
      isQuestionTypingAnimationEnabled: false,
      // Chat
      initialChatOpen: false,
      isChatEnable: false,
      isChatLoading: false,
      chatInput: "",
      chatMessages: [],
      // Broadcast channel
      channel: null,
      tourChannel: null,
      // Analytics
      trackAnalytics: null,
      // Navigation
      isNavigationScreenEnabled: false,
    };
  },
  created() {
    const params = queryParams();

    this.isManual = Boolean(params.manual);

    this.loading = true;

    this.initPreview();
  },
  mounted() {
    this.adjustSize();

    this.resizeObserver = new ResizeObserver(() => {
      this.$nextTick(() => {
        this.adjustSize();
      });
    });

    if (this.$refs.iframeContainer) {
      this.resizeObserver.observe(this.$refs.iframeContainer);
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    toggleUserInfo() {
      this.isOpenUserInfo = !this.isOpenUserInfo;
    },
    toggleChatBox() {
      this.isOpenChatBox = !this.isOpenChatBox;
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -100, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 100, behavior: "smooth" });
    },

    // Utils
    contrastColor,

    // Demo center
    getDemoCenter(){
      this.loading = true;

      return this.axios.get(`/frontend/demo-center/view/${this.publicId}`, {
        params: { settings: true }
      }).then((response) => {
        const { data } = response.data;

        this.demoCenter =  {
          ...data,
          settings: data.settings || {}
        };

        return data;
      }).catch((errorRes) => {
        const { message } = errorRes.response.data;
        this.toast(message, "e");
      }).finally(() => {
        this.loading = false;
      });
    },
    initPreview() {
      return this.getDemoCenter().then(() => {
        document.title = `${this.demoCenter.name} - Showpilot`;

        if (!this.trackAnalytics) {
          this.trackAnalytics = new Analytics({
            platform_customer_id: this.demoCenter.platform_customer_id,
            dev: false,
            logging: true,
          });

          // On page close / reload
          window.onbeforeunload = () => {
            this.trackAnalytics?.onClose(this.analyticsData());
          };

          setTimeout(() => {
            this.trackAnalytics?.addVistorEvent(this.analyticsData());
            this.trackAnalytics?.startTimer(`demo_center_${this.demoCenter.id}`);
          }, 100);
        }

        this.createTourBroadcastChannel(this.sessionId || "");

        Promise.all([this.getDemoCenterContents(), this.getDemoCenterResources(), this.getDemoCenterJourneys()])
          .then(() => {
            this.updateResourceCollection();

            if (!this.isManual) {
              this.start();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    restartPreview() {
      this.currentSelectedPage.id = null;

      this.journeys = this.journeys.map((item) => ({ ...item, question_preview: "", userResponse: "" }));

      this.$nextTick(() => {
        this.start();
      });
    },
    start() {
      // Intro content
      if(this.demoCenter.settings && this.demoCenter.settings.intro_enabled && this.demoCenter.settings.intro_content_url){
        this.currentSelectedPage = {
          id: 1,
          item: {
            name: this.demoCenter.settings.intro_content_name,
            content_type: this.demoCenter.settings.intro_content_type != 'tour' ? 'library' : 'tour',
            content_extension: this.demoCenter.settings.intro_content_type,
            url: this.demoCenter.settings.intro_content_url,
            image: this.demoCenter.settings.intro_content_image,
            content_id: this.demoCenter.settings.intro_content_id,
          },
          type: "content",
          intro: true,
        };

        return;
      }

      if (this.journeys.length) {
        this.startJourney();
      } else if (this.playlist.length) {
        this.selectContent(this.playlist[0].content_id, this.playlist[0].content_type);
      }
    },
    startJourney(){
      if (this.journeys.length) {
        if (!this.journeys[0].meta) {
          this.selectQuestion(this.journeys[0].id);
        } else if (this.journeys[0].meta && !this.journeys[0].meta.appear_type) {
          this.selectQuestion(this.journeys[0].id);
        } else if (this.playlist.length) {
          this.selectContent(this.playlist[0].content_id, this.playlist[0].content_type);
        }
      }
    },
    // Iframe
    adjustSize() {
      const iframe = this.$refs.iframeRef;
      const iframeContainer = this.$refs.iframeContainer;

      if (!iframe || !iframeContainer) {
        return;
      }

      const width = this.deviceWidth;
      const scaleFactor = (iframeContainer.clientWidth || 500) / this.deviceWidth;

      this.iframeStyle = {
        width: `${width}px`,
        height: `${iframeContainer.clientHeight / scaleFactor}px`,
        transform: `scale(${scaleFactor})`,
        transformOrigin: "top left",
      };
    },
    onChangeDeviceWidth(e) {
      this.deviceWidth = Number(e.target.value);

      this.$nextTick(() => {
        this.adjustSize();
      });
    },
    onChangeUrl(e) {
      this.url = e.target.value;
    },
    // Playlist
    getDemoCenterContents(options = {}) {
      return this.axios.get(`/frontend/demo-center/content/${this.demoCenter.public_id}`).then((response) => {
        const { data } = response.data;

        this.playlist = data;
      });
    },
    getDemoCenterResources(options = {}) {
      return this.axios.get(`/frontend/demo-center/resource/${this.demoCenter.public_id}`).then((response) => {
        const { data } = response.data;

        this.resources = data;
      });
    },
    getContentRecommendations(query) {
      return this.axios
        .post(`/frontend/demo-center/content/recommend`, {
          demo_center_id: this.demoCenter.public_id,
          query,
        })
        .then((response) => {
          const { data } = response.data;

          return data;
        });
    },
    async recommendContent(query) {
      const recommendations = await this.getContentRecommendations(query);

      return recommendations;
    },
    updateResourceCollection() {
      const contentOptions = this.journeys.flatMap((journey) => {
        if (journey.options) {
          return journey.options.flatMap((option) => {
            return option.contents || [];
          });
        }

        return [];
      });

      this.resourceCollection = [...this.playlist, ...this.resources, ...contentOptions];
    },
    addToPlaylist(content_id, content_type = "library", { select = false, recomendation = false } = {}) {
      const index = this.playlist.findIndex((item) => item.content_id == content_id && item.content_type == content_type);

      if (index == -1) {
        const content = this.resourceCollection.find((item) => {
          return item.content_id == content_id && item.content_type == content_type;
        });

        if (content) {
          this.playlist.push({ ...content, isRecomendation: recomendation });
        }
      }

      if (select) {
        this.selectContent(content_id, content_type);

        setTimeout(() => {
          this.hideChatWindow();
        }, 100);
      }
    },
    removeFromPlaylist(content_id, content_type = "library") {
      this.playlist = this.playlist.filter((item) => !(item.content_id == content_id && item.content_type == content_type));
    },
    getContentUrl(content) {
      if (content.content_extension == "youtube") {
        return addQueryParam(content.url, { autoplay: 1 });
      }

      return content.url;
    },
    getTourShareCode,
    // Journey
    getDemoCenterJourneys() {
      return this.axios.get(`/frontend/demo-center/journey/${this.demoCenter.public_id}`).then((response) => {
        const { data } = response.data;

        this.journeys = data.map((item) => {
          let userResponse = "";

          if (["single_select", "multi_select"].includes(item.type)) userResponse = [];
          return {
            ...item,
            userResponse,
          };
        });
      });
    },
    generateQuestion({ topic, history = "" }) {
      return this.axios
        .post(`/frontend/demo-center/question/${this.demoCenter.public_id}`, {
          topic,
          history,
        })
        .then((response) => {
          const { data } = response.data;

          return data;
        });
    },
    selectOption(id) {
      if (this.currentSelectedPage) {
        if (this.isSingleSelect) {
          const option = this.currentSelectedPage.item.options.find((optionItem) => optionItem.id == id);

          this.currentSelectedPage.item.userResponse = option;

          // Recommendations
          this.playlist.forEach((content) => {
            if (content.isRecomendation) {
              this.removeFromPlaylist(content.content_id, content.content_type);
            }
          });

          const playlistSet = this.playlistSet;

          const filteredContent = option.contents.filter((item) => !playlistSet.has(item.content_id + item.content_type));

          // const numOfRecommendations = Math.floor(Math.random() * 2) + 1;

          // const randomContents = getRandomElements(
          //   filteredContent,
          //   numOfRecommendations
          // );

          filteredContent.forEach((content) => {
            this.addToPlaylist(content.content_id, content.content_type, { recomendation: true });
          });
        } else if (this.isMultiSelect) {
          const option = this.currentSelectedPage.item.options.find((optionItem) => optionItem.id == id);

          if (!Array.isArray(this.currentSelectedPage.item.userResponse)) this.currentSelectedPage.item.userResponse = [];

          this.currentSelectedPage.item.userResponse = toggleOptionInUserResponse(this.currentSelectedPage.item.userResponse, option);

          // Recommendations
          const contents = this.currentSelectedPage.item.userResponse.flatMap((option) => {
            return option.contents.map((content) => {
              return {
                content_id: content.content_id,
                content_type: content.content_type,
              };
            });
          });

          this.playlist.forEach((content) => {
            if (content.isRecomendation) {
              this.removeFromPlaylist(content.content_id, content.content_type);
            }
          });

          const playlistSet = this.playlistSet;

          const filteredContent = contents.filter((item) => !playlistSet.has(item.content_id + item.content_type));

          filteredContent.forEach((content) => {
            this.addToPlaylist(content.content_id, content.content_type, { recomendation: true });
          });
        }
      }
    },
    async selectQuestion(id) {
      const question = this.journeys.find((item) => item.id == id);

      this.isQuestionTypingAnimationEnabled = true;

      this.currentSelectedPage = {
        id: question.id,
        item: question,
        type: "question",
      };

      if (question.is_ai_enabled) {
        this.isQuestionLoading = true;

        const history = this.journeys
          .map((item) => {
            if (!item.question_preview) return "";

            let response = "";

            if (item.type == "single_select") {
              response = item.userResponse.text;
            } else if (item.type == "multi_select") {
              response = item.userResponse.map((option) => option.text).join(",");
            } else {
              response = item.userResponse;
            }

            return `Question: ${item.question_preview}. Answer: ${response}`;
          })
          .filter(Boolean)
          .join(" | ");

        this.currentSelectedPage.item.question_preview = await this.generateQuestion({
          topic: question.question,
          history,
        });

        this.isQuestionLoading = false;
      } else {
        this.currentSelectedPage.item.question_preview = question.question;
      }

      this.hideNavigationScreen();
    },
    selectContent(content_id, content_type) {
      if(this.currentSelectedPage.intro){
        return this.startJourney();
      }

      const content = this.resourceCollection.find((item) => item.content_id == content_id && item.content_type == content_type);

      let journeyItem = null;

      this.journeys.forEach((journey) => {
        if (journey.meta && journey.meta.appear_content_id == content_id) {
          const appearType = journey.meta.appear_type;

          if (appearType == "before_content") {
            journeyItem = journey;
          }
        }
      });

      if (journeyItem) {
        this.selectQuestion(journeyItem.id);

        return;
      }

      if (content) {
        if (this.currentSelectedPage.item) {
          this.trackAnalytics.addTotalTimeEvent(this.analyticsData(), {
            id: `resource_${this.currentSelectedPage.item.content_id + this.currentSelectedPage.item.content_type}`,
            type: "resource",
            entries: {
              content_id: this.currentSelectedPage.item.content_id,
              content_type: this.currentSelectedPage.item.content_type,
            },
          });

          this.trackAnalytics.resetTimer(`resource_${this.currentSelectedPage.item.content_id + this.currentSelectedPage.item.content_type}`);
        }

        this.currentSelectedPage = {
          id: content.id,
          item: content,
          type: "content",
        };

        this.trackAnalytics.addResourceViewEvent(
          {
            content_id: this.currentSelectedPage.item.content_id,
            content_type: this.currentSelectedPage.item.content_type,
          },
          this.analyticsData(),
        );

        this.trackAnalytics.startTimer(`resource_${this.currentSelectedPage.item.content_id + this.currentSelectedPage.item.content_type}`);

        setTimeout(() => {
          if (content.content_type == "tour") this.adjustSize();
          else this.iframeStyle = {};
        }, 800);

        this.hideNavigationScreen();

        this.scrollContentIntoView(this.currentSelectedPage.item);
      }
    },
    scrollContentIntoView(content) {
      const contentId = `content-${content.content_id + content.content_type}`;

      document.getElementById(contentId)?.scrollIntoView({ behavior: "smooth" });
    },
    questionTypingAnimationOnFinish() {
      this.isQuestionTypingAnimationEnabled = false;
    },
    getMeta(journey, property, defaultValue = "") {
      if (journey.meta && journey.meta[property]) {
        return journey.meta[property];
      }

      return defaultValue;
    },
    async recommendJourneyContent() {
      let query = "";

      this.journeys.forEach((journey) => {
        const serializedJourney = this.serializeJourney(journey);

        query += `Question: ${serializedJourney.question_preview} Answer: ${serializedJourney.user_response} `;
      });

      this.isRecomendationLoading = true;

      try {
        const recommendations = await this.recommendContent(query);

        recommendations.forEach((content) => {
          this.addToPlaylist(content.content_id, content.content_type);
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isRecomendationLoading = false;
      }
    },
    // Navigation
    goToNext({ skip = false } = {}) {
      if(this.currentSelectedPage.intro){
        return this.startJourney();
      }

      if (skip) {
        this.playlist.forEach((content) => {
          if (content.isRecomendation) {
            this.removeFromPlaylist(content.content_id, content.content_type);
          }
        });
      } else {
        this.playlist = this.playlist.map((content) => {
          return { ...content, isRecomendation: false };
        });
      }

      if (this.currentSelectedPage.type == "question") {
        const index = this.journeys.findIndex((journey) => journey.id == this.currentSelectedPage.id);
        const isLast = index + 1 > this.journeys.length - 1;

        const serializedJourney = this.serializeJourney(this.currentSelectedPage.item);

        if (["name", "email"].includes(serializedJourney.response_type) && serializedJourney.user_response) {
          this.user = {
            ...this.user,
            [serializedJourney.response_type]: serializedJourney.user_response,
          };
        }

        this.trackAnalytics.addJourneyEntry(serializedJourney, this.analyticsData());

        if (isLast) {
          this.recommendJourneyContent();

          this.selectContent(this.playlist[0].content_id, this.playlist[0].content_type);
        } else {
          this.selectQuestion(this.journeys[index + 1].id);
        }
      }
    },
    goToNextContent() {},
    // Chat
    queryLlmModel(question = "") {
      const data = {
        question,
        demo_center_id: this.demoCenter.id,
      };

      return this.axios
        .post(`/frontend/demo-center/chat/${this.demoCenter.public_id}`, data)
        .then((response) => {
          const { data } = response.data;

          return data.result;
        })
        .catch((errorRes) => {
          const { message } = errorRes.response.data;
          this.toast(message, "e");
        });
    },
    showChatWindow() {
      this.isChatEnable = true;

      if (!this.initialChatOpen) {
        this.initialChatOpen = true;

        setTimeout(() => {
          this.isChatLoading = true;

          setTimeout(() => {
            this.isChatLoading = false;

            this.chatMessages.push({
              isAiMessage: true,
              message: generateWelcomeMessage(),
            });
          }, 700);
        }, 500);
      }
    },
    hideChatWindow() {
      this.isChatEnable = false;
    },
    sendChatMessage() {
      const question = this.chatInput;

      if (!question) return;

      this.chatMessages.push({
        isAiMessage: false,
        message: question,
      });

      this.trackAnalytics?.addChatEntry(
        {
          message: question,
          is_ai_message: false,
        },
        this.analyticsData(),
      );

      this.chatInput = "";

      const timerId = setTimeout(() => {
        this.isChatLoading = true;

        this.scrollToBottomChatWindow();
      }, 800);

      this.queryLlmModel(question)
        .then((res) => {
          if (!res) throw new Error("No response");

          const recomendation = res.recomended_content
            ? this.resourceCollection.find((item) => {
                return item.content_id == parseInt(res.recomended_content);
              })
            : null;

          this.chatMessages.push({
            isAiMessage: true,
            message: res.answer,
            recomendation,
          });

          this.trackAnalytics?.addChatEntry(
            {
              message: res.answer,
              is_ai_message: true,
            },
            this.analyticsData(),
          );
        })
        .catch(console.log)
        .finally(() => {
          clearTimeout(timerId);

          this.isChatLoading = false;
        });
    },
    scrollToBottomChatWindow() {
      this.$nextTick(() => {
        this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
      });
    },
    // Broadcast channel
    createBroadcastChannel(id) {
      this.channel = new BroadcastChannel(`demo-center-${id}`);

      this.channel.addEventListener("message", ({ data }) => {
        const { action, payload } = data;

        switch (action) {
          case "reload-preview": {
            this.initPreview();

            break;
          }
          case "restart-preview": {
            this.restartPreview();

            break;
          }
        }
      });
    },
    closeBroadcastChannel() {
      this.channel && this.channel.close();
    },
    sendMessage(action, payload) {
      this.channel.postMessage({
        action,
        payload,
      });
    },
    // Tour Broadcast channel
    createTourBroadcastChannel(id) {
      this.tourChannel = new BroadcastChannel(`tour-interaction-${id}`);

      this.tourChannel.addEventListener("message", ({ data }) => {
        const { action, payload } = data;

        switch (action) {
          case "GUIDE_VIEW": {
            this.onTourProgress(payload);
          }
        }
      });
    },
    // Analytics
    analyticsData() {
      return {
        demo_center: {
          id: parseInt(this.demoCenter?.id),
          name: this.demoCenter.name,
        },
        delivery_type: this.delivery_type,
        customer: this.customerData,
        user: {
          name: this.user.name,
          email: this.user.email,
        },
      };
    },
    serializeJourney(journey) {
      const type = journey.type;

      const result = {
        question: journey.question,
        question_type: type,
        question_preview: journey.question_preview,
        question_options: journey.options ? journey.options.map((item) => item.text).join(" ($,$) ") : "",
        response_type: journey.response_type,
        user_response: null,
      };

      if (type == "single_select") {
        result.user_response = journey.userResponse.text;
      } else if (type == "multi_select") {
        result.user_response = journey.userResponse.map((item) => item.text).join(" ($,$) ");
      } else {
        result.user_response = String(journey.userResponse);
      }

      return result;
    },
    trackResourceProgress(resource, percentage) {
      this.trackAnalytics.updateResourceStateMap(resource.content_id + resource.content_type, (state) => {
        return {
          ...state,
          event_type: "resource_completion",
          content_id: resource.content_id,
          content_type: resource.content_type,
          content_view_percentage: Math.max(state.content_view_percentage || 0, percentage),
        };
      });
    },
    // Resource progress
    onPdfScroll(percentage) {
      this.trackResourceProgress(this.currentSelectedPage.item, percentage);

      if (percentage == 100) {
        this.showNavigationScreen();
      }
    },
    onVideoProgress(percentage) {
      this.trackResourceProgress(this.currentSelectedPage.item, percentage);

      if (percentage == 100) {
        this.showNavigationScreen();
      }
    },
    onTourProgress(data) {
      const percentage = parseInt((data.current_step / data.total_steps) * 100);

      this.trackResourceProgress(this.currentSelectedPage.item, percentage);

      if (percentage == 100) {
        this.showNavigationScreen();
      }
    },
    // Navigation
    showNavigationScreen() {
      if (this.nextContent) {
        setTimeout(() => {
          this.isNavigationScreenEnabled = true;
        }, 500);
      }
    },
    hideNavigationScreen() {
      this.isNavigationScreenEnabled = false;
    },
  },
  computed: {
    playlistSet() {
      return new Set(this.playlist.map((item) => item.content_id + item.content_type));
    },
    isSingleSelect() {
      return this.currentSelectedPage && this.currentSelectedPage.type == "question" && this.currentSelectedPage.item.type == "single_select";
    },
    isMultiSelect() {
      return this.currentSelectedPage && this.currentSelectedPage.type == "question" && this.currentSelectedPage.item.type == "multi_select";
    },
    nextContent() {
      const index = this.playlist.findIndex((content) => content.id == this.currentSelectedPage.id);

      const isLast = index + 1 > this.playlist.length - 1;

      if (isLast) return null;

      return this.playlist[index + 1];
    },
    prevContent() {
      const index = this.playlist.findIndex((content) => content.id == this.currentSelectedPage.id);

      const isFirst = index == 0;

      if (isFirst) return null;

      return this.playlist[index - 1];
    },
    sessionId() {
      return this.trackAnalytics?.sessionId;
    },
  },
  watch: {
    "currentSelectedPage.item.type": function () {
      this.adjustSize();
    },
    chatMessages() {
      this.scrollToBottomChatWindow();
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/v2/widget.scss";
@import "../../../assets/scss/v2/global.scss";

.iframe-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  transition: 0.2s;
}
.iframe-container.chat-enabled {
  top: -25px !important;
  transform: scale(0.95);
  filter: blur(1px);
}

.iframe {
  overflow: hidden;
  border: 0;
  width: 100%;
  height: 100%;
}

.playlist-item:has(.item-selected) {
  border-color: #4b60eb !important;
}

.content-selected {
  background-color: #4b60eb10;
  border: 3px solid #4b60ebb6 !important;
  border-radius: 10px;
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: -25px;
  width: 40px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 50%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.sidebar-toggle.disabled {
  left: -70px;
}
.sidebar-toggle:hover {
  background: #ededed;
}

.apply-box-shadow {
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.apply-box-shadow-2x {
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.no-transition * {
  transition: none !important;
}

/* Navigation */
.navigation-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #475569d9;
  backdrop-filter: blur(4px);
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.navigation-card-wrapper {
  width: 35%;
}
.navigation-card {
  width: 100%;
  background-color: #0f172a;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  gap: 15px;
  z-index: 100000;
}
.navigation-card img {
  width: 5rem;
  height: 5rem;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid #eeeeee95;
}
.navigation-card h4 {
  color: #eee;
}
.navigation-card p {
  color: #eeeeee8d;
}
.navigation-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 30px;
}
.navigation-actions button {
  width: 100%;
}
/* Chat */
.chat-widget {
  width: 60%;
  background-color: white;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  height: 80px;
  border-radius: 5px;
  gap: 10px;
  z-index: 100;
}

.chat-wrapper {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  will-change: transform, opacity;
}

.chat-window {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.chat-window > :first-child {
  margin-top: auto;
}

.chat-window::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-window::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: #747474;
}

.message-item {
  background-color: #4b60eb;
  color: white;
  max-width: 50%;
  padding: 15px;
  border-radius: 20px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 2px;
  margin-bottom: 10px;
  align-self: flex-end;
  text-wrap: balance;
}

.message-item.ai-message {
  background-color: #eeeff4;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  align-self: flex-start;
  color: #17171a;
}

.chat-bubble {
  width: max-content;
  background-color: #eeeff4;
  padding: 11px 24px;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #17171aa2;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #17171a9e;
  }
  28% {
    transform: translateY(-7px);
    background-color: #17171a63;
  }
  44% {
    transform: translateY(0px);
    background-color: #17171a2c;
  }
}

/* Journey */
@keyframes blink-caret {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
.blink {
  animation: blink-caret 0.75s step-end infinite;
}

/* Skeleton */
.skeleton-container {
  display: flex;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

.skeleton-image {
  width: 56px;
  height: 56px;
  background-color: #b0b0b0;
  margin-right: 20px;
  border-radius: 8px;
  animation: skeletonPulseImage 1.5s infinite;
}

.skeleton-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.skeleton-title {
  height: 13px;
  width: 70%;
  background-color: #c0c0c0;
  margin-bottom: 12px;
  border-radius: 4px;
  animation: skeletonPulseTitle 1.5s infinite 0.1s;
}

.skeleton-text {
  width: 90%;
  height: 5px;
  background-color: #d0d0d0;
  margin-bottom: 8px;
  border-radius: 4px;
  animation: skeletonPulseText 1.5s infinite 0.2s;
}

.skeleton-text:nth-child(2) {
  animation-delay: 0.3s;
}

.skeleton-text:nth-child(3) {
  animation-delay: 0.4s;
}

.skeleton-text:last-child {
  width: 60%;
}

@keyframes skeletonPulseImage {
  0%,
  100% {
    background-color: #b0b0b0;
  }
  50% {
    background-color: #808080;
  }
}

@keyframes skeletonPulseTitle {
  0%,
  100% {
    background-color: #c0c0c0;
  }
  50% {
    background-color: #a0a0a0;
  }
}

@keyframes skeletonPulseText {
  0%,
  100% {
    background-color: #d0d0d0;
  }
  50% {
    background-color: #b0b0b0;
  }
}
</style>
