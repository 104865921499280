import * as COMPONENTS from "./components";

export const ELEMENTS = [
    {
        id: "text",
        type: ["tag", "font", "text"],
        label: "Heading",
        default: {
            props: {
                content: "Getting started",
            },
            styles: {
                desktop: {
                    width: "130px",
                    'word-break': "break-word",
                }
            }
        },
        constants: {
            styles: {
                background: "#00000000",
                color: "#1a1a1c",
                "font-size": "16px",
                "font-weight": "bold",
                "letter-spacing": "0",
            }
        }
    },
    {
        id: "rich_text",
        type: ["tag", "text", 'font', "rich_text"],
        label: "Rich text",
        default: {
            props: {
                content: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>",
            },
            styles: {
                desktop: {
                    width: "240px",
                    'word-break': "break-word",
                }
            }
        },
        constants: {
            styles: {
                background: "#00000000",
                color: "#1a1a1c",
            }
        }
    },
    {
        id: "button",
        type: ["tag", "font", "button"],
        label: "Button",
        default: {
            props: {
                content: "Next",
                on_click_action: "next",
                button_type: "primary",
            },
            styles: {
                desktop: {
                    width: "100px",
                    height: "40px",
                }
            }
        },
        constants: {
            styles: {
                background: "#4B60EB",
                color: "#fff",
                '--builder-button-hover-background': "#4B60EB",
                '--builder-button-hover-color': "#ffffff"
            }
        }
    },
    {
        id: "step_counter",
        type: ["tag", "font", "step_counter"],
        label: "Step counter",
        default: {
            props: {},
            styles: {
                desktop: {}
            }
        },
        constants: {
            styles: {
                color: "#778F98",
            }
        }
    },
    {
        id: "box",
        type: ["tag", "shape"],
        label: "Box",
        default: {
            props: {},
            styles: {
                desktop: {
                    width: "80px",
                    height: "80px",
                    background: "#7dd3fc",
                }
            }
        },
    },
    {
        id: "circle",
        type: ["tag", "shape"],
        label: "Circle",
        default: {
            props: {},
            styles: {
                desktop: {
                    width: "80px",
                    height: "80px",
                    background: "#7dd3fc",
                }
            }
        },
    },
    {
        id: "image",
        type: ["tag", "image"],
        label: "Image",
        default: {
            props: {
                src: "",
            },
            styles: {
                desktop: {
                    width: "150px",
                    height: "100px",
                }
            }
        },
    },
    {
        id: "video",
        type: ["tag", "video"],
        label: "Video",
        default: {
            props: {
                src: "",
                is_external_url: false,
            },
            styles: {
                desktop: {
                    width: "150px",
                    height: "100px",
                }
            }
        },
    },
].map(element => {
    return {
        ...element,
        component: COMPONENTS[element.id] || null,
    }
});

export const ELEMENT_MAP = ELEMENTS.reduce((acc, item) => {
    acc[item.id] = item;

    return acc;
}, {});

export function getElementComponentById(id) {
    return ELEMENT_MAP[id] || null;
}

export function createBuilderCollection(astArray, context = {}) {
    return astArray.map((element) => createBuilderElement(element, context))
}

export function createBuilderElement(element, context) {
    const elementComponent = getElementComponentById(element.component_id);

    const props = {
        ...elementComponent.default.props,
        ...element.props,
    };

    // Generate merged styles
    const styles = {
        desktop: {
            ...elementComponent.default.styles.desktop,
            ...element.styles.desktop,
        },
    };

    const component = elementComponent ? elementComponent.component({
        props,
        styles: styles.desktop,
        context,
    }) : {};

    return {
        ...element,
        props,
        styles,
        type: elementComponent.type,
        label: elementComponent.label,
        element: component.element,
        update: component.update,
        onSelect: component.onSelect,
        onDeSelect: component.onDeSelect,
        constants: elementComponent.constants,
        getDefaultStyle(key) {
            return this.constants?.styles[key]
        },
        updateElement() {
            this.update({
                props: this.props,
                styles: this.styles.desktop,
                context
            });
        }
    }
}

export default {}