<template>
    <div ref="tourHolder">
        <Loading :loading="loading"/>
    </div>
</template>

<script>
    import Loading from "@/components/Loading";
    import HTMLParser from "@/utils/parser/html/dom";
    import BigPicture from "@/big-picture";
    import TourAnalytics from "@/analytics/tour";
    import ConversionWidget from "../conversion";
    import { domGetElement } from "@/utils/dom-element-path";

    import { addScriptTag, addStyles, injectCSS, convertToHttpUrl, previewLockedStyles, removeLockedStyles } from "@/utils/index";
    import { renderVariables } from "@/utils/variables";
    
    import GuideDrawer from "../guideDrawer";
    import { addScriptTagStringInHtml } from '../../../utils';

    function createIframe(url = null){
        const iframe = document.createElement("iframe");

        iframe.setAttribute("class", "story-guide-iframe");

        if(url) iframe.src = url;

        return iframe;
    }

    function constructStyleGuide(type, tourStyleGuide, guideStyleGuide, override = false) {
        let styleGuide = {};

        switch (type) {
            case "guide": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.guide || {}),
                        ...(override ? guideStyleGuide?.guide || {} : {}),
                    },
                    button: {
                        ...(tourStyleGuide?.button || {}),
                        ...(override ? guideStyleGuide?.button || {} : {})
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

                break;
            }
            case "lightbox": {
                styleGuide = {
                    lightbox: {
                        ...(tourStyleGuide?.lightbox || {}),
                        ...(override ? guideStyleGuide?.lightbox || {} : {}),
                    },
                    button: {
                        ...(tourStyleGuide?.button || {}),
                        ...(override ? guideStyleGuide?.button || {} : {})
                    }
                };

                break;
            }
            case "link": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.link || {}),
                        ...(override ? guideStyleGuide?.link || {} : {}),
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

                break;
            }
            case "input": {
                styleGuide = {
                    guide: {
                        ...(tourStyleGuide?.guide || {}),
                        ...(override ? guideStyleGuide?.guide || {} : {}),
                    },
                    glow: {
                        ...(tourStyleGuide?.glow || {}),
                    },
                    beacon: {
                        ...(tourStyleGuide?.beacon || {}),
                    }
                };

              break;
            }
        }

        return styleGuide;
    }

    function setupGuides(iframe, guides, options){
        const { screenType = "html", getTour, getTourOperator, getAnalyticsInstance, getAnalyticsData, getConversionState } = options;

        const mainDocument = iframe.contentDocument;
        const mainWindow = iframe.contentWindow;

        const storyGuide = new mainWindow.StoryGuide();

        const guidesCount = guides.length;

        const tour = getTour();

        guides.forEach((guide, index) => {
            const isFirstGuideIndex = index == 0;
            const isLastGuideIndex = guidesCount - 1 == index;
            
            if(screenType != "html"){
                try {
                    const guideDrawerInstance = new GuideDrawer({
                         doc: mainDocument,
                    });
    
                    const guideElement = guideDrawerInstance.createGuideElement({
                        elementId: guide.meta.guide_element_id,
                        getPositionProps: () => {
                            return {
                                elementX: guide.meta.guide_element_x,
                                elementY: guide.meta.guide_element_y,
                                elementWidth: guide.meta.guide_element_width,
                                elementHeight: guide.meta.guide_element_height,
                                containerX: guide.meta.guide_element_container_x,
                                containerY: guide.meta.guide_element_container_y,
                                containerWidth: guide.meta.guide_element_container_width,
                                containerHeight: guide.meta.guide_element_container_height,
                            }
                        },
                        getContainer: () => {
                            return mainDocument.body.querySelector("#storyscale-screen-element");
                        },
                        className: "",
                    });
    
                    mainDocument.body.appendChild(guideElement);
                } catch (error) {
                    console.log(error);
                }
            }

            const styleGuide = constructStyleGuide(
                guide.type,
                tour.style_guide, 
                guide.style_guide, 
                guide.meta.override_styles == "1"
            );

            const guideButtonTemplate = guide.type !== "link" 
            ? guide.meta.template || "single_button"
            : guide.meta.template || "prompt";

            const attachToElement = domGetElement(guide.meta.elementPath, mainDocument)

            let stepOptions = {
                id: guide.id,
                type: guide.type,
                original_id: guide.original_tour_guide_id,
                options: {
                    title: guide.meta.title,
                    description: renderVariables({
                        content: guide.meta.description,
                        variables: getTourOperator().variableMap
                    }),
                    overlay: guide.meta.overlay == "1" ? true : false,
                    
                    // Glow    
                    glowEnabled: guide.meta.glow_enabled == '1' ? true : false,
                    glowType: guide.meta.glow_type,
                    glowColor: guide.meta.glow_color,

                    // Beacon    
                    beaconEnabled: guide.meta.beacon_enabled == '1',
                    beaconPositionX: guide.meta.beacon_position_x,
                    beaconPositionY: guide.meta.beacon_position_y,
                    beaconType: guide.meta.beacon_type,
                    beaconColor: guide.meta.beacon_color,

                    showConfetti: guide.meta.showConfetti == "1" ? true : false,
                    template: guideButtonTemplate,

                    attachTo: {
                        element: attachToElement,
                        on: guide.meta.position || 'top'
                    },
                    getTotalNumberOfSteps(){
                        return getTourOperator().getTotalNumberOfSteps();
                    },
                    getCurrentStepNumber(){
                        return getTourOperator().getCurrentStepNumber();
                    }
                },
                styles: styleGuide,
                events: {
                    onShow(){
                        previewLockedStyles(attachToElement);

                        getTourOperator().onGuideShow(guide);
                        getAnalyticsInstance()?.addGuideViewEvent(getAnalyticsData());
                        getAnalyticsInstance()?.addToGuidesViewed(guide.id);
                        getAnalyticsInstance()?.startTimer(`guide_${guide.id}`);
                    },
                    onHide(){
                        getAnalyticsInstance()?.addTotalTimeEvent(getAnalyticsData(), {
                            id: `guide_${guide.id}`,
                            type: "guide",
                        });

                        setTimeout(() => {
                            removeLockedStyles(attachToElement);
                        }, 200);
                    }
                }
            };

            function gotToNextStep(instance){
                if(isLastGuideIndex){
                    const operator = getTourOperator();

                    if(operator.isLastScreen()){
                        instance.next();    
                    } else {
                        operator.next();
                        operator.startTour();
                    }
                } else {
                    instance.next();
                }
            }

            function gotToPreviousStep(instance){
                if(isFirstGuideIndex){
                    const operator = getTourOperator();

                    if(!operator.isFirstScreen()){
                        operator.back();
                        operator.startTourFromLast();
                    }
                } else {
                    instance.back();
                }
            }

            function resetInputFieldValues(){
                const operator = getTourOperator();

                try {
                    operator.screens.forEach(screen => {
                        const fields = [...screen.iframe.contentDocument.querySelectorAll("input, select, textarea")];

                        fields.forEach(field => field.value = "")
                    });
                } catch (error) {
                    console.log(error); 
                }
            }

            function restartTour(){
                const operator = getTourOperator();

                resetInputFieldValues();

                operator.start();
            }

            const buttons = {
                button1: {
                    text: guide.meta.button1_text,
                    onClick(instance){
                        if(getConversionState().isConversionActive) return;

                        if(guideButtonTemplate === "prompt") return;

                        const guideAction = guide.meta.button1_action || "next";

                        if(guideAction === "next"){
                            gotToNextStep(instance)
                        } else if(guideAction === "back"){
                            gotToPreviousStep(instance);
                        } else if(guideAction === "restart"){
                            restartTour();
                        }
                    }
                },
                button2: {
                    text: guide.meta.button2_text,
                    onClick(instance){
                        if(getConversionState().isConversionActive) return;

                        if(["prompt", "single_button"].includes(guideButtonTemplate)) return;

                        const guideAction = guideButtonTemplate === "single_button_with_link" ? "link" : guide.meta.button2_action || "back";

                        if(guideAction === "link"){
                            guide.meta.button2_external_url && window.open(convertToHttpUrl(guide.meta.button2_external_url), "_blank");
                        } else if(guideAction === "next"){
                            gotToNextStep(instance)
                        } else if(guideAction === "back"){
                            gotToPreviousStep(instance);
                        } else if(guideAction === "restart"){
                            restartTour();
                        }
                    }
                },
            };

            // Guide & lightbox options
            if(["guide", "lightbox"].includes(guide.type)){
                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        buttons,
                    }
                }
            } else if(guide.type === "link"){
                const linkDetails = {
                    type: guide.meta.link_type,
                    external_url: guide.meta.link_external_url || "",
                    external_url_target: guide.meta.link_external_url_target || "_self",
                    screen_id: Number(guide.meta.link_screen_id) || 0,
                    step_id: Number(guide.meta.link_step_id) || 0,
                    link_event: guide.meta.link_event || "click",
                    link_event_hover_delay: Number(guide.meta.link_event_hover_delay || 0) * 1000,
                };

                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        link: linkDetails,
                        onElementEvent(){
                            if(getConversionState().isConversionActive) return;

                            switch(this.link.type){
                                case "external": {
                                     if(this.link.external_url) window.open(convertToHttpUrl(this.link.external_url), this.link.external_url_target);
                                     break;
                                }
                                case "screen": {
                                     const operator = getTourOperator();
                                     operator.show(this.link.screen_id);
                                     operator.startTour();
                                     break;
                                }
                                case "step": {
                                     const operator = getTourOperator();
                                    
                                     if(operator.currentScreen.id === this.link.screen_id){
                                         operator.showGuide(this.link.step_id);
                                     } else {
                                         operator.show(this.link.screen_id);
                                         
                                         setTimeout(() => {
                                            if(this.link.step_id){
                                                operator.showGuide(this.link.step_id);
                                            } else {
                                                operator.startTour();
                                            }
                                         }, 500);
                                     }
                                }
                            }
                        }
                    }
                }
            } else if(guide.type === "input"){
                stepOptions = {
                    ...stepOptions,
                    options: {
                        ...stepOptions.options,
                        // Validation
                        validation: guide.meta.validation,
                        characterLength: guide.meta.character_length,
                        predefineValue: guide.meta.pre_define_value,
                        // Input field
                        placeholder: guide.meta.placeholder,
                        defaultValue: guide.meta.default_value,
                        // Selectbox
                        selectedValueOption: guide.meta.selected_value_option,
                        selectboxOptions: guide.meta.selectbox_options,
                        // Events
                        onSuccess(instance){
                          return new Promise((resolve) => {
                               gotToNextStep(instance);

                               resolve();
                          });
                        }
                    }
                }
            } else if(guide.type === "group"){
                // Please refactor
                const stepCollection = guide.guides.map((guideObject) => {
                    let options = {};

                    const guideButtonTemplate = guideObject.type !== "link" 
                    ? guideObject.meta.template || "single_button"
                    : guideObject.meta.template || "prompt";

                    const buttons = {
                        button1: {
                            text: guideObject.meta.button1_text,
                            onClick(){
                                let instance = storyGuide;

                                if(guideButtonTemplate === "prompt") return;

                                const guideAction = guideObject.meta.button1_action || "next";

                                if(guideAction === "next"){
                                    gotToNextStep(instance)
                                } else if(guideAction === "back"){
                                    gotToPreviousStep(instance);
                                } else if(guideAction === "restart"){
                                    restartTour();
                                }
                            }
                        },
                        button2: {
                            text: guideObject.meta.button2_text,
                            onClick(){
                                let instance = storyGuide;

                                if(["prompt", "single_button"].includes(guideButtonTemplate)) return;

                                const guideAction = guideButtonTemplate === "single_button_with_link" ? "link" : guideObject.meta.button2_action || "back";

                                if(guideAction === "link"){
                                    guide.meta.button2_external_url && window.open(convertToHttpUrl(guideObject.meta.button2_external_url), "_blank");
                                } else if(guideAction === "next"){
                                    gotToNextStep(instance)
                                } else if(guideAction === "back"){
                                    gotToPreviousStep(instance);
                                } else if(guideAction === "restart"){
                                    restartTour();
                                }
                            }
                        },
                    };

                    switch(guideObject.type){
                        case "guide": {                
                            options = {
                                type: "guide",
                                template: guideButtonTemplate,
                                buttons,
                            };

                            break;
                        }
                        case "link": {
                            const linkDetails = {
                                type: guideObject.meta.link_type,
                                external_url: guideObject.meta.link_external_url || "",
                                external_url_target: guideObject.meta.link_external_url_target || "_self",
                                screen_id: Number(guideObject.meta.link_screen_id) || 0,
                                step_id: Number(guideObject.meta.link_step_id) || 0,
                                link_event: guideObject.meta.link_event || "click",
                                link_event_hover_delay: Number(guideObject.meta.link_event_hover_delay || 0) * 1000,
                            };

                            options = {
                                type: "link",
                                template: guideButtonTemplate,
                                link: linkDetails,
                                onElementEvent(){
                                    switch(this.link.type){
                                        case "external": {
                                            if(this.link.external_url) window.open(convertToHttpUrl(this.link.external_url), this.link.external_url_target);
                                            break;
                                        }
                                        case "screen": {
                                            const operator = getTourOperator();
                                            operator.show(this.link.screen_id);
                                            operator.startTour();
                                            break;
                                        }
                                        case "step": {
                                            const operator = getTourOperator();
                                            
                                            if(operator.currentScreen.id === this.link.screen_id){
                                                operator.showGuide(this.link.step_id);
                                            } else {
                                                operator.show(this.link.screen_id);
                                         
                                                setTimeout(() => {
                                                    if(this.link.step_id){
                                                        operator.showGuide(this.link.step_id);
                                                    } else {
                                                        operator.startTour();
                                                    }
                                                }, 500);
                                            }
                                        }
                                    }
                                }
                            };

                            break;
                        }
                    }

                    const attachToElement = domGetElement(guideObject.meta.elementPath, mainDocument);

                    const styleGuide = constructStyleGuide(
                        guideObject.type,
                        tour.style_guide, 
                        guideObject.style_guide, 
                        guideObject.meta.override_styles == "1"
                    );

                    return {
                        type: "guide",
                        
                        trigger_hover: guideObject.meta.trigger_hover == '1' ? true : false,
                        shouldScrollToPosition: false,
                        preview: true,
                        template: guideObject.meta.template,
                        title: guideObject.meta.title,
                        description: guideObject.meta.description,
                        overlay: guideObject.meta.overlay == '1' ? true : false,
                    
                        // Glow    
                        glowEnabled: guideObject.meta.glow_enabled == '1',
                        glowType: guideObject.meta.glow_type,
                        glowColor: guideObject.meta.glow_color,

                        // Beacon    
                        beaconEnabled: guideObject.meta.beacon_enabled == '1',
                        beaconPositionX: guideObject.meta.beacon_position_x,
                        beaconPositionY: guideObject.meta.beacon_position_y,
                        beaconType: guideObject.meta.beacon_type,
                        beaconColor: guideObject.meta.beacon_color,

                        attachTo: {
                            element: attachToElement,
                            on: guideObject.meta.position || 'top'
                        },

                        buttons: {
                            button1: {
                                text: guideObject.meta.button1_text,
                                action: guideObject.meta.button1_action
                            },
                            button2: {
                                text: guideObject.meta.button2_text,
                                external_url: guideObject.meta.button2_external_url,
                                action: guideObject.meta.button2_action
                            }
                        },
                        getTotalNumberOfSteps(){
                            return getTourOperator().getTotalNumberOfSteps();
                        },
                        getCurrentStepNumber(){
                            return getTourOperator().getCurrentStepNumber();
                        },
                        styles: styleGuide,

                        ...options,
                    }
                }).filter(Boolean);
                
                stepOptions = {
                    ...stepOptions,
                    options: {
                        steps: stepCollection,
                    }
                }
            }

            storyGuide.addStep({
                ...stepOptions,
            });
        });

        return storyGuide;
    }

    function showIframe(iframe){
        iframe.classList.add("active");
    }

    function hideIframe(iframe){
        iframe.classList.remove("active");
    }

    export default {
        props: [],
        components: {
            Loading,
        },
        data(){
            return {
                loading: true,
                operator: {},

                tour: {},
                customerData: {},
                delivery_type: "share",
                tour_type: "published",

                // Conversion
                conversion: null,
                isConversionActive: false,
                conversionInstance: null,
            }
        },
        created () {
            if(this.getParameterByName('embed')){
                this.delivery_type = "embed"
            }

            if(this.getParameterByName('type') == "personalised"){
                this.tour_type = "personalised"
            }

            injectCSS(`
                .story-guide-iframe{
                    width: 100%;
                    height: 100%;
                    border: none;
                    position: absolute;
                    left: -100%;
                }
                .story-guide-iframe.active{
                    left: 0px;
                }
            `);
        },
        destroyed(){
            this.removePreviews();
        },
        methods: {
            // Tour
            init({ tour, variableMap }){
                return new Promise((initResolve) => {
                    this.removePreviews();

                    const self = this;

                    this.tour = tour;
                    this.conversion = tour.conversion;

                    // Analytics
                    this.trackAnalytics = this.initAnalytics({
                        platform_customer_id: tour.platform_customer_id
                    });

                    // On page close / reload
                    window.onbeforeunload = () => {
                        this.trackAnalytics?.onClose(this.analyticsData());
                    }

                    const operator = {
                        screens: [],
                        variableMap,
                        currentScreen: null,
                        currentIndex: null,
                        totalScreens(){
                            return this.screens.length;
                        },
                        isFirstScreen(){
                            return this.currentIndex == 0;
                        },
                        isLastScreen(){
                            return this.currentIndex == (this.totalScreens() - 1);
                        },
                        getScreen(screenId){
                            let screenIndex = null;

                            const screen = this.screens.find((item, index) => {
                                if(screenId == item.id){
                                    screenIndex = index;  

                                    return true;
                                }

                                return false;
                            });

                            return {
                                index: screenIndex,
                                screen
                            }
                        },
                        show(screenId, captureScreenView = true, shouldStartTour = true){
                            const { screen, index } = this.getScreen(screenId);

                            if(screen){
                                if(this.currentScreen){
                                    // Hide current step
                                    if(this.currentScreen.tourGuide.currentStep){
                                        this.currentScreen.tourGuide.currentStep.hide()
                                    }

                                    this.currentScreen.hide();
                                }

                                this.currentIndex = index;
                                this.currentScreen = screen;
                                this.currentScreen.show(captureScreenView);

                                shouldStartTour && this.startTour();
                            }
                        },
                        showGuide(stepId){
                            const { step } = this.currentScreen.tourGuide.getStep(stepId);
                        
                            if(step){
                                this.currentScreen.tourGuide.showStep(stepId);
                            } else {
                                this.startTour();
                            }
                        },
                        startTour(){
                            if(this.currentScreen){
                                setTimeout(() => this.currentScreen.tourGuide?.start(), 500);
                            }
                        },
                        startTourFromLast(){
                            if(this.currentScreen){
                                setTimeout(() => this.currentScreen.tourGuide?.startFromLast(), 500);
                            }
                        },
                        next(){
                            if (this.currentScreen && !this.isLastScreen()) {
                                const nextIndex = this.currentIndex + 1;
                                const screen = this.screens[nextIndex];

                                if (screen) {
                                    this.currentIndex = nextIndex;
                                    this.currentScreen.hide();
                                    this.currentScreen.tourGuide.complete();

                                    this.currentScreen = screen;
                                    this.currentScreen.show();
                                }
                            }
                        },
                        back(){
                            if (this.currentScreen) {
                                const backIndex = this.currentIndex - 1;
                                const screen = this.screens[backIndex]

                                if (screen) {
                                    this.currentScreen.hide();
                                    this.currentScreen.tourGuide.complete();

                                    this.currentIndex = backIndex;
                                    this.currentScreen = screen;
                                    this.currentScreen.show();
                                }
                            }
                        },
                        start(){
                            const screen = this.screens[0];

                            if(screen){
                                if(this.currentScreen){
                                    this.currentScreen.hide();
                                    this.currentScreen.tourGuide.complete();
                                }
                            
                                this.currentIndex = 0;
                                this.currentScreen = screen;
                                this.currentScreen?.show();
                                this.startTour();
                            }
                        },
                        getTotalNumberOfSteps(){
                            return this.screens.reduce((steps, screen) => steps + screen.tourGuide.totalSteps(), 0)
                        },
                        getCurrentStepNumber(){
                            let step = 0;
                            
                            for(const screen of this.screens){
                                if(this.currentScreen?.id == screen.id){
                                    step += screen.tourGuide?.currentIndex != null ? screen.tourGuide?.currentIndex + 1 : 0;
                                    
                                    break;
                                } else {
                                    step += screen.tourGuide.totalSteps();
                                }
                            }

                            return step;
                        },
                        getCurrentState(){
                            const currentScreenId = this.currentScreen?.id;
                            const currentStepId = this.currentScreen?.tourGuide?.currentStep?.id;
                            const currentScreenIframe = this.currentScreen?.iframe;

                            return {
                                screenId: currentScreenId,
                                stepId: currentStepId,
                                iframe: currentScreenIframe,
                            }
                        },
                        // Events
                        onGuideShow(guide){
                            self.updateTourHistory(guide);
                            self.setupNavigationBasedConversion();
                        }
                    };

                    self.operator = operator;

                    initResolve();
                });
            },
            addScreen(screen){
                return new Promise(async (resolve, reject) => {
                    try {
                        const self = this;

                        const iframe = createIframe();

                        self.$refs.tourHolder.appendChild(iframe);

                        if(screen.isHtmlRender){
                            await HTMLParser.renderHtml({
                                html: screen.html,
                                iframe,
                                scripts: [
                                    process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js"
                                ],
                            },(element) => {
                                if(element.tagName == "TEXT"){
                                    element.innerText = renderVariables({
                                        content: element.innerText,
                                        variables: self.operator.variableMap,
                                    })
                                }
                            });
                        } else {
                            await HTMLParser.renderDocument({
                                ast: screen.ast,
                                iframe
                            }, (element) => {
                                if(element.tagName == "TEXT"){
                                    element.innerText = renderVariables({
                                        content: element.innerText,
                                        variables: self.operator.variableMap,
                                    })
                                }
                            });
                        }

                        const mainDocument = iframe.contentDocument;

                        addStyles(mainDocument, `html, body { margin: 0; padding: 0;}`);

                        const checkStoryGuideLoaded = () => {
                            const mainWindow = iframe.contentWindow;

                            if(mainWindow.StoryGuide){
                                const tourGuide = setupGuides(iframe, screen.guides, {
                                    screenType: screen.type || "html",
                                    getTour(){
                                        return self.tour;
                                    },
                                    getTourOperator(){
                                        return self.operator;
                                    },
                                    getAnalyticsInstance(){
                                        return self.trackAnalytics;
                                    },
                                    getAnalyticsData(){
                                        return self.analyticsData();
                                    },
                                    getConversionState(){
                                        return {
                                            isConversionActive: self.isConversionActive
                                        }
                                    }
                                });

                                self.operator.screens.push({
                                    id: screen.id,
                                    original_id: screen.original_tour_screen_id,
                                    name: screen.name,
                                    type: screen.type,
                                    iframe,
                                    show(captureScreenView = true){
                                        showIframe(this.iframe);

                                        try {
                                            if(captureScreenView){
                                                self.trackAnalytics.addScreenViewEvent(self.analyticsData());
                                                self.trackAnalytics?.startTimer(`screen_${this.id}`);
                                            }
                                        } catch (error) { console.log(error); }
                                    },
                                    hide(){
                                        hideIframe(this.iframe);

                                        try {
                                            self.trackAnalytics.addTotalTimeEvent(self.analyticsData(), {
                                                id: `screen_${this.id}`,
                                                type: "screen",
                                            });
                                        } catch (error) { console.log(error); }
                                    },
                                    tourGuide,
                                    order: screen.order,
                                });

                                self.operator.screens = self.operator.screens.sort((screenOne , screenTwo) => screenOne.order - screenTwo.order)
                                self.trackAnalytics?.setTotalGuides(self.operator.getTotalNumberOfSteps());
                                
                                resolve();
                            } else {
                                window.requestAnimationFrame(checkStoryGuideLoaded);
                            }
                        }

                        window.requestAnimationFrame(checkStoryGuideLoaded);
                    } catch (err) {
                        reject(err);
                    }
                })      
            },
            addScreenOptimized(screen){
                return new Promise(async (resolve, reject) => {
                    try {
                        const self = this;

                        const content = addScriptTagStringInHtml(process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js", screen.html);
                        
                        const htmlBlob = new Blob([content], {type: "text/html"});

                        const htmlURL = URL.createObjectURL(htmlBlob);

                        const iframe = createIframe(htmlURL);

                        self.$refs.tourHolder.appendChild(iframe);

                        await HTMLParser.renderIframe({
                            iframe,
                        },(element) => {
                            if(element.tagName == "TEXT"){
                                element.innerText = renderVariables({
                                    content: element.innerText,
                                    variables: self.operator.variableMap,
                                });
                            }
                        });

                        const mainDocument = iframe.contentDocument;

                        const checkStoryGuideLoaded = () => {
                            const mainWindow = iframe.contentWindow;

                            if(mainWindow.StoryGuide){
                                const tourGuide = setupGuides(iframe, screen.guides, {
                                    screenType: screen.type || "html",
                                    getTour(){
                                        return self.tour;
                                    },
                                    getTourOperator(){
                                        return self.operator;
                                    },
                                    getAnalyticsInstance(){
                                        return self.trackAnalytics;
                                    },
                                    getAnalyticsData(){
                                        return self.analyticsData();
                                    },
                                    getConversionState(){
                                        return {
                                            isConversionActive: self.isConversionActive
                                        }
                                    }
                                });

                                self.operator.screens.push({
                                    id: screen.id,
                                    original_id: screen.original_tour_screen_id,
                                    name: screen.name,
                                    type: screen.type,
                                    iframe,
                                    show(captureScreenView = true){
                                        showIframe(this.iframe);

                                        try {
                                            if(captureScreenView){
                                                self.trackAnalytics.addScreenViewEvent(self.analyticsData());
                                                self.trackAnalytics?.startTimer(`screen_${this.id}`);
                                            }
                                        } catch (error) { console.log(error); }
                                    },
                                    hide(){
                                        hideIframe(this.iframe);

                                        try {
                                            self.trackAnalytics.addTotalTimeEvent(self.analyticsData(), {
                                                id: `screen_${this.id}`,
                                                type: "screen",
                                            });
                                        } catch (error) { console.log(error); }
                                    },
                                    tourGuide,
                                    order: screen.order,
                                });

                                self.operator.screens = self.operator.screens.sort((screenOne , screenTwo) => screenOne.order - screenTwo.order)
                                self.trackAnalytics?.setTotalGuides(self.operator.getTotalNumberOfSteps());
                                
                                resolve();
                            } else {
                                window.requestAnimationFrame(checkStoryGuideLoaded);
                            }
                        }

                        iframe.addEventListener('load', () => {
                            addStyles(mainDocument, `html, body { margin: 0; padding: 0;}`);

                            window.requestAnimationFrame(checkStoryGuideLoaded);
                        }, true);
                    } catch (err) {
                        reject(err);
                    }
                })     
            },
            renderTour(tour, screens){
                return new Promise((resolve, reject) => {
                    const self = this;

                    this.tour = tour;
                    this.conversion = tour.conversion;

                    // Analytics
                    this.trackAnalytics = this.initAnalytics({
                        platform_customer_id: tour.platform_customer_id
                    });

                    // On page close / reload
                    window.onbeforeunload = () => {
                        this.trackAnalytics?.onClose(this.analyticsData());
                    }

                    // BigPicture
                    BigPicture.detailsPromise().then((customerData) => {
                        this.customerData = customerData;
                    }).finally(() => {
                        const operator = {
                            screens: [],
                            currentScreen: null,
                            currentIndex: null,
                            totalScreens(){
                                return this.screens.length;
                            },
                            isFirstScreen(){
                                return this.currentIndex == 0;
                            },
                            isLastScreen(){
                                return this.currentIndex == (this.totalScreens() - 1);
                            },
                            getScreen(screenId){
                                let screenIndex = null;

                                const screen = this.screens.find((item, index) => {
                                    if(screenId == item.id){
                                        screenIndex = index;  

                                        return true;
                                    }

                                    return false;
                                });

                                return {
                                    index: screenIndex,
                                    screen
                                }
                            },
                            show(screenId){
                                const { screen, index } = this.getScreen(screenId);

                                if(screen){
                                    if(this.currentScreen){
                                        // Hide current step
                                        if(this.currentScreen.tourGuide.currentStep){
                                            this.currentScreen.tourGuide.currentStep.hide()
                                        }

                                        this.currentScreen.hide();
                                    }

                                    this.currentIndex = index;
                                    this.currentScreen = screen;
                                    this.currentScreen.show();
                                }
                            },
                            showGuide(stepId){
                                const { step } = this.currentScreen.tourGuide.getStep(stepId);
                        
                                if(step){
                                    this.currentScreen.tourGuide.showStep(stepId);
                                } else {
                                    this.startTour();
                                }
                            },
                            startTour(){
                                if(this.currentScreen){
                                    setTimeout(() => this.currentScreen.tourGuide?.start(), 500);
                                }
                            },
                            startTourFromLast(){
                                if(this.currentScreen){
                                    setTimeout(() => this.currentScreen.tourGuide?.startFromLast(), 500);
                                }
                            },
                            next(){
                                if (this.currentScreen && !this.isLastScreen()) {
                                    const nextIndex = this.currentIndex + 1;
                                    const screen = this.screens[nextIndex];

                                    if (screen) {
                                        this.currentIndex = nextIndex;
                                        this.currentScreen.hide();
                                        this.currentScreen.tourGuide.complete();

                                        this.currentScreen = screen;
                                        this.currentScreen.show();
                                    }
                                }
                            },
                            back(){
                                if (this.currentScreen) {
                                    const backIndex = this.currentIndex - 1;
                                    const screen = this.screens[backIndex]

                                    if (screen) {
                                        this.currentScreen.hide();
                                        this.currentScreen.tourGuide.complete();

                                        this.currentIndex = backIndex;
                                        this.currentScreen = screen;
                                        this.currentScreen.show();
                                    }
                                }
                            },
                            start(){
                                const screen = this.screens[0];

                                if(screen){
                                    if(this.currentScreen){
                                        this.currentScreen.hide();
                                        this.currentScreen.tourGuide.complete();
                                    }
                                
                                    this.currentIndex = 0;
                                    this.currentScreen = screen;
                                    this.currentScreen?.show();
                                    this.startTour();
                                }
                            },
                            getTotalNumberOfSteps(){
                                return this.screens.reduce((steps, screen) => steps + screen.tourGuide.totalSteps(), 0)
                            },
                            getCurrentStepNumber(){
                                let step = 0;
                                
                                for(const screen of this.screens){
                                    if(this.currentScreen?.id == screen.id){
                                        step += screen.tourGuide?.currentIndex != null ? screen.tourGuide?.currentIndex + 1 : 0;
                                        
                                        break;
                                    } else {
                                        step += screen.tourGuide.totalSteps();
                                    }
                                }

                                return step;
                            },
                            getCurrentState(){
                                const currentScreenId = this.currentScreen?.id;
                                const currentStepId = this.currentScreen?.tourGuide?.currentStep?.id;
                                const currentScreenIframe = this.currentScreen?.iframe;

                                return {
                                    screenId: currentScreenId,
                                    stepId: currentStepId,
                                    iframe: currentScreenIframe,
                                }
                            },
                            // Events
                            onGuideShow(){
                                self.setupNavigationBasedConversion();
                            }
                        };

                        self.operator = operator;

                        const promises = screens.map((screen) => {
                            return new Promise(async(resolve, reject) => {
                                try {
                                    const iframe = createIframe();

                                    document.body.appendChild(iframe);

                                    await HTMLParser.renderDocument({
                                        ast: screen.ast,
                                        iframe
                                    },(element) => {
                                        if(element.tagName == "TEXT"){
                                            element.innerText = renderVariables({
                                                content: element.innerText,
                                                variables: variableMap,
                                            })
                                        }
                                    });

                                    const mainDocument = iframe.contentDocument;

                                    addScriptTag(mainDocument, process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js");

                                    addStyles(mainDocument, `html, body { margin: 0; padding: 0;} body {background: #fff}`);

                                    mainDocument.addEventListener("story-guide:init", () => {
                                        const tourGuide = setupGuides(iframe, screen.guides, {
                                            screenType: screen.type || "html",
                                            getTour(){
                                                return self.tour;
                                            },
                                            getTourOperator(){
                                                return self.operator;
                                            },
                                            getAnalyticsInstance(){
                                                return self.trackAnalytics;
                                            },
                                            getAnalyticsData(){
                                                return self.analyticsData();
                                            },
                                            getConversionState(){
                                                return {
                                                    isConversionActive: self.isConversionActive
                                                }
                                            }
                                        });

                                        operator.screens.push({
                                            id: screen.id,
                                            iframe,
                                            show(){
                                                this.iframe.classList.add("active");
                                            },
                                            hide(){
                                                this.iframe.classList.remove("active");
                                            },
                                            tourGuide,
                                            order: screen.order,
                                        });
                                        
                                        resolve();
                                    });
                                } catch (err) {
                                    reject(err)
                                }
                            })
                        });

                        return Promise.all(promises).then(() => {
                            self.loading = false;
                            self.operator.screens = self.operator.screens.sort((screenOne , screenTwo) => screenOne.order - screenTwo.order)
                            self.trackAnalytics?.setTotalGuides(self.operator.getTotalNumberOfSteps());
                            resolve(self.operator);
                        }).catch(err => reject(err));
                    });
                })
            },
            startTour(){
                this.loading = false;
                this.operator?.start();

                // Create visitor event
                if(this.trackAnalytics) {
                    BigPicture.detailsPromise().then((customerData) => {
                        this.customerData = customerData;
                    }).finally(() => {
                        this.trackAnalytics?.startTimer(`tour_${this.analyticsData().tour.id}`);
                        this.trackAnalytics?.addVistorEvent(this.analyticsData());
                    });
                }

                this.setupTimeBasedConversion();
            },
            showScreen(screenId){
                this.operator?.show(screenId, false, false);
            },
            removePreviews(){
                this.operator?.screens?.forEach((screen) => screen.iframe.remove())
            },
            // Conversion
            isConversionEnabled(){
              return Boolean(this.tour.conversion_enabled && this.conversion && this.conversion.primary_cta);
            },
            onConversionSubmit(){
                this.isConversionActive = false;

                this.trackAnalytics?.addConversionEvent(this.analyticsData());

                setTimeout(() => {
                    this.conversionInstance.hide();
                }, 1000);
            },
            onConversionClose(){
                this.isConversionActive = false;
                this.conversionInstance.hide();
            },
            setupTimeBasedConversion(){
                const self = this;

                if(this.isConversionEnabled() && this.conversion.trigger_type === "time-based"){
                    const waitTime = this.conversion.wait_time;
                    const ctaCode = this.conversion.primary_cta.text;
                    const conversionType = this.conversion.conversion_type;
                    const isPaywallConversion = conversionType == "paywall";

                    self.conversionInstance = new ConversionWidget({
                        root: document.body,
                        onClose(){
                            self.onConversionClose();
                        },
                        onSubmit(){
                            self.onConversionSubmit();
                        },
                        closeButton: !isPaywallConversion,
                    });

                    setTimeout(() => {
                        self.isConversionActive = true;
                        self.conversionInstance.show(ctaCode);
                    }, waitTime * 1000);
                }
            },
            setupNavigationBasedConversion(){
                const self = this;

                if(this.isConversionEnabled() && this.conversion.trigger_type === "navigation"){
                    const { screenId, stepId } = this.operator.getCurrentState();
                    const { tour_screen_id, tour_guide_id } = this.conversion;

                    if(screenId === tour_screen_id && stepId === tour_guide_id){
                        const ctaCode = this.conversion.primary_cta.text;

                        self.conversionInstance = new ConversionWidget({
                            root: document.body,
                            onClose(){
                                self.onConversionClose();
                            },
                            onSubmit(){
                                self.onConversionSubmit();
                            }
                        });

                        setTimeout(() => {
                            self.isConversionActive = true;
                            self.conversionInstance.show(ctaCode);
                        }, 100);
                    }
                }
            },
            // History
            updateTourHistory(guide){
                try {
                    const currentStepNumber = this.operator.getCurrentStepNumber();
                    const title = this.slugify(guide.meta.name ? guide.meta.name : guide.meta.title);
                    const type = guide.type;
                    const stepQueryParam = [currentStepNumber, type, title].filter(Boolean).join("|")
    
                    this.setQueryParameter("step", stepQueryParam);
                } catch (error) {
                    console.log(error);
                }
            },
            // Anayltics
            initAnalytics(data){
                return new TourAnalytics({
                    platform_customer_id: data.platform_customer_id,
                    dev: false,
                    logging: true,
                });
            },
            analyticsData() {
                const property = this.tour_type == "personalised" ? "id" : "original_id";
                const tourProperty = this.tour_type == "personalised" ? "id" : "original_tour_id";

                return {
                    tour: {
                        id: parseInt(this.tour?.[tourProperty]),
                        name: this.tour.name,
                        tour_type: this.tour_type
                    },
                    tour_screen: {
                        id: parseInt(this.operator.currentScreen?.[property]),
                        name: this.operator.currentScreen?.name || "",
                        screen_type: this.operator.currentScreen?.type || "",
                    },
                    tour_guide: {
                        id: parseInt(this.operator.currentScreen?.tourGuide?.currentStep?.[property] || 0),
                        guide_type: this.operator.currentScreen?.tourGuide?.currentStep?.type || ""
                    },
                    delivery_type: this.delivery_type,
                    customer: this.customerData,
                }
            },
        }
    }
</script>