<template>
    <div>
        <Loading :loading="loading"/>
        
        <div v-show="!isPasswordProtected && !loading">
            <TourRendererOptimized ref="tourRenderer"  v-show="!responsiveMode"/>
            <TourResponsiveRendererOptimized ref="tourResponsiveRenderer" v-show="isResponsiveTourEnabled && responsiveMode" :responsiveMode="responsiveMode"/>
    
            <div v-if="!isResponsiveTourEnabled && responsiveMode" class="no-responsive-tour">
                <p>
                    Please view in desktop mode <br/> to get a better tour experience
                </p>
            </div>
        </div>
        <div v-if="isPasswordProtected" class="outer_tour_holder">
            <div style="max-width: 600px; margin: 0 auto;" class="no-responsive-tour">
                <div style="width: 100%;">
                    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15rem" height="15rem"><path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z"/></svg>
                </div>
                <div style="width: 50%; max-width: 400px;">
                    <h1 class="ss_pass_main_heading">Protected Page</h1>
                    <div>
                        <label style="display: block; color: #000; padding-bottom: 10px;">Password</label>
                    </div>
                    <form @submit="validateTour">
                        <input id="share_password" type="password" placeholder="Password" style="padding: 10px 15px; border: 1px solid #ddd; border-radius: 4px; width: 100%; margin-bottom: 10px;" class="form-control w-100" v-model="password"  />
                        <button style="padding: 10px 15px; border-radius: 4px; width: 100%;" class="btn btn-primary" type="submit">Submit</button>
                    </form>

                </div>
            </div>
        </div>

        <div id="main" class="col-xl-12" v-if="!isTourActive">
            <NotFound />
        </div>
    </div>
</template>
<script>
    import Loading from "../../components/Loading.vue";
    import TourRenderer from "../components/renderer";
    import TourRendererOptimized from "../components/renderer/optimized";
    import TourResponsiveRendererOptimized from "../components/renderer/responsive/optimized";
    import { generateVariableMap } from "@/utils/variables";
    import systemVariables from "@/constants/personalization-variables";
    import HTMLParser from "@/utils/parser/html/dom";
    import NotFound from "../../components/NotFound.vue";

    import { queryParams } from '../../utils';
    import base64 from "base-64";
    
    export default {
        layout: 'preview',
        props: ["tourId"],
        components: {
            Loading,
            TourRendererOptimized,
            TourResponsiveRendererOptimized,
            NotFound
        },
        data () {
            return {
                loading: false,
                loaded: false,
                tourPublicId: 0,
                tour: {},
                screens: [],
                responsiveMode: false,

                defaultPageTitle: document.title,

                screenResources: [],
                screenCount:0,
                minOrder:0,
                screensLoaded: 0,
                responsiveScreensLoaded: 0,

                screensResourcesLoaded: false,
                isTourLoadingStarted: false,
                isResponsiveTourLoadingStarted: false,

                password: "",
                type: "personalised",
                isPasswordProtected: false,

                isTourActive: true
            }
        },
        created(){
            this.tourPublicId = this.tourId;

            this.createMediaQueryListener();
        },
        mounted(){
            if(this.tourPublicId) return this.showTourPreview(this.tourPublicId);
        },
        destroyed() {
            this.updatePageTitle(this.defaultPageTitle)
        },
        methods: {
            //Validate Tour
            async validateTour() {
                this.loading = true;

                const payload = {
                    "password": this.password,
                    "type": this.type,
                    "id": this.tour?.id
                };

                try {
                    await this.axios.post(`/frontend/tour/share/validate`, payload);
                    
                    this.isPasswordProtected = false;
                    this.$refs.tourRenderer.startTour();
                    this.$refs.tourResponsiveRenderer.startTour();

                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } catch (error) {
                    console.log(error);
                    this.toast("Invalid credential", "e");
                    this.loading = false;
                } 
            },
            // Tour
            getTour(tourPublicId){
                const is_personalized = this.getParameterByName("type") == "personalised";

                return this.axios.get(`/frontend/tour/view/${tourPublicId}`, {
                    params: {
                        with_screens: true,
                        with_guides: true,
                        with_style_guide: true,
                        with_conversion: true,
                        with_embed_settings: true,
                        is_personalized,
                    }
                }).then((res) => {
                    const { data } = res.data;
                    return data
                }).catch((err) => {
                    console.error(err);
                    // this.toast("Tour not found", "e");
                });
            },
            // Screen
            getScreenContent(screenPublicId){
                return this.axios.get(`/frontend/tour/tour-screen/view-content/${screenPublicId}`).then((res) => {
                    const { data } = res.data;

                    return data;
                }).catch((err) => {
                    console.log(err);
                })
            },
            async fetchScreenContent(fileUrl, screenType = "html"){
                if(screenType == "html"){
                    return fetch(fileUrl).then(res => res.text())
                }

                return HTMLParser.toString(HTMLParser.generateAST({
                    type: screenType,
                    content_url: fileUrl,
                }));
            },
            // Guide
            getGuides(screenPublicId){
                return this.axios.get(`/frontend/tour/guide/show-all/${screenPublicId}`, {
                    params: {
                        with_style_guide: true,
                    }
                }).then((res) => {
                    const { data } = res.data;

                    return data;
                }).catch((err) => {
                    console.error(err);
                });
            },
            // Variables
            async getVariables(tourPublicId) {
                try {
                    const is_personalized = this.getParameterByName("type") == "personalised";

                    const response = await this.axios.get(`/frontend/tour/variable/show-all/${tourPublicId}`, {
                       params: {
                        is_personalized,
                       }
                    });
                    
                    const { data } = response

                    return data.data
                } catch (error) {
                    console.log(error)
                    return []
                }
            },
            async getGeneratedVariableMap(tourPublicId) {
                const customVariables = await this.getVariables(tourPublicId)
                const variableMap = generateVariableMap([...systemVariables, ...customVariables])

                return variableMap
            },
            // Preview
            showTourPreview(tourId){
                if(!tourId) return;

                this.getTour(tourId).then(async (data) => {
                    const { tour, screens } = data;

                    this.tour = tour;

                    this.isPasswordProtected = this.tour?.shared?.is_password_protected || false;

                    this.updatePageTitle(`${tour.name} - Storyscale`);

                    const variableMap = await this.getGeneratedVariableMap(tour.public_id);

                    Promise.all([
                        this.$refs.tourRenderer.init({ tour, variableMap }),
                        this.$refs.tourResponsiveRenderer.init({ tour, variableMap }),
                    ]).then(() => {
                        this.setupIntegrations();

                        this.screenCount = screens.length;
                        this.screensLoaded = 0;
                        this.responsiveScreensLoaded = 0;
                        this.minOrder = Math.min(...screens.map((screen) => screen.order));

                        this.screenResources = screens;
                        this.screensResourcesLoaded = true;
                    
                        this.loaded = true;

                        !this.responsiveMode 
                        ? this.loadTour() 
                        : this.loadResponsiveTour();
                    });
                }).catch(() => {
                    this.isTourActive = false;
                    this.loading = false;
                    this.$refs.tourRenderer.loading = false;
                });
            },
            loadTour(){
               try {
                 if(this.isTourLoadingStarted || !this.screensResourcesLoaded) return;
                  this.isTourLoadingStarted = true;

                  this.$refs.tourRenderer.setScreens(this.screenResources);

                  !this.isPasswordProtected && this.$refs.tourRenderer.startTour();
               } catch (error) {
                 console.log(error)
               }
            },
            loadResponsiveTour(){
                if(this.isResponsiveTourLoadingStarted || !this.screensResourcesLoaded) return;

                this.isResponsiveTourLoadingStarted = true;

                this.$refs.tourResponsiveRenderer.setScreens(this.screenResources);

                !this.isPasswordProtected && this.$refs.tourResponsiveRenderer.startTour();
            },
            render(){
                this.$refs.tourRenderer.renderTour(this.tour, this.screens).then(() => {
                    this.$refs.tourRenderer.startTour();
                });
            },
            updatePageTitle(title = ""){
                document.title = title;
            },
            setupIntegrations(){
                const params = queryParams();

                let parentPageCookies = {};

                try {
                    parentPageCookies = JSON.parse(base64.decode(params.__stappck__));
                } catch (error) {}

                try {
                    if(this.tour.embed_settings.integration_include_script){
                        const self = this;

                        function onTourViewEvent(callback){
                            if(typeof(callback) != "function") throw new Error("[onTourViewEvent]: Callback should be a function");

                            try {
                                self.$refs.tourRenderer.trackAnalytics.onEvent("visitor", (payload) => {
                                    const data = {
                                        event_type: payload.event_type,
                                        device: payload.device,
                                        geo: payload.geo,
                                        guid: payload.guid,
                                        tour_id: payload.tour_id,
                                        tour_name: payload.tour.name,
                                        tour_url: payload.tour_url,
                                        screen_id: payload.tour_screen_id,
                                        screen_name: payload.tour_screen.name,
                                        cookies: parentPageCookies,
                                        timestamo: (new Date()).toISOString(),
                                    }

                                    callback && callback(data)
                                });
                            } catch (error) {
                                console.log('[Storyscale inject]: ', error.message);
                            }
                        }
                        
                        function onGuideViewEvent(callback){
                            if(typeof(callback) != "function") throw new Error("[onGuideViewEvent]: Callback should be a function");

                            try {
                                self.$refs.tourRenderer.trackAnalytics.onEvent("guide_view", (payload) => {
                                    const data = {
                                        event_type: payload.event_type,
                                        device: payload.device,
                                        geo: payload.geo,
                                        guid: payload.guid,
                                        tour_id: payload.tour_id,
                                        tour_name: payload.tour.name,
                                        tour_url: payload.tour_url,
                                        guide_id: payload.tour_guide_id,
                                        guide_type: payload.tour_guide.guide_type,
                                        screen_id: payload.tour_screen_id,
                                        screen_name: payload.tour_screen.name,
                                        cookies: parentPageCookies,
                                        timestamo: (new Date()).toISOString(),
                                    }

                                    setTimeout(() => {
                                        callback && callback(data)
                                    }, 100);
                                });
                            } catch (error) {
                                console.log('[Storyscale inject]: ', error.message);
                            }
                        }

                        function onScreenViewEvent(callback){
                            if(typeof(callback) != "function") throw new Error("[onScreenViewEvent]: Callback should be a function");

                            try {
                                self.$refs.tourRenderer.trackAnalytics.onEvent("screen_view", (payload) => {
                                    const data = {
                                        event_type: payload.event_type,
                                        device: payload.device,
                                        geo: payload.geo,
                                        guid: payload.guid,
                                        tour_id: payload.tour_id,
                                        tour_name: payload.tour.name,
                                        tour_url: payload.tour_url,
                                        screen_id: payload.tour_screen_id,
                                        screen_name: payload.tour_screen.name,
                                        cookies: parentPageCookies,
                                        timestamo: (new Date()).toISOString(),
                                    }

                                    setTimeout(() => {
                                        callback && callback(data)
                                    }, 100);
                                });
                            } catch (error) {
                                console.log('[Storyscale inject]: ', error.message);
                            }
                        }

                        function onTourComplete(callback){
                            if(typeof(callback) != "function") throw new Error("[onTourComplete]: Callback should be a function");

                            try {
                                self.$refs.tourRenderer.trackAnalytics.onEvent("tour_completion", (payload) => {
                                    const data = {
                                        event_type: payload.event_type,
                                        device: payload.device,
                                        geo: payload.geo,
                                        guid: payload.guid,
                                        tour_id: payload.tour_id,
                                        tour_name: payload.tour.name,
                                        tour_url: payload.tour_url,
                                        screen_id: payload.tour_screen_id,
                                        screen_name: payload.tour_screen.name,
                                        guide_viewed: payload.guide_viewed,
                                        total_guides: payload.total_guides,
                                        time_spent: payload.time_spent,
                                        total_clicks: payload.total_clicks,
                                        cookies: parentPageCookies,
                                        timestamo: (new Date()).toISOString(),
                                    }

                                    setTimeout(() => {
                                        callback && callback(data)
                                    }, 100);
                                });
                            } catch (error) {
                                console.log('[Storyscale inject]: ', error.message);
                            }
                        }

                        this.injectCodeSnippet(
                            this.tour.embed_settings.integration_include_script,
                            this.tour.embed_settings.integration_include_script_type,
                            {
                                $cookies: parentPageCookies,
                                onTourViewEvent: (...args) => onTourViewEvent(...args),
                                onGuideViewEvent: (...args) => onGuideViewEvent(...args),
                                onScreenViewEvent: (...args) => onScreenViewEvent(...args),
                                onTourComplete: (...args) => onTourComplete(...args),
                            }
                        );
                    }
                } catch (error) { console.log(error) }

                try {
                    if(Boolean(this.tour.embed_settings.integration_gtm_enabled) && this.tour.embed_settings.integration_gtm_id){
                        this.integrateTagManager(this.tour.embed_settings.integration_gtm_id);
                    }
                } catch (error) { console.log(error) }

                const shouldInjectGlobalGTM = Boolean(this.tour.embed_settings.integration_gtm_enabled) 
                ? !Boolean(this.tour.embed_settings.integration_disable_global_gtm)
                : true;

                try {
                    if (shouldInjectGlobalGTM && this.tour.tracking_integrations && this.tour.tracking_integrations.integrate_google_tag_manager ) {
                        this.integrateTagManager(this.tour.tracking_integrations.google_tag_manager_id);
                    }
                } catch (error) { console.log(error) }
            },
            // Responsive Layout switch
            createMediaQueryListener(){
                const mediaQuery = window.matchMedia('(max-width: 560px)');

                const handleResponsiveLayoutSwitch = () => mediaQuery.matches ? this.onMobile() : this.onDesktop();

                mediaQuery.addEventListener("change", handleResponsiveLayoutSwitch);

                setTimeout(handleResponsiveLayoutSwitch, 100);
            },
            showLoading(callback){
                this.loading = true;

                callback && callback();

                setTimeout(() => {
                    this.loading = false
                }, 500)
            },
            onDesktop(){
                if(!this.loaded) return this.responsiveMode = false;

                this.showLoading(() => {
                    this.responsiveMode = false;
                    
                    setTimeout(() => {
                        this.loadTour();
                    }, 0)
                });
            },
            onMobile(){
                if(!this.loaded) return this.responsiveMode = true;

                this.showLoading(() => {
                    this.responsiveMode = true;
                    
                    setTimeout(() => {
                        this.loadResponsiveTour();
                    }, 0)
                });
            },
        },
        computed: {
            isResponsiveTourEnabled(){
                const isEnabled = this.tour.is_responsive_tour_enabled;

                return typeof isEnabled !== "undefined" && isEnabled !== null ? isEnabled : true;
            }
        }
    }
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.outer_tour_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.no-responsive-tour{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1rem;
    text-align: center;
    padding: 30px;
    color: gray;
    font-family: none;
    background-color: #eff2f9;
    color: #4B60EB;
    font-family: "Noto Sans KR", sans-serif !important;    
    font-weight: 500;
    border-radius: 8px;
}
.ss_pass_main_heading {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 50px !important;
    color: #000;
    margin-bottom: 20px;
}
.no-responsive-tour .btn {
    background: #d5d5d5 !important;
    color: #000 !important;
    border: 1px solid #d5d5d5 !important;
    cursor: pointer !important;
    font-weight: 600 !important;
}
.no-responsive-tour .btn:hover {
    background: #000 !important;
    color: #d5d5d5 !important;
}
#main{
    width: 100%;
    height: 100vh;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}
</style>
