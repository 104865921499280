import {
    onIframeReady,
    renderCanvasImages,
    disableAnchorLinks,
    renderIframes,
    walkDom,
    getAttributes,
    decodeHTMLEntities
} from "../utils";
import toString from "../toString";

export function renderDocument(options, callback = null) {
    return new Promise(async (resolve, reject) => {
        try {
            let { ast, iframe } = options;

            // Generate html
            const html = toString(ast);

            // Render html
            iframe.contentDocument.open("text/html", "replace");
            iframe.contentDocument.write(html);
            iframe.contentDocument.close();

            await onIframeReady(iframe);

            // Render canvas images
            renderCanvasImages(iframe.contentDocument);

            // Render iframes
            await renderIframes(Array.from(iframe.contentDocument.body.querySelectorAll("iframe")));

            // Disable form submits
            [...iframe.contentDocument.forms].forEach(form => form.onsubmit = (event) => event.preventDefault());

            if (typeof callback === "function") {
                walkDom(iframe.contentDocument.documentElement, (element) => {
                    callback(element);
                });
            }

            // Disable anchor link, remove this after chrome extension update
            disableAnchorLinks(iframe.contentDocument);

            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

export function renderHtml(options, callback = null) {
    return new Promise(async (resolve, reject) => {
        try {
            let { html, scripts = [], iframe } = options;

            const doc = new DOMParser().parseFromString(html, "text/html");

            doc.head.insertAdjacentHTML("afterbegin", '<meta charset="utf-8"/>');

            walkDom(doc.documentElement, (element) => {
                const attrs = getAttributes(element);

                Object.entries(attrs).forEach(([key]) => {
                    const isEventListener = key.startsWith("on");
                    const shouldRemoveAttr = isEventListener || ["contenteditable"].includes(key);

                    if (shouldRemoveAttr) element.removeAttribute(key);
                });
            });

            // Add script tags
            scripts?.forEach((src) => {
                const tag = doc.createElement("script");

                tag.setAttribute("src", src);

                doc.head.appendChild(tag);
            });

            const content = doc.documentElement.outerHTML

            // Render html
            iframe.contentDocument.open("text/html", "replace");
            iframe.contentDocument.write(content);
            iframe.contentDocument.close();

            await onIframeReady(iframe);

            // Render canvas images
            renderCanvasImages(iframe.contentDocument);

            // Render iframes
            await renderIframes(Array.from(iframe.contentDocument.body.querySelectorAll("iframe")));

            // Disable form submits
            [...iframe.contentDocument.forms].forEach(form => form.onsubmit = (event) => event.preventDefault());

            if (typeof callback === "function") {
                walkDom(iframe.contentDocument.documentElement, (element) => {
                    callback(element);
                });
            }

            // Disable anchor link, remove this after chrome extension update
            disableAnchorLinks(iframe.contentDocument);

            // TODO: Find a better solution for html enitity decode rendering 
            new Promise((resolve) => {
                [...iframe.contentDocument.body.querySelectorAll("text")].forEach(el => el.innerHTML = decodeHTMLEntities(el.innerText))
                
                resolve();
            });
                    
            resolve();
        } catch (error) {
            reject(error);
        }
    });
}

export function renderIframe(options, callback = null) {
    return new Promise(async (resolve, reject) => {
        try {
            let { iframe } = options;

            await onIframeReady(iframe);

            walkDom(iframe.contentDocument.documentElement, (element) => {
                const attrs = getAttributes(element);

                Object.entries(attrs).forEach(([key]) => {
                    const isEventListener = key.startsWith("on");
                    const shouldRemoveAttr = isEventListener || ["contenteditable"].includes(key);

                    if (shouldRemoveAttr) element.removeAttribute(key);
                });

                if (typeof callback === "function") {
                    callback(element);
                }
            });

            // Render canvas images
            renderCanvasImages(iframe.contentDocument);

            // Render iframes
            await renderIframes(Array.from(iframe.contentDocument.body.querySelectorAll("iframe")));

            // Disable form submits
            [...iframe.contentDocument.forms].forEach(form => form.onsubmit = (event) => event.preventDefault());

            // Disable anchor link, remove this after chrome extension update
            disableAnchorLinks(iframe.contentDocument);

            // TODO: Find a better solution for html enitity decode rendering 
            new Promise((resolve) => {
                [...iframe.contentDocument.body.querySelectorAll("text")].forEach(el => el.innerHTML = decodeHTMLEntities(el.textContent))
                
                resolve();
            });

            resolve();
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
}