<template>
  <div>
    <Loading :loading="loading" solid />

    <template v-if="!loading">
      <div class="main col-xl-12" v-if="!isActive">
        <NotFound />
      </div>

      <div v-else-if="!loading">
        <AgentRenderer :publicId="publicId" />
      </div>
    </template>
  </div>
</template>
<script>
import Loading from "../components/Loading.vue";
import AgentRenderer from "./components/renderer/new.vue";
import NotFound from "../components/NotFound.vue";

export default {
  layout: "preview",
  props: ["publicId"],
  components: {
    Loading,
    AgentRenderer,
    NotFound,
  },
  data() {
    return {
      loading: true,
      isActive: false,
    };
  },
  created() {
    this.checkIfExists(this.publicId);
  },
  methods: {
    async checkIfExists(publicId) {
      try {
        const res = await this.axios.get(`/frontend/demo-center/check/${publicId}`).then((res) => res.data.data);

        this.isActive = res.is_active;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

.outer_tour_holder {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.no-responsive-tour {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  padding: 30px;
  color: gray;
  font-family: none;
  background-color: #eff2f9;
  color: #4b60eb;
  font-family: "Noto Sans KR", sans-serif !important;
  font-weight: 500;
  border-radius: 8px;
}
.ss_pass_main_heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 50px !important;
  color: #000;
  margin-bottom: 20px;
}
.no-responsive-tour .btn {
  background: #d5d5d5 !important;
  color: #000 !important;
  border: 1px solid #d5d5d5 !important;
  cursor: pointer !important;
  font-weight: 600 !important;
}

.no-responsive-tour .btn:hover {
  background: #000 !important;
  color: #d5d5d5 !important;
}

.main {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
</style>