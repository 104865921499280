import PreviewWidget from "../preview-widget";
import { onIframeReady, getBoundingClientRectPadded } from "@/utils";
import 'requestidlecallback-polyfill';

export default class ResponsiveViewer {
    constructor(options) {
        const { iframe, onReady } = options;

        this.iframe = iframe;
        this.onReady = onReady || (() => { });
        this.events = {};

        this.centerPositions = {
            x: 0,
            y: 0,
            scale: 0,
        };

        this.isAnimating = false;

        this.setup();
    }

    setup() {
        this.createViewer();
    }

    createViewer() {
        onIframeReady(this.iframe).then(() => {
            this.iframe.contentDocument.open("text/html", "replace");
            this.iframe.contentDocument.write(this.getMockUp());
            this.iframe.contentDocument.close();

            onIframeReady(this.iframe).then(() => {
                this.previewContainer = this.iframe.contentDocument.getElementById('preview-container');
                this.previewHolder = this.iframe.contentDocument.getElementById('preview-holder');
                this.guideContainer = this.iframe.contentDocument.getElementById('guide-container');
                this.dragableLayer = this.iframe.contentDocument.getElementById('drag-layer');

                this.previewWidget = new PreviewWidget({
                    element: this.previewHolder,
                    iframe: this.iframe,
                });

                this.previewWidget.panzoomInstance.on('transform', (e) => {
                    const callback = this.events["transform"];

                    callback && callback(this)
                });

                this.onReady();

                window.responsiveViewer = this;
            });
        });
    }

    setRect(rect) {
        this.previewWidget.panzoomInstance.showRectangle(rect);
    }

    move(x, y, zoom) {
        this.center();

        setTimeout(() => {
            requestIdleCallback(() => {
                this.previewWidget.moveTo(x, y, zoom);
            });
        }, 500);
    }

    lookAt(x, y, zoom, prevScreenWidth, prevScreenHeight, duration = 1500, easing) {
        // TODO: Fix double invoke, on screen load
        
        if(this.isAnimating) return;

        this.isAnimating = true;
    
        // if (this.animationInstance) {
        //     this.animationInstance.cancel();

        //     this.animationInstance.commitStyles();

        //     return this.lookAt(x, y, zoom, prevScreenWidth, prevScreenHeight, duration, easing);
        // }

        this.center();
        this.center();

        requestIdleCallback(() => {
            setTimeout(() => {
                const { screenWidth, screenHeight } = this.getTransform();

                const newX = (x * screenWidth) / prevScreenWidth;
                const newY = (y * screenHeight) / prevScreenHeight;
                const newZoom = (zoom * screenWidth) / prevScreenWidth;

                requestIdleCallback(() => {
                    this.animateTo(newX, newY, newZoom, duration, easing).then(() => {
                        this.previewWidget.moveTo(newX, newY, newZoom);
                    });
                });
            }, 1000);
        });
    }

    autoLookAt(element, duration = 1500, easing) {
        const padding = 50;

        this.previewWidget.panzoomInstance.showRectangle(getBoundingClientRectPadded(element, padding));

        const { x, y, scale: zoom } = this.previewWidget.panzoomInstance.getTransform();

        this.center();
        this.center();

        requestIdleCallback(() => {
            this.animateTo(x, y, zoom, duration, easing).then(() => {
                this.previewWidget.moveTo(x, y, zoom);
            });
        });
    }

    animateTo(x, y, zoom, duration = 1500, easing = 'cubic-bezier(0.11, 0, 0.5, 0)') {
        try {
            const { x: currentX, y: currentY, scale: currentZoom } = this.getTransform();

            const currentPositionTransform = `matrix(${currentZoom}, 0, 0, ${currentZoom}, ${currentX}, ${currentY})`;
            const finalPositionTransform = `matrix(${zoom}, 0, 0, ${zoom}, ${x}, ${y})`;

            const keyframes = [
                { transform: currentPositionTransform },
                { transform: finalPositionTransform },
            ];

            const timing = {
                duration,
                iterations: 1,
                easing,
            }

            this.previewHolder.style.transform = currentPositionTransform;

            this.animationInstance = this.previewHolder.animate(keyframes, timing);

            this.previewHolder.style.transform = finalPositionTransform;

            const finishedPromise = this.animationInstance 
            ? this.animationInstance.finished 
            : Promise.resolve();

            return Promise.resolve(finishedPromise).then(() => {
                this.animationInstance = null;
                this.isAnimating = false;

                return {
                    transform: finalPositionTransform,
                }
            });
        } catch (error) {
            console.log(error);
            return Promise.reject();
        }
    }

    getCenterPosition() {
        const iframe = this.previewHolder.querySelector('iframe.active');
        const { width: holderWidth, height: holderHeight } = this.previewContainer.getBoundingClientRect();
        const { width: iframeWidth, height: iframeHeight } = iframe.getBoundingClientRect();
        const { screenWidth } = this.getTransform();

        const scale = (0.25 * screenWidth) / 330;

        const [x, y] = [(holderWidth - iframeWidth) / 2, (holderHeight - iframeHeight) / 3];

        return { x, y, scale };
    }

    center() {
        const { screenWidth } = this.getTransform();

        const newZoom = (0.24 * screenWidth) / 330;

        this.previewWidget.panzoomInstance.zoomAbs(0, 0, newZoom);

        requestIdleCallback(() => {
            const { x, y } = this.getCenterPosition();

            this.previewWidget.panzoomInstance.moveTo(x, y);

            this.centerPositions = { x, y, scale: newZoom };
        });
    }

    centerGracefully() {
        return new Promise((resolve, reject) => {
            try {
                const { x, y, scale } = this.centerPositions;

                if (this.isCurrentTransform(x, y, scale)) return resolve();

                this.animateTo(x, y, scale, 800).then(() => {
                    this.previewWidget.moveTo(x, y, scale);

                    setTimeout(resolve, 0);
                });
            } catch (error) {
                reject(error);
            }
        })
    }

    getTransform() {
        const { x, y, scale } = this.previewWidget.panzoomInstance.getTransform();
        const { width: screenWidth, height: screenHeight } = this.previewContainer.getBoundingClientRect();

        return { x, y, scale, screenWidth, screenHeight };
    }

    isCurrentTransform(dataX, dataY, dataScale) {
        const { x, y, scale } = this.getTransform();

        return x === dataX && y === dataY && scale === dataScale
    }

    setMoveable(flag) {
        flag ? this.dragableLayer.style.removeProperty("display")
            : this.dragableLayer.style.display = 'none';
    }

    getMockUp() {
        const html = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="icon" type="image/x-icon" href="https://securnyx360.s3.ap-south-1.amazonaws.com/lazy_logo.png">
                <title>Story tour viewer</title>
                <style>
                    html, body{
                        margin: 0;
                        padding: 0;
                        height: 100%;
                        background: #f1f5f9;
                    }
                    
                    .tour-container{
                        height: 100%;
                        background: #f1f5f9;
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .preview-container{
                        width: 100%;
                        overflow: hidden;
                        background-color: #f1f5f9;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-basis: 100%;
                        outline: none;
                        overflow-x: hidden;
                    }
                    
                    .preview-container .preview-holder{
                        width: 100%;
                        height: 100%;
                    }
                    
                    .preview-container .preview-holder iframe{
                        width: 1360px;
                        height: 768px;
                        border: none;
                    }
                    
                    .guide-container{
                        display: flex;
                        justify-content: center;
                    }
                </style>
                <script src='https://unpkg.com/panzoom@9.4.0/dist/panzoom.min.js'></script>
            </head>
            <body>
                <section class="tour-container">
                    <div class="preview-container" id="preview-container">
                        <div class="preview-holder" id="preview-holder">
                          <div id="drag-layer" style="display:none; width: 1360px; height: 768px; cursor: grab; position: fixed; top: 0px; left: 0px; background-color: transparent;"></div>
                        </div>
                    </div>
                    <div class="guide-container" id="guide-container"></div>
                </section>
            </body>
            </html>
        `;

        return html;
    }

    on(event, callback) {
        this.events[event] = callback;

        return () => {
            delete this.events[event];
        }
    }
}