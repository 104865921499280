export default (Vue) => {
    Vue.directive('click-outside', {
        bind(el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    typeof binding.value == 'function' && binding.value(event);
                }
            };

            el.clickOutsideWindowBlurEvent = function (event) {
                typeof binding.value == 'function' && binding.value(event);
            };

            window.addEventListener("blur", el.clickOutsideWindowBlurEvent);
            document.body.addEventListener('click', el.clickOutsideEvent)
        },
        unbind(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
            window.removeEventListener("blur", el.clickOutsideWindowBlurEvent);
        },
    });

    Vue.directive('focus', {
        inserted(el, binding, vnode) {
            el.focus();
        }
    });
};