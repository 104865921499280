import { addScriptTag } from "../../../..";

export function generateAttributeString(attributes) {
    const result = [];

    for (let key in attributes) {
        // Replace double quotes with single quotes
        if (attributes[key] && attributes[key].replace) {
            attributes[key] = attributes[key].replace(/"/g, `'`) || attributes[key];
        }

        // Filter inline event attributes
        if (key.startsWith("on")) continue;

        result.push(key + '="' + attributes[key] + '"')
    }

    if (!result.length) {
        return ''
    };

    return ' ' + result.join(' ')
}

export function generateStyleObject(styleString) {
    const styleArray = styleString.split(";");

    const styleObject = styleArray.reduce((acc, item) => {
        const [property, value] = item.split(":");

        if (property && value) acc[property.trim()] = value.trim();

        return acc;
    }, {});

    return styleObject
}

export function generateStyleString(styleObject) {
    return Object.entries(styleObject).map(([property, value]) => `${property}: ${value}`).join(";")
}

// export function onIframeReady(iframe) {
//     return new Promise((resolve, reject) => {
//         function checkReadyState() {
//             try {
//                 if (iframe?.contentDocument?.readyState === 'interactive' || iframe?.contentDocument?.readyState === 'complete') {
//                     resolve();
//                 } else if (iframe?.contentWindow) {
//                     iframe?.contentWindow?.addEventListener("DOMContentLoaded", resolve, true);
//                 } else {
//                     resolve();
//                 }
//             } catch (err) {
//                 reject(err);
//             }
//         }

//         checkReadyState();
//     });
// }

export function onIframeReady(iframe, state = "complete") {
    return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
            try {
                if ([state, "complete", "interactive"].includes(iframe?.contentDocument?.readyState)) {
                    clearInterval(timer);
                    resolve();
                }
            } catch (err) {
                clearInterval(timer);
                console.log(err);
                reject()
            }
        }, 50);
    })
}

export function walkDom(element, callback = () => { }) {
    callback(element);

    if (element.childNodes.length) {
        const childList = Array.from(element.childNodes);

        for (const child of childList) {
            walkDom(child, callback);
        }
    }
}

export function renderIframes(iframes) {
    const promises = iframes.map(async (iframeElement) => {
        try {
            iframeElement.srcdoc = "<body></body>";

            await onIframeReady(iframeElement, "interactive");

            if (iframeElement.contentDocument && iframeElement.getAttribute("source")) {

                const html = window.atob(iframeElement.getAttribute("source").replace("data:text/html;base64,", ""));

                iframeElement.contentDocument.open("text/html", "replace");
                iframeElement.contentDocument.write(html);
                iframeElement.contentDocument.close();

                await onIframeReady(iframeElement);

                renderCanvasImages(iframeElement.contentDocument);

                disableAnchorLinks(iframeElement.contentDocument);

                iframeElement.removeAttribute("source");

                // Add story-guide
                addScriptTag(iframeElement.contentDocument, process.env.VUE_APP_FRONTEND_APP_URL + "/story-guide/story-guide.js");

                return renderIframes(Array.from(iframeElement.contentDocument.body.querySelectorAll("iframe")))
            }
        } catch (error) {
            console.log(error);
        } finally {
            return Promise.resolve();
        }
    });

    return Promise.all(promises)
}

export function renderCanvasImages(doc = document) {
    Array.from(doc.body.querySelectorAll("canvas")).forEach(canvas => {
        if (canvas.getAttribute("image")) {
            const ctx = canvas.getContext("2d");

            const image = new Image();

            image.onload = function () {
                ctx.drawImage(image, 0, 0);
            };

            image.src = canvas.getAttribute("image");
        }

        canvas.removeAttribute("image");
    });
}

export function disableAnchorLinks(doc = document) {
    Array.from(doc.links).forEach(anchor => {
        anchor.setAttribute("href", "javascript:void(0)");
        anchor.removeAttribute("target");
    });
}

export function sleep(delay = 0) {
    return new Promise((resolve) => setTimeout(resolve, delay))
}

export function getAttributes(element) {
    if (element && element.attributes) {
        const attrs = {};

        Array.from(element.attributes).forEach((attr) => {
            attrs[attr?.nodeName] = attr?.value;
        });

        return attrs;
    }

    return {};
}

export function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html");

    return doc.documentElement.textContent;
}

export function decodeHTMLEntities(text) {
    const entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"'],
    ];

    const entitiesUpperCase = entities.map((entity) => {
        const [key, value] = entity;

        return [key.toUpperCase(), value];
    });

    const entityCollection = [...entities, ...entitiesUpperCase];

    for (let i = 0, max = entityCollection.length; i < max; ++i){
        if(text) text = text.replace(new RegExp('&' + entityCollection[i][0] + ';', 'g'), entityCollection[i][1]);
    }

    return text;
}

export function encodeHtmlEntity(rawStr) {
    return rawStr.replace(/[\u00A0-\u9999<>\&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
    });
}