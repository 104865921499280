import { fadeOutAudio } from "../../../../../utils";

export default class AudioManager {
  constructor() {
    this.manager = new Map();
    this.isMute = false;
  }

  add(id, url) {
    const audioPlayer = new Audio();

    audioPlayer.src = url;

    this.manager.set(id, audioPlayer);
  }

  getDuration() {
    return this.audioPlayer ? this.audioPlayer.duration : 0;
  }

  play(id, { onEnd = null, onStart = null } = {}) {
    const audioPlayer = this.manager.get(id);

    if (audioPlayer) {
      if(this.audioPlayer) this.audioPlayer.pause();

      this.audioPlayer = audioPlayer;

      this.setAudioMute();

      audioPlayer.currentTime = 0;
      audioPlayer.volume = 1;

      const audioPromise = audioPlayer.play();

      if (audioPromise != undefined) {
        audioPromise
          .then(_ => {
            onStart();
          })
          .catch(err => {
            onEnd();
          });
      }

      if (onEnd) {
        this.audioPlayer.onended = () => {
          onEnd();
        };
      }
    } else {
      this.audioPlayer = null;
    }

    return this.audioPlayer;
  }

  resume() {
    if (this.audioPlayer) {
      this.audioPlayer.play();
    }
  }

  pause() {
    if (this.audioPlayer) {
      fadeOutAudio(this.audioPlayer, 400);
    }
  }

  error() {
    if (this.audioPlayer) {
    }
  }

  mute() {
    this.isMute = true;
    this.setAudioMute();
  }

  unMute() {
    this.isMute = false;
    this.setAudioMute();
  }

  setAudioMute() {
    if (this.audioPlayer) {
      this.audioPlayer.muted = this.isMute;
    }
  }
}
