<template>
    <div v-if="load">
        <lightViewer v-if="tokenData && dataObj && dataObj.experience_type === 'viewer' && show" :obj="dataObj" />
        <spotlightLight v-if="tokenData && dataObj && typeData && typeData === 'spotlight' && show" :obj="dataObj" />
        <Badge v-if="tokenData && badgeObj && showBadge" :obj="badgeObj" />
        <RecommendLight v-if="tokenData && recommendObj && showRec" :obj="recommendObj" />
    </div>
</template>
<script>
    import lightViewer from "./viewer/light.vue";
    import spotlightLight from "./spotlight/spotlightLight";
    import Badge from "./badge/badge";
    import RecommendLight from "./recommend/recommendLight";

    var _idleSecondsTimer = null;
    var _idleSecondsCounter = 0;

    export default {
        props: [ 'webtag', 'ptkn', 'dtp' ],
        created() {
            // console.log(document.location);
            // console.log(navigator);
            // console.log(document.referrer.split('?')[0]);
            if ( this.webtag && this.ptkn ) {
                this.requestPayload = {
                    platform_token: this.ptkn,
                    landing_page_link: this.getLandingPageLink(),
                    utm_source: null,
                    referral_domain_url: null,
                };
                if ( this.getParameterByName( 'utm_source' ) ) {
                    this.requestPayload.utm_source = this.getParameterByName( 'utm_source' );
                }
                if ( document.referrer && (`${document.location.origin}${document.location.pathname}` !== document.referrer.split('?')[0]) ) {
                    this.requestPayload.referral_domain_url = document.referrer.split('?')[0];
                }
                this.getExperience();
                this.getExperienceExtended();
            }
        },
        components: {
            lightViewer,
            spotlightLight,
            Badge,
            RecommendLight,
        },
        data () {
            return {
                dataObj: null,
                recommendObj: null,
                badgeObj: null,
                show: false,
                showRec: false,
                showBadge: false,
                tokenData: this.ptkn,
                typeData: null,
                requestPayload: null,
                load: false,
            };
        },
        updated() {
            // if(!this.load) return;

            if( this.dataObj ) {
                if ( this.dataObj.experience_display_trigger === 'exitintent' ) {
                    let addEvent = function (obj, evt, fn) {
                        if (obj.addEventListener) {
                            obj.addEventListener(evt, fn, false);
                        } else if (obj.attachEvent) {
                            obj.attachEvent("on" + evt, fn);
                        }
                    };

                    addEvent(window, "mouseout", (event) => {
                        event = event ? event : window.event;
                        var from = event.relatedTarget || event.toElement;
                        if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
                            this.showTrue();
                        }
                    });
                } else if ( this.dataObj.experience_display_trigger === 'scrollto' ) {
                    window.addEventListener('scroll', () => {
                        let scrollTop = window.scrollY;
                        let docHeight = document.body.offsetHeight;
                        let winHeight = window.innerHeight;
                        let scrollPercent = scrollTop / (docHeight - winHeight);
                        let scrollPercentRounded = Math.round(scrollPercent * 100);
                        if ( Number(this.dataObj.experience_display_trigger_idlescroll) <= Number(scrollPercentRounded) ) {
                            this.showTrue();
                        }
                    });
                } else if ( this.dataObj.experience_display_trigger === 'idleduration' ) {
                    // document.onclick = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // document.onscroll = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onmousemove = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onkeypress = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // _idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
                    setTimeout(()=> { this.showTrue(); }, ( Number(this.dataObj.experience_display_trigger_idleduration) - 1.5 ) * 1000);
                } else {
                    this.showTrue();
                }
            }
            if ( this.recommendObj ) {
                if ( this.recommendObj.experience_display_trigger === 'exitintent' ) {
                    let addEvent = function (obj, evt, fn) {
                        if (obj.addEventListener) {
                            obj.addEventListener(evt, fn, false);
                        } else if (obj.attachEvent) {
                            obj.attachEvent("on" + evt, fn);
                        }
                    };

                    addEvent(window, "mouseout", (event) => {
                        event = event ? event : window.event;
                        var from = event.relatedTarget || event.toElement;
                        if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
                            this.showRecTrue();
                        }
                    });
                } else if ( this.recommendObj.experience_display_trigger === 'scrollto' ) {
                    window.addEventListener('scroll', () => {
                        let scrollTop = window.scrollY;
                        let docHeight = document.body.offsetHeight;
                        let winHeight = window.innerHeight;
                        let scrollPercent = scrollTop / (docHeight - winHeight);
                        let scrollPercentRounded = Math.round(scrollPercent * 100);
                        if ( Number(this.recommendObj.experience_display_trigger_idlescroll) <= Number(scrollPercentRounded) ) {
                            this.showRecTrue();
                        }
                    });
                } else if ( this.recommendObj.experience_display_trigger === 'idleduration' ) {
                    // document.onclick = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // document.onscroll = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onmousemove = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onkeypress = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // _idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
                    setTimeout(()=> { this.showRecTrue(); }, ( Number(this.recommendObj.experience_display_trigger_idleduration) - 1.5 ) * 1000);
                } else {
                    this.showRecTrue();
                }
            }
            if ( this.badgeObj ) {
                if ( this.badgeObj.experience_display_trigger === 'exitintent' ) {
                    let addEvent = function (obj, evt, fn) {
                        if (obj.addEventListener) {
                            obj.addEventListener(evt, fn, false);
                        } else if (obj.attachEvent) {
                            obj.attachEvent("on" + evt, fn);
                        }
                    };

                    addEvent(window, "mouseout", (event) => {
                        event = event ? event : window.event;
                        var from = event.relatedTarget || event.toElement;
                        if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
                            this.showBadgeTrue();
                        }
                    });
                } else if ( this.badgeObj.experience_display_trigger === 'scrollto' ) {
                    window.addEventListener('scroll', () => {
                        let scrollTop = window.scrollY;
                        let docHeight = document.body.offsetHeight;
                        let winHeight = window.innerHeight;
                        let scrollPercent = scrollTop / (docHeight - winHeight);
                        let scrollPercentRounded = Math.round(scrollPercent * 100);
                        if ( Number(this.badgeObj.experience_display_trigger_idlescroll) <= Number(scrollPercentRounded) ) {
                            this.showBadgeTrue();
                        }
                    });
                } else if ( this.badgeObj.experience_display_trigger === 'idleduration' ) {
                    // document.onclick = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // document.onscroll = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onmousemove = () => {
                    //     _idleSecondsCounter = 0;
                    // };

                    // document.onkeypress = () => {
                    //     _idleSecondsCounter = 0;
                    // };
                    // _idleSecondsTimer = window.setInterval(this.CheckIdleTime, 1000);
                    setTimeout(()=> { this.showBadgeTrue(); }, ( Number(this.badgeObj.experience_display_trigger_idleduration) - 1.5 ) * 1000);
                } else {
                    this.showBadgeTrue();
                }
            }
        },
        methods: {
            // CheckIdleTime() {
            //     _idleSecondsCounter++;
            //     if ( Number(_idleSecondsCounter) >= Number(this.dataObj.experience_display_trigger_idleduration) ) {
            //         this.showTrue();
            //         window.clearInterval(_idleSecondsTimer);
            //     }
            // },
            showTrue() {
                this.show = true;
            },
            showRecTrue() {
                this.showRec = true;
            },
            showBadgeTrue() {
                this.showBadge = true;
            },
            getExperienceExtended () {
                this.$Progress.start();
                this.axios.post( `frontend/view-experience/global/extended`, this.requestPayload )
                .then( ( response ) => {
                    const { recommend, badge } = response.data.data;
                    if ( recommend ) {
                        const exp = recommend.experience;
                        this.recommendObj = {
                            ...exp,
                            display_pages: [],
                            style_guide_id: recommend.style_guide,
                            primary_cta_id: recommend.primary_cta,
                            recommend_type: recommend.recommend_type,
                            tracking_integrations: recommend.tracking_integrations,
                            recommend_experience: recommend.recommend_experience ? recommend.recommend_experience : null,
                            recommend_experience_links: recommend.recommend_experience_links.length > 0 ? recommend.recommend_experience_links : [],
                            keen_data: recommend.user.keen_data,
                        };
                        if ( this.dtp ) {
                            this.recommendObj.delivery_type = this.dtp;
                        }
                        if ( recommend.display_pages.length > 0 ) {
                            recommend.display_pages.map( ( link ) => {
                                this.recommendObj.display_pages.push( link.page_url );
                                return link;
                            } );
                        }

                        if ( this.recommendObj.tracking_integrations.integrate_google_tag_manager ) {
                            this.integrateTagManager( this.recommendObj.tracking_integrations.google_tag_manager_id );
                        }

                        this.recommendObj.visitor_scroll_before_interaction = String( this.recommendObj.visitor_scroll_before_interaction );
                        this.recommendObj.visitor_wait_before_interaction = String( this.recommendObj.visitor_wait_before_interaction );
                        this.load = true;
                    }
                    if ( badge ) {
                        const exp = badge.experience;
                        this.badgeObj = {
                            ...exp,
                            display_pages: [],
                            style_guide_id: badge.style_guide,
                            badge_asset_id: badge.badge_asset,
                            tracking_integrations: badge.tracking_integrations,
                            badge_url: badge.badge_url,
                            keen_data: badge.user.keen_data,
                        };
                        if ( this.dtp ) {
                            this.badgeObj.delivery_type = this.dtp;
                        }
                        if ( badge.display_pages.length > 0 ) {
                            badge.display_pages.map( ( link ) => {
                                this.badgeObj.display_pages.push( link.page_url );
                                return link;
                            } );
                        }

                        if ( this.badgeObj.tracking_integrations.integrate_google_tag_manager ) {
                            this.integrateTagManager( this.badgeObj.tracking_integrations.google_tag_manager_id );
                        }

                        this.badgeObj.visitor_scroll_before_interaction = String( this.badgeObj.visitor_scroll_before_interaction );
                        this.badgeObj.visitor_wait_before_interaction = String( this.badgeObj.visitor_wait_before_interaction );
                        this.load = true;
                    }
                    this.$Progress.finish();

                }).finally(() => {
                    this.$Progress.finish();
                })
                .catch( ( error ) => {
                    console.log(error);
                    // const { message } = error.response.data;
                    // console.log( message );
                    this.$Progress.fail();
                } )
            },
            getExperience () {
                this.$Progress.start();
                this.axios.post( `frontend/view-experience/global`, this.requestPayload )
                .then( ( response ) => {
                    const { data } = response.data;

                    // if(!data || !data.experience) return
                    if ( data ) {
                        const exp = data.experience;
                        this.typeData = exp.experience_type;
                        this.dataObj = {
                            ...exp,
                            display_pages: [],
                            paywall_cta_id: data.paywall_cta,
                            primary_cta_id: data.primary_cta,
                            nudge_cta_id: data.nudge_details,
                            style_guide_id: data.style_guide,
                            primary_sequence: null,
                            sequences: [],
                            keen_data: data.user.keen_data,
                        };
                        if (this.dtp) {
                            this.dataObj.delivery_type = this.dtp;
                        }
                        if (data.display_pages.length > 0) {
                            data.display_pages.map((link) => {
                                this.dataObj.display_pages.push(link.page_url);
                                return link;
                            });
                        }
                        this.dataObj.visitor_scroll_before_interaction = String(this.dataObj.visitor_scroll_before_interaction);
                        this.dataObj.visitor_wait_before_interaction = String(this.dataObj.visitor_wait_before_interaction);
                        if (data.sequences) {
                            data.sequences.map((sq) => {
                                // sq.now = false;
                                if (sq.pivot.is_primary === 1) {
                                    // sq.now = true;
                                    this.dataObj.primary_sequence = {...sq};
                                } else {
                                    this.dataObj.sequences.push({...sq});
                                }
                                return sq;
                            });
                        }
                        this.load = true;
                        switch (this.typeData) {
                            case 'viewer':
                                this.dataObj.user = data.user;
                                break;
                            case 'spotlight':
                                if (data.experience.launch_with_splash) {
                                    this.dataObj.splash_asset_id = data.splash_image;
                                } else {
                                    this.dataObj.splash_asset_id = null;
                                }
                                break;
                            // case 'badge':
                            //     this.dataObj.badge_asset_id = data.badge_asset;
                            //     this.dataObj.badge_url = data.badge_url;
                            //     break;
                            // case 'recommend':
                            //     this.dataObj.recommend_experience = data.recommend_experience ? data.recommend_experience : null;
                            //     this.dataObj.recommend_experience_links = data.recommend_experience_links.length > 0 ? data.recommend_experience_links : [];
                            //     this.dataObj.recommend_type = data.recommend_type;
                            //     break;
                            case 'blank':
                                break;
                            default:
                                console.log("Invalid Type");
                        }
                    }
                    this.$Progress.finish();

                }).finally(() => {
                     this.$Progress.finish();
                })
                .catch( ( error ) => {
                    console.log(error);
                    // const { message } = error.response.data;
                    // console.log( message );
                    this.$Progress.fail();
                } )
            },
            getLandingPageLink(){
                let {origin, pathname, search, hash} = document.location

                if(pathname == "/") pathname = ""

                let landing_page_link = (origin + pathname + search)

                if(hash) landing_page_link += hash

                return landing_page_link;
            }
        },
    }
</script>
